import {
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  POST_CATEGORY_SUCCESS,
  POST_CATEGORY_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  allCategories: [],
  newCategoryAdded: {},
  error: {},
  
}

const categories = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        allCategories: action.payload,
      }

    case GET_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case POST_CATEGORY_SUCCESS:
      return {
        ...state,
        allCategories: [...state.allCategories, action.payload],
        newCategoryAdded: action.payload,
      }

    case POST_CATEGORY_FAIL:
      return {
        ...state,
        error: { ...state, error: action.payload },
      }

    default:
      return state
  }
}

export default categories
