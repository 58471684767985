import React, { useState } from 'react';
import { Col } from 'reactstrap';
import i18n from "i18next";

//Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const JobListGlobalFilter = () => {
    const [selectDate, setSelectDate] = useState();
    const dateChange = (date) => {
        setSelectDate(date);
    };
    return (
        <React.Fragment>
            <Col xxl={2} lg={6}>
                <select className="form-control select2 mb-3 mb-xxl-0">
                    <option>{i18n.t("Status")}</option>
                    <option value="Active">{i18n.t("Active")}</option>
                    <option value="New">{i18n.t("New")}</option>
                    <option value="Close">{i18n.t("Close")}</option>
                </select>
            </Col>
            <Col xxl={2} lg={4}>
                <select className="form-control select2 mb-3 mb-xxl-0">
                    <option>{i18n.t("Select Type")}</option>
                    <option value="1">{i18n.t("Full Time")}</option>
                    <option value="2">{i18n.t("Part Time")}</option>
                </select>
            </Col>
            <Col xxl={2} lg={4}>
                <div id="datepicker1">
                    <DatePicker
                        className="form-control mb-3 mb-xxl-0"
                        selected={selectDate}
                        onChange={dateChange}
                    />
                </div>
            </Col>
            <Col xxl={1} lg={4}>
                <div className='mb-3 mb-xxl-0'>
                    <button type="button" className="btn btn-soft-secondary w-100">
                        <i className="mdi mdi-filter-outline align-middle"></i> {i18n.t("Filter")}</button>
                </div>
            </Col>
        </React.Fragment>
    );
};
export default JobListGlobalFilter;

