import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_SHIF_IN_PERIOD,
    GET_SHIF_DETAILS_BY_SHIFT_ID,
} from "./actionTypes";
import {
    getShifInPeriodSuccess,
    getShifInPeriodFail,
    getShifDetailsByShiftIdSuccess,
    getShifDetailsByShiftIdFail,
} from "./actions";
import {
    getShiftInPreiod,
    getShiftDetailsByShiftId
} from "helpers/backend_helper";

function* onGetShiftInPeriod({ payload: parameters }) {
    try {
        const response = yield call(getShiftInPreiod, parameters);
        yield put(getShifInPeriodSuccess(response));
    } catch (error) {
        yield put(getShifInPeriodFail(error));
    }
};

function* onGetShiftDetailsByShiftId({ payload: FK_Shift_ID }) {
    try {
        const response = yield call(getShiftDetailsByShiftId, FK_Shift_ID);
        yield put(getShifDetailsByShiftIdSuccess(response));
    } catch (error) {
        yield put(getShifDetailsByShiftIdFail(error));
    }
};

function* endShiftReportSaga() {
    yield takeEvery(GET_SHIF_IN_PERIOD, onGetShiftInPeriod);
    yield takeEvery(GET_SHIF_DETAILS_BY_SHIFT_ID, onGetShiftDetailsByShiftId);
}

export default endShiftReportSaga;