import {
    GET_PREPARE_AREAS_SUCCESS,
    GET_PREPARE_AREAS_FAIL,
    POST_PREPARE_AREA_SUCCESS,
    POST_PREPARE_AREA_FAIL
  } from "./actionType"
  
  const INIT_STATE = {
    allPrepareAreas: [],
    prepareAreaAdded: {},
    // userProfile: {},
    error: {},
  }
  
  const prepareAreas = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_PREPARE_AREAS_SUCCESS:
        return {
          ...state,
          allPrepareAreas: action.payload,
        }
  
      case GET_PREPARE_AREAS_FAIL:
        return {
          ...state,
          error: action.payload,
        }

        case POST_PREPARE_AREA_SUCCESS:
          return {
            ...state,
            allPrepareAreas: [...state.allPrepareAreas, action.payload],
            prepareAreaAdded: action.payload
          }

          case POST_PREPARE_AREA_FAIL:
            return {
              ...state,
              error:  { ...state, error: action.payload },
              loading: "false"
            }

      default:
        return state
    }
  }
  
  export default prepareAreas
  