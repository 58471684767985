import React, { useMemo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import TableContainer from "components/Common/TableContainer"

import {
  Col,
  Row,
  Card,
  CardBody,
  Spinner,
  Button,
  UncontrolledTooltip,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

import Breadcrumbs from "components/Common/Breadcrumb"

import { getStockTransfer, getStockTransferById } from "store/actions"
import TransferDetailsModal from "./TransferDetailsModal"

const StockTransferReport = props => {
  document.title = "Stock Transfer | Reports"
  const dispatch = useDispatch()

  const [transferModal, setTransferModal] = useState(false)
  const [stockTransferList, setStockTransferList] = useState([])

  //   const reports = useSelector(state => state.Reports)
  const stockTransfer = useSelector(state => state.stockTransfer)
  const {
    stockTransferData: stockTransferData,
    getStockTransferLoading: loading
  } = stockTransfer

  useEffect(() => {
    dispatch(getStockTransfer())
  }, [])

  useEffect(() => {
    setStockTransferList(stockTransferData)
  }, [stockTransferData])

  // useEffect(() => {
  //   console.log(stockTransferDetails)
  //   setTranferDetails(stockTransferDetails)
  // }, [stockTransferDetails])

  const toggleTransferModel = () => {
    setTransferModal(prevState => !prevState)
  }

  const handleTransferModal = data => {
    // setTransferId(data.PK_StockTransfere_ID)
    console.log(data.PK_StockTransfere_ID)
    dispatch(getStockTransferById(data.PK_StockTransfere_ID))
    toggleTransferModel()
  }

  const columns = useMemo(
    () => [
      {
        Header: props.t("Date"),
        accessor: "TransDate",
        filterable: true,
        Cell: cellProps => {
          const date = new Date(cellProps.value)
          const year = date.getFullYear()
          const month =
            date.getMonth() + 1 > 9
              ? date.getMonth() + 1
              : `0${date.getMonth() + 1}`
          const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
          return <>{cellProps.value ? year + "-" + month + "-" + day : ""}</>
        },
      },
      {
        Header: props.t("Time"),
        accessor: "TransTime",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("TotalTransferedQty"),
        accessor: "TotalTransferedQty",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("EmpName"),
        accessor: "EmpName",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("Notes"),
        accessor: "Notes",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      //   {
      //     Header: props.t("Employee"),
      //     accessor: "EmpFromName",
      //     filterable: true,
      //     Cell: cellProps => {
      //       return <>{cellProps.value || ""}</>
      //     },
      //   },
      //   {
      //     Header: props.t("Notes"),
      //     accessor: "Notes",
      //     filterable: true,
      //     Cell: cellProps => {
      //       return <>{cellProps.value || 0}</>
      //     },
      //   },

      {
        Header: props.t("Attach"),
        Cell: cellProps => {
          const { PK_StockTransfere_ID } = cellProps.row.original
          return (
            <div className="list-unstyled hstack gap-1 mb-0">
              <li>
                {PK_StockTransfere_ID !== null &&
                  PK_StockTransfere_ID !== "" && (
                    <Button
                      type="button"
                      className="btn btn-sm btn-soft-primary"
                      onClick={() =>
                        handleTransferModal(cellProps.row.original)
                      }
                    >
                      <i className="mdi mdi-eye-outline" id="viewtooltip" />
                      <UncontrolledTooltip placement="top" target="viewtooltip">
                        {props.t("Attach")}
                      </UncontrolledTooltip>
                    </Button>
                  )}
              </li>
            </div>
          )
        },
      },
    ],
    [props.t]
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Reports")}
            breadcrumbItem={props.t("Stock Transfer report")}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="text-center py-5">
                      <Spinner className="ms-2" color="primary" />
                    </div>
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={stockTransferList}
                      isAddOptions={false}
                      customPageSize={5}
                      allListSize={
                        stockTransferList ? stockTransferList.length : []
                      }
                      customPageSizeOptions={true}
                    />
                  )}
                </CardBody>
                {/* {safeBalanceList &&
                  (safeBalanceList.length && !loading ? (
                    <div className="mt-2 d-flex justify-content-end">
                      <SendAndPrintPdf
                        data={safeBalanceList}
                        extraData={null}
                        filteredData={["No", "SafeName", "Balance"]}
                        extraTwoColData={null}
                      />
                    </div>
                  ) : (
                    <></>
                  ))} */}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <TransferDetailsModal
        isOpen={transferModal}
        toggle={toggleTransferModel}
      />
    </React.Fragment>
  )
}

StockTransferReport.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(StockTransferReport))
