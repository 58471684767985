import React, { useEffect, useState } from "react"

const SelectPeriodType = props => {
  const [periodType, setPeriodType] = useState("monthly")

  const onChangeChartPeriod = e => {
    const pType = e.target.value
    setPeriodType(pType)
    props.currentPeriodTypeHandler(pType)
  }

  useEffect(() => {
    setPeriodType(props.periodType)  
  }, [props.periodType])


  return (
    <div className="ms-auto">
      <label className="visually-hidden" htmlFor="autoSizingSelect">
        Period Type
      </label>
      <select
        onChange={onChangeChartPeriod}
        defaultValue={periodType}
        className="form-select"
      >
        <option value="monthly">Monthly</option>
        <option value="weekly">Weekly</option>
        <option value="today">Daily</option>
      </select>
    </div>
  )
}

export default SelectPeriodType
