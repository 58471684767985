import autoTable from "jspdf-autotable"
import {
  preparePdfHeader,
  preparePdfTabelForShiftReport,
  preparePdfTabel,
  preparePdfTwoColumnsTabel,
  convertToUiLabel,
} from "./"

const getTabelRowData = (filteredData, obj, i) => {
  const datePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?$/
  let currentObj = {}

  filteredData.forEach(key => {
    if (obj[key] || obj[key] === 0 || obj[key] === null) {
      if (datePattern.test(obj[key])) {
        // convert date
        currentObj[key] = obj[key].split("T")[0]
      } else {
        if (obj[key]) {
          currentObj[key] = obj[key]
        } else if (obj[key] === 0) {
          currentObj[key] = 0
        } else {
          currentObj[key] = "__"
        }
      }
    } else {
      // row number
      currentObj[key] = i + 1
    }
  })

  return { currentObj }
}

const prepareTabelData = (data, filteredData) => {
  const TabelData = []

  const filteredTabelData = data.map((obj, i) => {
    const { currentObj } = getTabelRowData(filteredData, obj, i)
    return currentObj
  })

  filteredTabelData.forEach((obj, i) => {
    TabelData[i] = Object.values(obj)
  })
  TabelData.unshift(filteredData)

  return { TabelData }
}

const attachPdfTabelDataToDoc = (doc, TabelData, margin) => {
  const TabelDataWithcovertedLabels = TabelData.map(one =>
    one.map(value => convertToUiLabel(value))
  )
  autoTable(doc, {
    body: TabelDataWithcovertedLabels,
    startY: margin,
    theme: "grid",
    didParseCell: function (data) {
      if (data.row.index === 0) {
        data.cell.styles.fillColor = [251, 148, 14]
        data.cell.styles.textColor = 255
        data.cell.styles.cellPadding = 3
      } else {
        data.cell.styles.fontSize = 8
        data.cell.styles.cellPadding = 2
      }
    },
    alternateRowStyles: {
      fillColor: [255, 216, 168], // Color for striped rows
    },
  })

  return { doc }
}

const checkForRemainingSpacePage = (doc, margin, marginTop, goToNextPage) => {
  let modifiedMarginTop = marginTop
  const remainingSpace = doc.internal.pageSize.height - margin
  if (remainingSpace < 15 && goToNextPage) {
    doc.addPage() // Add a new page if remaining space is insufficient
    modifiedMarginTop = 15 // Reset marginTop for the new page
  }

  return { modifiedMarginTop }
}

const preparePdf = (
  data,
  filteredData,
  fileName,
  extraData,
  logo,
  extraTwoColData
) => {
  let { doc, margin } = preparePdfHeader(fileName, extraData, logo)
  if (fileName === "shift report") {
    // special tabel for shift report
    doc = preparePdfTabelForShiftReport(doc, data, filteredData, margin)
  } else if (filteredData) {
    // tabel whose has more than two columns and the first row will has the descripe the values
    doc = preparePdfTabel(doc, data, filteredData, margin)
  } else {
    // tabel whose has two columns and the first column will has the keys descripe the values
    doc = preparePdfTwoColumnsTabel(doc, data, margin, extraTwoColData)
  }

  return doc
}

export {
  prepareTabelData,
  attachPdfTabelDataToDoc,
  getTabelRowData,
  checkForRemainingSpacePage,
  preparePdf,
}
