import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"

import { useFormik } from "formik"
import * as Yup from "yup"
import classes from "./index.module.scss"
import CompaniesModal from "./Modal/CompaniesModal"

import { useDispatch, useSelector } from "react-redux"
import { getShifEmplyees, getShifDetails } from "store/end-shift/actions"
import ManagerApproval from "./ManagerApproval"
import SendAndPrintPdf from "pages/Reports/shared/pdf/components/SendAndPrintPdf"
import { convertDate } from "pages/Reports/helpers"

const EndShift = props => {
  const dispatch = useDispatch()
  const [showCompayModal, setShowCompanyModal] = useState(false)
  const [inputValues, setInputValuse] = useState({
    Total_Orders: 0,
    After_Discount: 0,
    Discount: 0,
    Total_Cash: 0,
    Total_Visa: 0,
    Total_Host: 0,
    Orders_Count: 0,
    Returns_Count: 0,
    Total_Return: 0,
    Expenses_Count: 0,
    Total_Expenses: 0,
    Total_Unpaid_Delivery: 0,
    Total_Unpaid_Tables: 0,
    Value_Carried_Over_From: 0,
    Value_Carried_Over_To: 0,
    Net_Cash: 0,
    Total_Companies: 0,
  })

  const [endShiftEmpId, setEndShiftEmpId] = useState(0)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectedEmp: "",
    },
    validationSchema: Yup.object({
      selectedEmp: Yup.string().required("Please select Employee."),
    }),
    onSubmit: values => {
      //
    },
  })

  const shiftDetailsReducer = useSelector(state => state.shiftDetails)
  const loginUser = useSelector(state => state.loginUser)
  const {
    shiftDetails,
    shiftEmployees,
    loadingShiftDetails: loading,
  } = shiftDetailsReducer

  // Fetch shift employees
  useEffect(() => {
    dispatch(getShifEmplyees())
  }, [])

  useEffect(() => {
    validation.values.selectedEmp = ""
    setInputValuse({
      Total_Orders: 0,
      After_Discount: 0,
      Discount: 0,
      Total_Cash: 0,
      Total_Visa: 0,
      Total_Host: 0,
      Orders_Count: 0,
      Returns_Count: 0,
      Total_Return: 0,
      Expenses_Count: 0,
      Total_Expenses: 0,
      Total_Unpaid_Delivery: 0,
      Total_Unpaid_Tables: 0,
      Value_Carried_Over_From: 0,
      Value_Carried_Over_To: 0,
      Net_Cash: 0,
      Total_Companies: 0,
    })
  }, [shiftEmployees])

  // Fetch shift details
  useEffect(() => {
    if (!validation.values.selectedEmp) return
    dispatch(getShifDetails(validation.values.selectedEmp))

    shiftEmployees &&
      shiftEmployees.length > 0 &&
      shiftEmployees.map(
        shift =>
          shift.PK_Shift_ID === +validation.values.selectedEmp &&
          setEndShiftEmpId(shift.FK_EmpID)
      )
  }, [validation.values.selectedEmp])

  // Set shift details
  useEffect(() => {
    if (shiftDetails.length === 0) return

    setInputValuse({
      Total_Orders: shiftDetails.Table[0].price || 0,
      After_Discount: shiftDetails.Table[0].TotalAfterDiscount || 0,
      Discount: shiftDetails.Table[0].Discount || 0,
      Total_Cash: shiftDetails.Table[0].Cashprice || 0,
      Total_Visa: shiftDetails.Table[0].Visaprice || 0,
      Total_Host: shiftDetails.Table3[0].ordercount || 0,
      Orders_Count: shiftDetails.Table[0].ordercount || 0,
      Returns_Count: shiftDetails.Table1[0].returncount || 0,
      Total_Return: shiftDetails.Table1[0].returnedvalue || 0,
      Expenses_Count: shiftDetails.Table2[0].expensecount || 0,
      Total_Expenses: shiftDetails.Table2[0].price || 0,
      Total_Unpaid_Delivery: shiftDetails.Table6[0].NotPaidDelivery || 0,
      Total_Unpaid_Tables: shiftDetails.Table7[0].NotPaidTables || 0,
      Value_Carried_Over_From: shiftDetails.Table8[0].TransferedFrom || 0,
      Value_Carried_Over_To: shiftDetails.Table9[0].TransferedTo || 0,
      Net_Cash: shiftDetails.Table10[0].NetCashAfterCLose || 0,
      // Net_Cash: shiftDetails.Table10[0].NetCash || 0,
      Total_Companies:
        shiftDetails.Table12 && shiftDetails.Table12.length > 0
          ? shiftDetails.Table12.length || 0
          : 0,
    })
  }, [shiftDetails])


  const toggleCompanyModal = () => {
    setShowCompanyModal(prevState => !prevState)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Shift")}
            breadcrumbItem={props.t("End Shift")}
          />
          <Card>
            <CardBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col sm="3">
                    <div className="mb-3">
                      <Label>{props.t("Employee")}</Label>
                      <Input
                        name="selectedEmp"
                        type="select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.selectedEmp || ""}
                        invalid={
                          validation.touched.selectedEmp &&
                          validation.errors.selectedEmp
                            ? true
                            : false
                        }
                      >
                        <option value="" disabled>
                          {props.t("Select Employee")}
                        </option>
                        {shiftEmployees &&
                          shiftEmployees.length > 0 &&
                          shiftEmployees
                            .filter(one => one.FK_JobCategoryID === 1)
                            .map((emp, index) => (
                              <option key={index} value={emp.PK_Shift_ID}>
                                {emp.EmpName}
                              </option>
                            ))}
                      </Input>
                      {validation.touched.selectedEmp &&
                      validation.errors.selectedEmp ? (
                        <FormFeedback type="invalid">
                          {validation.errors.selectedEmp}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </Form>
              <Row>
                {loading ? (
                  <div className="text-center py-5">
                    <Spinner className="ms-2" color="primary" />
                  </div>
                ) : (
                  <>
                    {Object.entries(inputValues).map(([key, value], i) => (
                      <Col key={i} md={3} xs={6}>
                        <div className="mb-3">
                          <Label className={`${classes.res_font}`}>
                            {props.t(key.replace(/_/g, " "))}
                          </Label>
                          {/* <div className={`${classes.inputview}`}>{value}</div> */}
                          {i < Object.entries(inputValues).length - 1 ? (
                            <div className={`${classes.inputview}`}>
                              {value}
                            </div>
                          ) : (
                            <div
                              className={`${
                                shiftDetails.Table12 &&
                                shiftDetails.Table12.length
                                  ? classes.btn_view
                                  : classes.inputview
                              } position-relative`}
                              style={{
                                cursor:
                                  shiftDetails.Table12 &&
                                  shiftDetails.Table12.length
                                    ? "pointer"
                                    : "unset",
                              }}
                              onClick={
                                shiftDetails.Table12 &&
                                shiftDetails.Table12.length
                                  ? toggleCompanyModal
                                  : () => {}
                              }
                            >
                              {value}
                              {shiftDetails.Table12 &&
                              shiftDetails.Table12.length ? (
                                <i
                                  className={`mdi mdi-eye-outline d-block position-absolute ${classes.icon_absolute}`}
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                          )}
                        </div>
                      </Col>
                    ))}
                    <CompaniesModal
                      companyModal={showCompayModal}
                      handleCompanyToggle={toggleCompanyModal}
                      compList={shiftDetails.Table12 || []}
                    />
                  </>
                )}
              </Row>
              <div className="text-end">
                {!loading && validation.values.selectedEmp && (
                  <ManagerApproval
                    selectedEmp={validation.values.selectedEmp}
                    selectedEmpId={endShiftEmpId}
                  />
                )}
                {!loading && validation.values.selectedEmp ? (
                  <SendAndPrintPdf
                    extraData={{
                      Employee:
                        shiftEmployees && validation.values.selectedEmp
                          ? shiftEmployees.find(
                              emp =>
                                emp.PK_Shift_ID ===
                                +validation.values.selectedEmp
                            )
                            ? shiftEmployees.find(
                                emp =>
                                  emp.PK_Shift_ID ===
                                  +validation.values.selectedEmp
                              ).EmpName
                            : ""
                          : "",
                      "Shift start in":
                        shiftDetails &&
                        shiftDetails.Table11 &&
                        shiftDetails.Table11[0] &&
                        shiftDetails.Table11[0].StartTime
                          ? convertDate(
                              new Date(shiftDetails.Table11[0].StartTime)
                            )
                          : convertDate(new Date()),
                      "Shift id": validation.values.selectedEmp,
                      "Shift end in": "Still open",
                      Branch: loginUser.userData.fkBranch.branchName,
                      "Printed by": loginUser.userData.empName,
                    }}
                    data={Object.entries(inputValues).map(one => ({
                      [one[0]]: one[1],
                    }))}
                    filteredData={null}
                    extraTwoColData={{
                      header: "Companies List",
                      data: shiftDetails.Table12,
                      filteredData: ["No", "CompOrderValue", "CompanyName"],
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

EndShift.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(EndShift))
