import React, { useEffect, useState } from "react"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  Button,
  Table,
} from "reactstrap"

const InvolvmentModal = props => {
  const { involveModal, handleInvolveToggle, involveData } = props
  const [items, setItems] = useState([])

  const toggle = () => {
    handleInvolveToggle()
  }
  useEffect(() => {
    const itemsInvolved =
      involveData && involveData.Qty > 0 && involveData.InvolvedItems.split(",")
    setItems(itemsInvolved)
  }, [involveData])
  return (
    <Modal isOpen={involveModal} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} tag="h4">
        {involveData && (
          <span className="">{`${involveData.MaterialName}`}</span>
        )}
        {involveData && ` is ${props.t("Involving In")}`}
      </ModalHeader>
      <ModalBody>
        <div className="card jobs-categories">
          <div
            className="card-body"
            style={{ maxHeight: "470px", overflow: "auto" }}
          >
            <Row>
              {items &&
                items.length > 0 &&
                items.map((item, index) => (
                  <Col xl={3} md={6} key={index}>
                    <div className="px-3 py-2 rounded bg-light bg-opacity-50 d-block mb-2">
                      {item}
                    </div>
                  </Col>
                ))}
            </Row>
            <Row>
              <Col></Col>
            </Row>
          </div>
        </div>
        <div className="text-end">
          <Button
            type="button"
            color="secondary"
            className="mx-1"
            onClick={toggle}
          >
            <i className="mdi mdi-arrow-left me-1" />
            {props.t("Back")}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

InvolvmentModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(InvolvmentModal))
