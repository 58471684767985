import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import BarChart from "./BarChart"
import Dountchart from "./Dountchart"
import FilterInput from "pages/Reports/ItemsSalesReport/filterInput"

import { useSelector } from "react-redux"

const ItemsSalesCharts = props => {
  const [quantityChart, setQuantityChart] = useState({
    data: [],
    categories: [],
  })
  const [priceChart, setPriceChart] = useState({ data: [], categories: [] })

  const reports = useSelector(state => state.Reports)
  const { getItemsSales: getItemsSalesData } = reports

  useEffect(() => {
    if (getItemsSalesData) {
      if (!getItemsSalesData.length) return
    }

    // Sorting by Quantity
    const sortedByQuantity = [...getItemsSalesData].sort(
      (a, b) => b.totalQty - a.totalQty
    )
    setQuantityChart({
      data: sortedByQuantity.map(item => item.totalQty),
      categories: sortedByQuantity.map(item => item.ItemName),
    })

    // Sorting by Price
    const sortedByPrice = [...getItemsSalesData].sort(
      (a, b) => b.TotalSales - a.TotalSales
    )
    setPriceChart({
      data: sortedByPrice.map(item => item.TotalSales),
      categories: sortedByPrice.map(item => item.ItemName),
    })
  }, [getItemsSalesData])

  const setNumberOfQtyToShow = number => {
    if (getItemsSalesData) {
      if (!getItemsSalesData.length) return
    }

    // Sorting by Quantity
    const sortedByQuantity = [...getItemsSalesData].sort(
      (a, b) => b.totalQty - a.totalQty
    )

    let topTenByQuantity = sortedByQuantity
    if (typeof number === "number")
      topTenByQuantity = sortedByQuantity.slice(0, number)

    setQuantityChart({
      data: topTenByQuantity.map(item => item.totalQty),
      categories: topTenByQuantity.map(item => item.ItemName),
    })
  }

  const setNumberOfSalesToShow = number => {
    if (getItemsSalesData) {
      if (!getItemsSalesData.length) return
    }

    // Sorting by Price
    const sortedByPrice = [...getItemsSalesData].sort(
      (a, b) => b.TotalSales - a.TotalSales
    )
    let topTenByPrice = sortedByPrice
    if (typeof number === "number")
      topTenByPrice = sortedByPrice.slice(0, number)

    setPriceChart({
      data: topTenByPrice.map(item => item.TotalSales),
      categories: topTenByPrice.map(item => item.ItemName),
    })
  }

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">
              {props.t("Products Total Sales Chart")}
            </CardTitle>
            <FilterInput
              allListSize={30}
              setNumberToShowHandler={setNumberOfSalesToShow}
            />
            <Row>
              <Col lg={6}>
                <BarChart
                  data={priceChart.data}
                  categories={priceChart.categories}
                />
              </Col>
              <Col lg={6}>
                <Dountchart
                  data={priceChart.data}
                  labels={priceChart.categories}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col lg={12}>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">
              {props.t("Products Quantity Chart")}
            </CardTitle>
            <FilterInput
              allListSize={30}
              setNumberToShowHandler={setNumberOfQtyToShow}
            />
            <Row>
              <Col lg={6}>
                <BarChart
                  data={quantityChart.data}
                  categories={quantityChart.categories}
                />
              </Col>
              <Col lg={6}>
                <Dountchart
                  data={quantityChart.data}
                  labels={quantityChart.categories}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

ItemsSalesCharts.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ItemsSalesCharts))
