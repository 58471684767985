import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import { Card, CardBody, Col, Row, Spinner } from "reactstrap"
import classes from "../../index.module.scss"
import Dountchart from "pages/Dashboard/components/ordersSummary/DonutChart"
import { useSelector, useDispatch } from "react-redux"
import { getDashboardDineIn } from "store/actions"

import {
  orderSummaryDountMonthDummyDataDummyData,
  orderSummaryDountTodayDummyDataDummyData,
  orderSummaryDountWeekDummyDataDummyData,
} from "pages/Dashboard/data"

const OrderSummary = props => {
  const dispatch = useDispatch()
  const [periodData, setPeriodData] = useState(
    orderSummaryDountTodayDummyDataDummyData
  )

  const [dineInOrder, setDineinOrder] = useState([])
  const [dinInOrderValues, setDinInOrderValues] = useState([])

  const {
    dashboardDinInList: dashboardDinInList,
    dashboardDinInLoading: isLoading,
  } = useSelector(state => state.dashboard)

  const currentPeriodTypeHandler = periodType => {
    if (periodType === "today") {
      setPeriodData(orderSummaryDountTodayDummyDataDummyData)
    } else if (periodType === "monthly") {
      setPeriodData(orderSummaryDountMonthDummyDataDummyData)
    } else if (periodType === "weekly") {
      setPeriodData(orderSummaryDountWeekDummyDataDummyData)
    }
  }

  useEffect(() => {
    dispatch(getDashboardDineIn())
  }, [])

  useEffect(() => {
    setDineinOrder(dashboardDinInList)
    Object.keys(dashboardDinInList).length &&
      setDinInOrderValues([
        dashboardDinInList.CanceledOrders,
        dashboardDinInList.OpenedOrdersCount,
        dashboardDinInList.ClosedOrdersCount,
      ])
  }, [dashboardDinInList])

  return (
    <Card style={{ borderRadius: "16px" }}>
      <CardBody>
        <div className="d-sm-flex flex-wrap">
          <div className="d-flex flex-column mb-4">
            <h4 className={"card-title" + classes.customerMapTitle}>
              {props.t("dineInSummary")}
            </h4>
            <p className={classes.customerMapDescription}>
              {props.t("enhanceDineInExerience")}.
            </p>
          </div>
          {/* <PeriodType currentPeriodTypeHandler={currentPeriodTypeHandler} /> */}
        </div>
        <div className={`${classes.manageOrder} badge-soft-primary`}>
          <div className={classes.subContainerSection}>
            <div className={`${classes.ordersNumber} bg-primary`}>{dineInOrder.AllOrdersCount || 0}</div>
            <div className={classes.newOrderContainer}>
              <span className={`${classes.newOrder}`}>{props.t("totalDineInOrders")}</span>
              <span className={`${classes.newOrderDot}`}></span>
            </div>
          </div>
          {/* <Link to={""} className={`${classes.newOrderLink} btn btn-sm btn-soft-primary btn btn-secondary fw-bold`}>
            <span>Manage orders</span>
            <i className="bx bx-chevron-right font-size-18 bx-fade-right position-relative" style={{top: '4px'}}></i>
          </Link>  */}
        </div>
        <div className={"gap-2 py-4" + " " + classes.orderSummaryCardContainer}>
          {/* canceled card */}
          <div className={`bg-light ${classes.orderSummaryCard}`}>
            <h4 className={classes.orderSummaryCardValue}>
              {dashboardDinInList.ClosedOrdersCount}
            </h4>
            <p>
              <span
                className={`${classes.newOrderDot} bg-success d-inline-block mx-2`}
              ></span>
              <span className={classes.orderSummaryCardTitle}>{props.t("paidOrders")}</span>
            </p>
          </div>
          {/* Open Orders card  */}
          <div className={`bg-light ${classes.orderSummaryCard}`}>
            <h4 className={classes.orderSummaryCardValue}>
              {dashboardDinInList.OpenedOrdersCount}
            </h4>
            <p>
              <span
                className={`${classes.newOrderDot} bg-primary d-inline-block mx-2`}
              ></span>
              <span className={classes.orderSummaryCardTitle}>{props.t("openOrders")}</span>
            </p>
          </div>
          {/* delivery card */}
          <div className={`bg-light ${classes.orderSummaryCard}`}>
            <h4 className={classes.orderSummaryCardValue}>
              {dashboardDinInList.CanceledOrders}
            </h4>
            <p>
              <span
                className={`${classes.newOrderDot} bg-dark d-inline-block mx-2`}
              ></span>
              <span className={classes.orderSummaryCardTitle}>
                {("canceledOrders")}
              </span>
            </p>
          </div>
        </div>

        <Row className="align-items-center">
          <Col sm="6" xxl="4">
            {/* <Dountchart
              data={periodData.map(one => one.value)}
              labels={periodData.map(one => one.title)}
            /> */}
            <Dountchart
              data={dinInOrderValues}
              labels={periodData.map(one => one.title)}
            />
          </Col>
          {isLoading ? (
            <div className="text-center">
              <Spinner className="ms-2" color="primary" />
            </div>
          ) : (
            Object.keys(dineInOrder).length && (
              <Col sm="6" xxl="8" className="d-flex flex-column gap-3">
                {periodData.map((one, index) => (
                  <div
                    key={index}
                    className={classes.dountChartProgressContainer}
                  >
                    <Col xs="4" className={classes.dountChartTitle}>
                      {one.title === "canceled" && (
                        <span>
                          <span>{`${one.title}  `}</span>
                          {dineInOrder.AllOrdersCount ? (dineInOrder.CanceledOrders /
                            dineInOrder.AllOrdersCount) *
                            100 : "0"}
                          %
                        </span>
                      )}
                      {one.title === "Open Orders" && (
                        <span>
                          <span>{`${one.title}  `}</span>
                          {dineInOrder.AllOrdersCount ? (dineInOrder.OpenedOrdersCount /
                            dineInOrder.AllOrdersCount) *
                            100 : "0"}
                          %
                        </span>
                      )}
                      {one.title === "Paid Orders" && (
                        <span>
                          <span>{`${one.title}  `}</span>
                          {dineInOrder.AllOrdersCount ? (dineInOrder.ClosedOrdersCount /
                            dineInOrder.AllOrdersCount) *
                            100 : "0"}
                          %
                        </span>
                      )}
                    </Col>
                    <Col xs="7" className={classes.dountChartProgressParent}>
                      <div
                        className="progress animated-progess progress-sm"
                        style={{ height: "100%" }}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width:
                              one.title === "canceled"
                                ? (dineInOrder.AllOrdersCount ? (dineInOrder.CanceledOrders /
                                    dineInOrder.AllOrdersCount) *
                                    100 : 0) +
                                  "%"
                                : one.title === "Open Orders"
                                ? (dineInOrder.AllOrdersCount ? (dineInOrder.OpenedOrdersCount /
                                    dineInOrder.AllOrdersCount) *
                                  100 : 0)
                                : one.title === "Paid Orders"
                                ? (dineInOrder.AllOrdersCount ? (dineInOrder.ClosedOrdersCount /
                                    dineInOrder.AllOrdersCount) *
                                    100 : 0) +
                                  "%"
                                : "0%",
                            height: "100%",
                            backgroundColor: one.color,
                          }}
                          aria-valuenow="78"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </Col>
                    <Col xs="1" className={classes.dountChartProgressValue}>
                      {one.title === "canceled" && dineInOrder.CanceledOrders}
                      {one.title === "Open Orders" &&
                        dineInOrder.OpenedOrdersCount}
                      {one.title === "Paid Orders" &&
                        dineInOrder.ClosedOrdersCount}
                    </Col>
                  </div>
                ))}
              </Col>
            )
          )}
        </Row>
      </CardBody>
    </Card>
  )
}

OrderSummary.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(OrderSummary))
