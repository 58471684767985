import React from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import { preparePdf } from "../helpers"

const PrintPdf = props => {
  const { data, filteredData, fileName, extraData, logo, extraTwoColData } =
    props

  const printPdfHandler = () => {
    const doc = preparePdf(
      data,
      filteredData,
      fileName,
      extraData,
      logo,
      extraTwoColData
    )
    doc.save(`${fileName}.pdf`)
  }

  return (
    <button
      type="button"
      className="btn btn-primary btn-label mx-1"
      onClick={printPdfHandler}
    >
      <i className="bx bxs-file-pdf label-icon"></i> {props.t("Print Pdf")}
    </button>
  )
}

PrintPdf.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(PrintPdf))
