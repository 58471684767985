import {
  GET_MAIN_PRIVILLAGE,
  GET_MAIN_PRIVILLAGE_SUCCESS,
  GET_MAIN_PRIVILLAGE_FAIL,

  GET_PAGE_ACTION,
  GET_PAGE_ACTION_SUCCESS,
  GET_PAGE_ACTION_FAIL
} from "./actionTypes"

export const getMainPrivillage = (params) => ({
    type: GET_MAIN_PRIVILLAGE,
    payload: params
  })
  
  export const getMainPrivillageSuccess = data => ({
    type: GET_MAIN_PRIVILLAGE_SUCCESS,
    payload: data,
  })
  export const getMainPrivillageFail = err => ({
    type: GET_MAIN_PRIVILLAGE_FAIL,
    payload: err,
  })

  export const getPageAction = (params) => ({
    type: GET_PAGE_ACTION,
    payload: params
  })

  export const getPageActionSuccess = data => ({
    type: GET_PAGE_ACTION_SUCCESS,
    payload: data
  })

  export const getPageActionFail = err => ({
    type: GET_PAGE_ACTION_FAIL,
    payload: err
  })
