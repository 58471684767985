const GET_SHIF_EMPLOYEES = "GET_SHIF_EMPLOYEES";
const GET_SHIF_EMPLOYEES_SUCCESS = "GET_SHIF_EMPLOYEES_SUCCESS";
const GET_SHIF_EMPLOYEES_FAIL = "GET_SHIF_EMPLOYEES_FAIL";

const GET_SHIF_DETAILS = "GET_SHIF_DETAILS";
const GET_SHIF_DETAILS_SUCCESS = "GET_SHIF_DETAILS_SUCCESS";
const GET_SHIF_DETAILS_FAIL = "GET_SHIF_DETAILS_FAIL";

const GET_MANAGER_APPROVAL = "GET_MANAGER_APPROVAL";
const GET_MANAGER_APPROVAL_SUCCESS = "GET_MANAGER_APPROVAL_SUCCESS";
const GET_MANAGER_APPROVAL_FAIL = "GET_MANAGER_APPROVAL_FAIL";
const GET_MANAGER_APPROVAL_RESET = "GET_MANAGER_APPROVAL_RESET";

const SET_END_SHIFT_EMP_ID = "SET_END_SHIFT_EMP_ID";
const SET_END_SHIFT_EMP_ID_SUCCESS = "SET_END_SHIFT_EMP_ID_SUCCESS";
const SET_END_SHIFT_EMP_ID_FAIL = "SET_END_SHIFT_EMP_ID_FAIL";
const SET_END_SHIFT_EMP_ID_RESET = "SET_END_SHIFT_EMP_ID_RESET";

const GET_EMAILS = "GET_EMAILS";
const GET_EMAILS_SUCCESS = "GET_EMAILS_SUCCESS";
const GET_EMAILS_FAIL = "GET_EMAILS_FAIL";

const SEND_EMAIL = "SEND_EMAIL";
const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
const SEND_EMAIL_FAIL = "SEND_EMAIL_FAIL";

export {
    GET_SHIF_EMPLOYEES,
    GET_SHIF_EMPLOYEES_SUCCESS,
    GET_SHIF_EMPLOYEES_FAIL,
    GET_SHIF_DETAILS,
    GET_SHIF_DETAILS_SUCCESS,
    GET_SHIF_DETAILS_FAIL,
    GET_MANAGER_APPROVAL,
    GET_MANAGER_APPROVAL_SUCCESS,
    GET_MANAGER_APPROVAL_FAIL,
    GET_MANAGER_APPROVAL_RESET,
    SET_END_SHIFT_EMP_ID,
    SET_END_SHIFT_EMP_ID_SUCCESS,
    SET_END_SHIFT_EMP_ID_FAIL,
    SET_END_SHIFT_EMP_ID_RESET,
    GET_EMAILS,
    GET_EMAILS_SUCCESS,
    GET_EMAILS_FAIL,
    SEND_EMAIL,
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_FAIL
};
