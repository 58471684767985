import React, { useState } from "react"
import { Link } from "react-router-dom"

import classes from "../index.module.scss"
import classNames from "classnames"

const PeriodType = props => {
  const [periodType, setPeriodType] = useState("today")

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    props.currentPeriodTypeHandler(pType)
  }

  return (
    <div className="ms-auto">
      <ul className={"nav nav-pills bg-light" + " " + classes.linkesContainer}>
        <li className="nav-item">
          <Link
            to="#"
            className={classNames(
              { active: periodType === "monthly" },
              "nav-link"
            )}
            onClick={() => {
              onChangeChartPeriod("monthly")
            }}
            id="one_month"
          >
            Monthly
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="#"
            className={classNames(
              { active: periodType === "weekly" },
              "nav-link"
            )}
            onClick={() => {
              onChangeChartPeriod("weekly")
            }}
            id="one_month"
          >
            Weekly
          </Link>{" "}
        </li>
        <li className="nav-item">
          <Link
            to="#"
            className={classNames(
              { active: periodType === "today" },
              "nav-link"
            )}
            onClick={() => {
              onChangeChartPeriod("today")
            }}
            id="one_month"
          >
            Today
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default PeriodType
