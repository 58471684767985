import React from "react"

import { useLocation } from "react-router-dom"
import PrintPdf from "./PrintPdf"
import SendPdf from "./SendPdf"
import { useSelector } from "react-redux"

const SendAndPrintPdf = props => {
  const { pathname } = useLocation()
  const { data, filteredData, extraData, extraTwoColData } = props
  const { logo } = useSelector(state => state.businessSettings)

  const [fileName, setFileName] = React.useState("")
  React.useEffect(() => {
    setFileName(pathname.split("/").pop().split("-").join(" "))
  }, [pathname])

  return (
    <>
      <PrintPdf
        data={data}
        filteredData={filteredData}
        fileName={fileName}
        extraData={extraData}
        logo={logo}
        extraTwoColData={extraTwoColData}
      />
      <SendPdf
        data={data}
        filteredData={filteredData}
        fileName={fileName}
        extraData={extraData}
        logo={logo}
        extraTwoColData={extraTwoColData}
      />
    </>
  )
}

export default SendAndPrintPdf
