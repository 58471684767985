import React, { useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  Table,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { useFormik } from "formik"
import { postCategory } from "store/actions"
import * as Yup from "yup"
import { useEffect } from "react"

const MainCategoryModal = props => {
  const { mainCatModal, handleMainCategoryToggle } = props
  const dispatch = useDispatch()
  const [mainCatName, setMainCatName] = useState("")
  const [isSubmitDisable, setIsSubmitDisable] = useState(false)

  const newCategoryAdded = useSelector(
    state => state.categories.newCategoryAdded
  )

  const toggle = () => {
    handleMainCategoryToggle()
  }

  useEffect(() => {
    if (Object.keys(newCategoryAdded).length > 0) {
      if(!newCategoryAdded.hasOwnProperty("fkCategoryId")) {

        setIsSubmitDisable(false)
        validation.resetForm()
        toggle()
      }
    }
  }, [newCategoryAdded])
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      mainCatName: mainCatName || "",
    },
    validationSchema: Yup.object({
      mainCatName: Yup.string().required(props.t("Please Enter Main Category")),
    }),
    onSubmit: values => {
      const newMainCat = {
        categoryName: values["mainCatName"],
      }
      // save new user
      try {
        dispatch(postCategory(newMainCat))
        setIsSubmitDisable(true)
      } catch (err) {
        console.log(err)
      }
    },
  })

  return (
    <Modal isOpen={mainCatModal} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} tag="h4">
        {props.t("Add Main Category")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">
                  {props.t("Add Main Category")}
                </Label>
                <Input
                  name="mainCatName"
                  type="text"
                  value={validation.values.mainCatName || ""}
                  invalid={
                    validation.touched.mainCatName &&
                    validation.errors.mainCatName
                      ? true
                      : false
                  }
                  placeholder={props.t("Enter Main Category Name")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.mainCatName &&
                validation.errors.mainCatName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.mainCatName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" disabled={isSubmitDisable} className="btn btn-success save-user">
                  {isSubmitDisable ? 
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  : <i className="mdi mdi-check-circle-outline me-1" />}
                  {props.t("Save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

MainCategoryModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(MainCategoryModal))
