import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"

import { useSelector } from "react-redux"

const AttachmentModal = props => {
  const { isOpen, toggle, attachData } = props
  const [attachType, setAttachType] = useState({ type: "", dataType: "" })

  useEffect(() => {
    console.log(attachData)
    if (attachData) {
      attachData.AttachmentPath &&
        attachData.AttachmentPath.startsWith("JVB") &&
        setAttachType({ type: "pdf", dataType: "application/pdf" })
      attachData.AttachmentPath &&
        attachData.AttachmentPath.startsWith("/9j") &&
        setAttachType({ type: "jpeg", dataType: "image/jpeg" })
      attachData.AttachmentPath &&
        attachData.AttachmentPath.startsWith("iVB") &&
        setAttachType({ type: "png", dataType: "image/png" })
    }
  }, [attachData])

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      className="exampleModal modal-lg"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>{props.t("Attachment")}</ModalHeader>
        <ModalBody>
          <div className="">
            {attachData ? (
              <div>
                {attachType.type === "pdf" && (
                  <div className="text-center">
                    <iframe
                      src={`data:${attachType.dataType};base64,${attachData.AttachmentPath}`}
                      className="d-block m-auto"
                      style={{ minHeight: "400px" }}
                    />
                    <a
                      download={`${attachData.PaymentCategory_Name}-${attachData.Payment_Name}.pdf`}
                      href={`data:${attachType.dataType};base64,${attachData.AttachmentPath}`}
                      className="btn btn-primary  btn-label m-2"
                    >
                      <i className="mdi mdi-download label-icon"></i>
                      {props.t("Download")}
                    </a>
                  </div>
                )}
                {attachType.type === "jpeg" && (
                  <div  className="text-center">
                    <img
                      src={`data:${attachType.dataType};base64,${attachData.AttachmentPath}`}
                      className="d-block m-auto"
                      style={{ maxWidth: "370px" }}
                    />
                    <a
                      download={`${attachData.PaymentCategory_Name}-${attachData.Payment_Name}.jpg`}
                      href={`data:${attachType.dataType};base64,${attachData.AttachmentPath}`}
                      className="btn btn-primary  btn-label m-2"
                    >
                      <i className="mdi mdi-download label-icon"></i>
                      {props.t("Download")}
                    </a>
                  </div>
                )}
                {attachType.type === "png" && (
                  <div className="text-center">
                  <img
                    src={`data:${attachType.dataType};base64,${attachData.AttachmentPath}`}
                    className="d-block m-auto"
                    style={{ maxWidth: "370px" }}
                  />
                  <a
                      download={`${attachData.PaymentCategory_Name}-${attachData.Payment_Name}.png`}
                      href={`data:${attachType.dataType};base64,${attachData.AttachmentPath}`}
                      className="btn btn-primary  btn-label m-2"
                    >
                      <i className="mdi mdi-download label-icon"></i>
                      {props.t("Download")}
                    </a>
                  </div>
                )}
              </div>
            ) : (
              "No Data Found"
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="success" onClick={toggle}>
            <i className="bx bx-check-circle label-icon"></i> {props.t("Close")}
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

AttachmentModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(AttachmentModal))
