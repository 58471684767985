import {
  GET_TBL_SUPPLIER_PAYMENT_SUCCESS,
  GET_TBL_SUPPLIER_PAYMENT_FAIL,
  GET_TBL_SUPPLIER_SUCCESS,
  GET_TBL_SUPPLIER_FAIL,
  ADD_NEW_SUPPLIER,
  ADD_NEW_SUPPLIER_SUCCESS,
  ADD_NEW_SUPPLIER_FAIL,
} from "store/supplier/actionTypes"

const INIT_STATE = {
  tblSupplierPayment: [],
  tblSupplier: [],
  newSupplierAdded: {},
  error: {},
}

const suppliers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TBL_SUPPLIER_PAYMENT_SUCCESS:
      return {
        ...state,
        tblSupplierPayment: action.payload,
      }

    case GET_TBL_SUPPLIER_SUCCESS:
      return {
        ...state,
        tblSupplier: action.payload,
      }

    case ADD_NEW_SUPPLIER:
      return {
        ...state,
        newSupplierAdded: {},
      }

    case ADD_NEW_SUPPLIER_SUCCESS:
      return {
        ...state,
        tblSupplier: [...state.tblSupplier, action.payload.rs],
        newSupplierAdded: action.payload.rs
      }

    case GET_TBL_SUPPLIER_PAYMENT_FAIL:
    case GET_TBL_SUPPLIER_FAIL:
        case ADD_NEW_SUPPLIER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default suppliers
