import usFlag from "../assets/images/flags/us.jpg"
import spain from "../assets/images/flags/spain.jpg"
import germany from "../assets/images/flags/germany.jpg"
import italy from "../assets/images/flags/italy.jpg"
import russia from "../assets/images/flags/russia.jpg"
import egypt from "../assets/images/flags/egypt.jpg"

const languages = {
  en: {
    label: "English",
    flag: usFlag,
  },
  ar: {
    label: "العربيه Arabic",
    flag: egypt,
  },
  gr: {
    label: "Deutsch",
    flag: germany,
  },
  mal: {
    label: "മലയാളം (Malayāḷaṁ)",
  },
  chi: {
    label: "简体中文 (Jiǎntǐ Zhōngwén)",
  },
  tur: {
    label: "Türkçe",
  },
  urdu: {
    label: "اردو (Urdu)",
  },
}

export default languages
