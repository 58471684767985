import {
    GET_TABEL_SAFE,
    GET_TABEL_SAFE_SUCCESS,
    GET_TABEL_SAFE_FAIL,
} from "store/tabel-safe/actionTypes";

const getTabelSafe = () => ({
    type: GET_TABEL_SAFE,
});

const getTabelSafeSuccess = data => ({
    type: GET_TABEL_SAFE_SUCCESS,
    payload: data
});

const getTabelSafeFail = error => ({
    type: GET_TABEL_SAFE_FAIL,
    payload: error
});

export {
    getTabelSafe,
    getTabelSafeSuccess,
    getTabelSafeFail
};