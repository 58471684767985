import React, { useMemo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import TableContainer from "components/Common/TableContainer"

import { Col, Row, Card, CardBody, Spinner, Button, UncontrolledTooltip } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

import Breadcrumbs from "components/Common/Breadcrumb"

import { getSafeTranfer } from "store/actions"
import AttachmentModal from "./AttachmentModal"

const SafeTransferReport = props => {

  document.title = "Safe Transfer | Reports"
  const dispatch = useDispatch()

  const [attachData, setAttachData] = useState({})
  const [attachModal, setAttachModal] = useState(false)

  const reports = useSelector(state => state.Reports)
  const safeTransfer = useSelector(state => state.safeTransfer.safeTransfer)
  const { getSafeTransferData: safeTransferData, loadingSafeTransferData: loading } = reports
  
  useEffect(() => {
    dispatch(getSafeTranfer())
  }, [safeTransfer])

  const toggleAttachModel = () => {
    setAttachModal(prevState => !prevState)
  }

  const handleAttachModal = data => {
    setAttachData(data)
    toggleAttachModel()
  }
  
  const columns = useMemo(
    () => [
      {
        Header: props.t("Date"),
        accessor: "TransfereDate",
        filterable: true,
        Cell: cellProps => {
          const date = new Date(cellProps.value)
          const year = date.getFullYear()
          const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
          const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
          return <>{cellProps.value ? year + "-" + month + "-" + day : ""}</>
        },
      },
      {
        Header: props.t("Time"),
        accessor: "TransfereTime",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("Safe From"),
        accessor: "SafeNameFrom",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("Safe To"),
        accessor: "SafeNameTo",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("Value"),
        accessor: "Value",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("Employee"),
        accessor: "EmpFromName",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("Notes"),
        accessor: "Notes",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
      
      {
        Header: props.t("Attach"),
        Cell: cellProps => {
          const { AttachmentPath } = cellProps.row.original
          return (
            <div className="list-unstyled hstack gap-1 mb-0">
              <li>
                {AttachmentPath !== null && AttachmentPath !== "" && <Button
                  type="button"
                  className="btn btn-sm btn-soft-primary"
                  onClick={() =>
                    handleAttachModal(cellProps.row.original)
                  }
                >
                  <i className="mdi mdi-eye-outline" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    {props.t("Attach")}
                  </UncontrolledTooltip>
                </Button>}
              </li>
            </div>
          )
        },
      },
    ],
    [props.t]
  )



  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Reports")}
            breadcrumbItem={props.t("Safe Transfer report")}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="text-center py-5">
                      <Spinner className="ms-2" color="primary" />
                    </div>
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={safeTransferData}
                      isAddOptions={false}
                      customPageSize={5}
                      allListSize={
                        safeTransferData ? safeTransferData.length : []
                      }
                      customPageSizeOptions={true}
                    />
                  )}
                </CardBody>
                {/* {safeBalanceList &&
                  (safeBalanceList.length && !loading ? (
                    <div className="mt-2 d-flex justify-content-end">
                      <SendAndPrintPdf
                        data={safeBalanceList}
                        extraData={null}
                        filteredData={["No", "SafeName", "Balance"]}
                        extraTwoColData={null}
                      />
                    </div>
                  ) : (
                    <></>
                  ))} */}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <AttachmentModal isOpen={attachModal} toggle={toggleAttachModel} attachData={attachData} />
    </React.Fragment>
  )
}

SafeTransferReport.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SafeTransferReport))
