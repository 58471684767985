import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

// Buffalo Login
import loginUser from "./login/reducer";

//reports
import Reports from "./reports/reducer";

// Items
import items from "./products/item/reducer";

// Item Notes
import itemNotes from "./products/itemNotes/reducer";

//Item Ingrediants
import itemIngrediant from "./products/ingrediants/reducer";

//Units
import units from "./products/unit/reducer";

// Expenses
import expenses from "./expense/reducer";

// End Shift 
import shiftDetails from "store/end-shift/reducer";

// Tabel Safe 
import tabelSafe from "store/tabel-safe/reducer";

//Material
import materials from "./products/materials/reducer";

//Prepare area
import prepareAreas from "./products/prepareArea/reducer";

import components from "./products/components/reducer";

import categories from "./products/category/reducer";
// Suppliers
import suppliers from "store/supplier/reducer";

// End Shift Report
import endShiftReport from "store/end-shift-report/reducer";

// Safe Transfer
import safeTransfer from "./safe-transfer/reducer";

// Purchase
import purchase from "./purchase/reducer";

import businessSettings from "./business-settings/reducer";

import compManufacture from "./component/reducer"
import stockTransfer from "./stock-transfer/reducer"

//dashboard
import dashboard from "./dashboard/reducer";

// Privillage
import privillage from "./privillage/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,

  loginUser,
  Reports,
  items,
  itemNotes,
  itemIngrediant,
  expenses,
  shiftDetails,
  tabelSafe,
  materials,
  prepareAreas,
  components,
  categories,

  suppliers,
  endShiftReport,
  safeTransfer,
  purchase,
  businessSettings,
  units,
  compManufacture,
  stockTransfer,
  dashboard,
  
  privillage
});

export default rootReducer;
