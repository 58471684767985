import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"

import { useSelector } from "react-redux"

const TransactionModal = props => {
  const { isOpen, toggle, orderMasterId, priceAndDiscount, startEndDate } =
    props
  const [data, setData] = useState([])

  const reports = useSelector(state => state.Reports)
  const { getShiftReport: shiftReportData } = reports

  useEffect(() => {
    if (shiftReportData && shiftReportData.Table2 && orderMasterId) {
      const filterdData = shiftReportData.Table2.filter(
        item => item.MasterID === orderMasterId
      )
      const mapedData = filterdData.map(one => {
        return {
          item: one.ItemName,
          qty: one.Qty,
          price: one.ItemPrice,
          totalPrice: one.Qty * one.ItemPrice,
          Notes: one.Notes,
        }
      })
      setData(mapedData)
    } else {
      setData([])
    }
  }, [shiftReportData, orderMasterId])

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal modal-lg"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>{props.t("Transaction")}</ModalHeader>
        <ModalBody>
          {shiftReportData && shiftReportData.Table1 ? (
            <Row className="m-0">
              {shiftReportData.Table && shiftReportData.Table.length > 0 && (
                <>
                  <Col md={2} sm={3} className="badge-soft-primary disabled text-secondary border-top border-light">
                    {props.t("Employee")}
                  </Col>
                  <Col md={4} sm={9} className="fw-bold border">
                    {shiftReportData &&
                    shiftReportData.Table[0] &&
                    shiftReportData.Table[0].EmpName
                      ? shiftReportData.Table.length === 1
                        ? shiftReportData.Table[0].EmpName
                        : props.t("All")
                      : ""}
                  </Col>
                  <Col md={2} sm={3} className="badge-soft-primary text-secondary border-top border-white">
                    {props.t("Shift No.")}
                  </Col>
                  <Col md={4} sm={9} className="fw-bold border">
                    {shiftReportData &&
                      shiftReportData.Table1 &&
                      shiftReportData.Table[0].PK_Shift_ID}
                  </Col>
                  <Col md={2} sm={3} className="badge-soft-primary text-secondary border-top border-white">
                    {props.t("Start")}
                  </Col>
                  <Col md={4} sm={9} className="fw-bold border">
                    {startEndDate.startDate}
                  </Col>
                  <Col md={2} sm={3} className="badge-soft-primary text-secondary border-top border-white">
                    {props.t("End")}
                  </Col>
                  <Col md={4} sm={9} className="fw-bold border">
                    {startEndDate.endDate}
                  </Col>
                  <Col md={2} sm={3} className="badge-soft-primary text-secondary  border-top border-white">
                    {props.t("Total Price")}
                  </Col>
                  <Col md={4} sm={9} className="fw-bold border">
                    {priceAndDiscount.price}
                  </Col>
                  <Col md={2} sm={3} className="badge-soft-primary text-secondary  border-top border-white">
                    {props.t("After Discount")}
                  </Col>
                  <Col md={4} sm={9} className="fw-bold border">
                    {priceAndDiscount.AfterDiscount}
                  </Col>
                  <Col md={2} sm={3} className="badge-soft-primary text-secondary  border-top border-white">
                    {props.t("Transaction No.")}
                  </Col>
                  <Col md={4} sm={9} className="fw-bold border-top border">
                    {shiftReportData &&
                      shiftReportData.Table1 &&
                      shiftReportData.Table1.length}
                  </Col>
                </>
              )}
            </Row>
          ) : (
            <></>
          )}
          <div
            className="table-responsive"
            style={{ maxHeight: "300px", overflowY: "auto" }}
          >
            <Table className="table align-middle table-nowrap mt-1">
              <thead>
                <tr>
                  <th scope="col">{props.t("Item")}</th>
                  <th scope="col">{props.t("Qty.")}</th>
                  <th scope="col">{props.t("Price")}</th>
                  <th scope="col">{props.t("Total Price")}</th>
                  <th scope="col">{props.t("Notes")}</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  data.map((one, i) => (
                    <tr key={i}>
                      <td>{one.item}</td>
                      <td>{one.qty}</td>
                      <td>{one.price}</td>
                      <td>{one.totalPrice}</td>
                      <td>{one.Notes}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="success" onClick={toggle}>
            <i className="bx bx-check-circle label-icon"></i> {props.t("Save")}
          </Button>
        </ModalFooter>
      </div>
      {/* test */}
    </Modal>
  )
}

TransactionModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(TransactionModal))
