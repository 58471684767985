import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ITEM_INGREDIANTS } from "./actionTypes"

import {
  getItemIngrediantSuccess,
  getItemIngrediantsFail
} from "./actions"


import { getItemIngrediant } from "../../../helpers/backend_helper"

function* fetchItemIngrediant(item) {
  try {
    const response = yield call(getItemIngrediant, item.itemId)
    yield put(getItemIngrediantSuccess(response))
  } catch (err) {
    yield put(getItemIngrediantsFail(err))
  }
}

function* itemIngrediantSaga() {
  yield takeEvery(GET_ITEM_INGREDIANTS, fetchItemIngrediant)
}

export default itemIngrediantSaga
