import React, { useEffect } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { Row } from "reactstrap";
import * as Yup from "yup";

import { useFormik } from "formik";
import { convertParametersToString } from "pages/Reports/helpers";

const DailyDate = (props) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because month is zero-indexed
    const formattedCurrentDate = `${year}-${month}`;

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            month: formattedCurrentDate,
        },
        validationSchema: Yup.object({
            month: Yup.string().required("Date is required"),
        }),
        onSubmit: (values) => {
            // 
        },
    });

    useEffect(() => {
        if (validation.values.month === "") return;
        const arrayOfDate = validation.values.month.split("-");
        const paramsObject = {
            month: arrayOfDate[1],
            year: arrayOfDate[0],
        };
        const stringParameter = convertParametersToString(paramsObject);
        props.getDataFilteredByDate(stringParameter, validation.values.month);
    }, [validation.values.month]);

    return (
        <Row className="mx-1" style={{ flexDirection: "row" }}>
            <label
                htmlFor="example-month-input"
                className="col-auto col-form-label px-0"            >
                {props.t("Date")}
            </label>
            <div className="col-auto">
                <input
                    className="form-control"
                    type="month"
                    id="example-month-input"
                    name="month"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.month || ""}
                />
            </div>
        </Row>
    );
};

DailyDate.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(DailyDate));
