import {
    ADD_EXPENSE,
    ADD_EXPENSE_SUCCESS,
    ADD_EXPENSE_FAIL,
    UPLOAD_EXPENSE_IMAGES,
    UPLOAD_EXPENSE_IMAGES_SUCCESS,
    UPLOAD_EXPENSE_IMAGES_FAIL,
    ADD_EXPENSE_CAT,
    ADD_EXPENSE_CAT_SUCCESS,
    ADD_EXPENSE_CAT_FAIL,
    GET_EXPENSE_CAT,
    GET_EXPENSE_CAT_SUCCESS,
    GET_EXPENSE_CAT_FAIL   
} from "store/expense/actionTypes";

const addExpense = expense => ({
    type: ADD_EXPENSE,
    payload: expense
});

const addExpenseSuccess = expense => ({
    type: ADD_EXPENSE_SUCCESS,
    payload: expense
});

const addExpenseFail = error => ({
    type: ADD_EXPENSE_FAIL,
    payload: error
});

const uploadExpenseImage = expense => ({
    type: UPLOAD_EXPENSE_IMAGES,
    payload: expense
});

const uploadExpenseImageSuccess = expense => ({
    type: UPLOAD_EXPENSE_IMAGES_SUCCESS,
    payload: expense
});

const uploadExpenseImageFail = error => ({
    type: UPLOAD_EXPENSE_IMAGES_FAIL,
    payload: error
});

const addExpenseCat = data => ({
    type: ADD_EXPENSE_CAT,
    payload: data
})

const addExpenseCatSuccess = data => ({
    type: ADD_EXPENSE_CAT_SUCCESS,
    payload: data
})

const addExpenseCatFail = err => ({
    type: ADD_EXPENSE_CAT_FAIL,
    payload: err
})

const getExpenseCat = () => ({
    type: GET_EXPENSE_CAT,
})

const getExpenseCatSuccess = data => ({
    type: GET_EXPENSE_CAT_SUCCESS,
    payload: data
})

const getExpenseCatFail = err => ({
    type: GET_EXPENSE_CAT_FAIL,
    payload: err
})

export {
    addExpense,
    addExpenseSuccess,
    addExpenseFail,
    uploadExpenseImage,
    uploadExpenseImageSuccess,
    uploadExpenseImageFail,
    addExpenseCat,
    addExpenseCatSuccess,
    addExpenseCatFail,
    getExpenseCat,
    getExpenseCatSuccess,
    getExpenseCatFail
};