// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_SIDEBAR_THEME_IMAGE,
  CHANGE_PRELOADER,
  TOGGLE_LEFTMENU,
  SHOW_SIDEBAR,
  CHANGE_LAYOUT_MODE,

  CHANGE_LAYOUT_DIRECTION
} from "./actionTypes";

//constants
import {
  layoutTypes,
  layoutModeTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftBarThemeImageTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,

  layoutDirections
} from "../../constants/layout";

// constants
const keys = {
  settings: "settings",
  layoutType: 'layoutType',
  layoutModeType: 'layoutModeType',
  layoutWidth: 'layoutWidth',
  leftSideBarTheme: 'leftSideBarTheme',
  leftSideBarThemeImage: 'leftSideBarThemeImage',
  leftSideBarType: 'leftSideBarType',
  topbarTheme: 'topbarTheme',
  isPreloader: 'isPreloader',
  showRightSidebar: 'showRightSidebar',
  isMobile: 'isMobile',
  showSidebar: 'showSidebar',
  leftMenu: 'leftMenu',
};

const setLocalStorageItems = (key, value) => {
  const settings = localStorage.getItem(keys.settings);
  let settingsObj = {};
  if (settings) {
    settingsObj = JSON.parse(settings);
  }
  settingsObj[key] = value;
  localStorage.setItem(keys.settings, JSON.stringify(settingsObj));
};

const getStoredValue = (key, initalValue) => {
  const settings = localStorage.getItem(keys.settings);
  let returnedValue = initalValue;
  if (settings) {
    const settingsObj = JSON.parse(settings);
    if (settingsObj[key]) {
      returnedValue = settingsObj[key];
    }
  }

  return returnedValue;
};

const INIT_STATE = {
  layoutType: getStoredValue(keys.layoutType, layoutTypes.HORIZONTAL),
  layoutModeType: getStoredValue(keys.layoutModeType, layoutModeTypes.LIGHT),
  layoutWidth: getStoredValue(keys.layoutWidth, layoutWidthTypes.FLUID),
  leftSideBarTheme: getStoredValue(keys.leftSideBarTheme, leftSideBarThemeTypes.COLORED),
  leftSideBarThemeImage: getStoredValue(keys.leftSideBarThemeImage, leftBarThemeImageTypes.NONE),
  leftSideBarType: getStoredValue(keys.leftSideBarType, leftSidebarTypes.DEFAULT),
  topbarTheme: getStoredValue(keys.topbarTheme, topBarThemeTypes.LIGHT),
  isPreloader: getStoredValue(keys.isPreloader, false),
  showRightSidebar: getStoredValue(keys.showRightSidebar, false),
  isMobile: getStoredValue(keys.isMobile, false),
  showSidebar: getStoredValue(keys.showSidebar, true),
  leftMenu: getStoredValue(keys.leftMenu, false),

  layoutDirections: (localStorage.getItem('dir') !== 'undefined') && (localStorage.getItem('dir') === "ltr" ? layoutDirections.LEFT : layoutDirections.RIGHT)
};

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      setLocalStorageItems(keys.layoutType, action.payload);
      return {
        ...state,
        layoutType: action.payload,
      };
    case CHANGE_PRELOADER:
      setLocalStorageItems(keys.isPreloader, action.payload);
      return {
        ...state,
        isPreloader: action.payload,
      };

    case CHANGE_LAYOUT_MODE:
      setLocalStorageItems(keys.layoutModeType, action.payload);
      return {
        ...state,
        layoutModeType: action.payload,
      };

    case CHANGE_LAYOUT_WIDTH:
      setLocalStorageItems(keys.layoutWidth, action.payload);
      return {
        ...state,
        layoutWidth: action.payload,
      };
    case CHANGE_SIDEBAR_THEME:
      setLocalStorageItems(keys.leftSideBarTheme, action.payload);
      return {
        ...state,
        leftSideBarTheme: action.payload,
      };
    case CHANGE_SIDEBAR_THEME_IMAGE:
      setLocalStorageItems(keys.leftSideBarThemeImage, action.payload);
      return {
        ...state,
        leftSideBarThemeImage: action.payload,
      };
    case CHANGE_SIDEBAR_TYPE:
      setLocalStorageItems(keys.leftSideBarType, action.payload.sidebarType);
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      };
    case CHANGE_TOPBAR_THEME:
      setLocalStorageItems(keys.topbarTheme, action.payload);
      return {
        ...state,
        topbarTheme: action.payload,
      };
    case SHOW_RIGHT_SIDEBAR:
      setLocalStorageItems(keys.showRightSidebar, action.payload);
      return {
        ...state,
        showRightSidebar: action.payload,
      };
    case SHOW_SIDEBAR:
      setLocalStorageItems(keys.showSidebar, action.payload);
      return {
        ...state,
        showSidebar: action.payload,
      };
    case TOGGLE_LEFTMENU:
      setLocalStorageItems(keys.leftMenu, action.payload);
      return {
        ...state,
        leftMenu: action.payload,
      };

    case CHANGE_LAYOUT_DIRECTION:
      return {
        ...state,
        layoutDirections: action.payload,
      };

    default:
      return state;
  }
};

export default Layout;
