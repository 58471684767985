import React from "react"

import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import { Label } from "reactstrap"
import Select from "react-select"
import { selectInputBgColors, layoutModeTypes } from "constants/layout"
import { useSelector, useDispatch } from "react-redux"
import {
  getOneMaterial,
  getStockBalanceFilteredByMaterial,
} from "store/actions"

const MutualSelectInput = ({
  labelValue,
  selectedValue,
  name,
  setSelected,
  list,
}) => {
  const dispatch = useDispatch()
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  const onChangeHandler = e => {
    setSelected(e)
    dispatch(getOneMaterial(Number(e.pkMaterialId)))
    dispatch(
      getStockBalanceFilteredByMaterial(`fk_material_id=${e.pkMaterialId}`)
    )
  }

  return (
    <>
      <Label className="mb-0">
        {labelValue} <span className="text-danger">*</span>
      </Label>
      <div className="input-group">
        <Select
          value={
            selectedValue && selectedValue !== ""
              ? list.find(function (option) {
                  return selectedValue
                    ? option.pkMaterialId === Number(selectedValue.pkMaterialId)
                    : ""
                })
              : ""
          }
          onChange={onChangeHandler}
          options={list}
          getOptionLabel={list => list["materialName"]}
          getOptionValue={list => list["pkMaterialId"]}
          name={name}
          className={`select2-selection form-control border-0 select-material`}
          styles={{
            menu: base => ({
              ...base,
              backgroundColor:
                layoutModeType === layoutModeTypes.LIGHT
                  ? selectInputBgColors.light
                  : selectInputBgColors.dark,
            }),
          }}
        />
      </div>
    </>
  )
}

MutualSelectInput.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(MutualSelectInput))
