import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../../../components/Common/ChartsDynamicColor"
import { dummyCategoriesChart } from "pages/Dashboard/data"
const StackedColumnChart = ({ dataColors, periodData, periodTime }) => {
  const [dataList, setDataList] = useState([])
  const stackedColumnChartColors = getChartColorsArray(dataColors)
  useEffect(() => {
    if(periodData.length){
      let list = [{data: periodData}]
      setDataList(list)
  }
  }, [periodData])
  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%",
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: periodTime,
      labels: {
        show: true,
      },
    },
    colors: stackedColumnChartColors,
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  }
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...dataList]}
        type="bar"
        height="359"
        className="apex-charts"
      />
    </React.Fragment>
  )
}

StackedColumnChart.propTypes = {
  periodData: PropTypes.any,
}
export default StackedColumnChart
