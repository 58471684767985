import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  Button,
} from "reactstrap"
import { useFormik } from "formik"

import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { addExpenseCat } from "store/actions"

const ExpensesCategoryModal = props => {
  const { categoryModal, handleCategoryToggle } = props

  const dispatch = useDispatch()
  const expenseCatLoading = useSelector(state => state.expenses.expenseCatLoading)
  const expenseCatAdded = useSelector(state => state.expenses.expenseCatAdded)
  const [submitDisabled, setSubmitDisabled] = useState(false)

  useEffect(() => {
    if (Object.keys(expenseCatAdded).length > 0) {
      setSubmitDisabled(false)
      validation.resetForm()
      toggle()
    }
  }, [expenseCatAdded])

  const toggle = () => {
    handleCategoryToggle()
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      categoryName: "",
    },
    validationSchema: Yup.object({
      categoryName: Yup.string().required(props.t("pleaseEnterCategoryName")),
    }),
    onSubmit: values => {
      const newCat = {
        paymentCategoryName: values["categoryName"],
      }
      dispatch(addExpenseCat(newCat))
      // toggle()
    },
  })

  return (
    <Modal isOpen={categoryModal} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} tag="h4">
        {props.t("addCategory")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">{props.t("Category Name")}</Label>
                <Input
                  name="categoryName"
                  type="text"
                  value={validation.values.categoryName || ""}
                  invalid={
                    validation.touched.categoryName &&
                    validation.errors.categoryName
                      ? true
                      : false
                  }
                  placeholder={props.t("Enter Category Name")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.categoryName &&
                validation.errors.categoryName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.categoryName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-success save-user"
              disabled={submitDisabled}
            >
              {submitDisabled ? (
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
              ) : (
                <i className="mdi mdi-check-circle-outline me-1" />
              )}
              {props.t("Save")}
            </button>
            <Button
              type="button"
              color="secondary"
              className="mx-1"
              onClick={toggle}
            >
              <i className="mdi mdi-cancel me-1" />
              {props.t("Cancel")}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

ExpensesCategoryModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ExpensesCategoryModal))
