import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
    GET_SHIF_EMPLOYEES,
    GET_SHIF_DETAILS,
    GET_MANAGER_APPROVAL,
    GET_MANAGER_APPROVAL_RESET,
    SET_END_SHIFT_EMP_ID,
    SET_END_SHIFT_EMP_ID_RESET,
    GET_EMAILS,
    SEND_EMAIL,
} from "store/end-shift/actionTypes";
import {
    getShifEmplyeesSuccess,
    getShifEmplyeesFail,
    getShifDetailsSuccess,
    getShifDetailsFail,
    getManagerApprovalSuccess,
    getManagerApprovalFail,
    getManagerApprovalReset,
    setEndShiftEmpIdSuccess,
    setEndShiftEmpIdFail,
    setEndShiftEmpIdReset,
    getEmailsSuccess,
    getEmailsFail,
    sendEmailSuccess,
    sendEmailFail
} from "store/end-shift/actions";
import {
    getShiftDetails,
    getShiftEmployees,
    getManagerApproval,
    setEndShiftEmpId,
    getEmails,
    sendEmail
} from "helpers/backend_helper";

function* onGetShiftEmployees() {
    try {
        const response = yield call(getShiftEmployees);
        console.log(response);
        yield put(getShifEmplyeesSuccess(response));
    } catch (error) {
        yield put(getShifEmplyeesFail(error));
    }
};

function* onGetShiftDetails({ payload: FK_Shift_ID }) {
    try {
        const response = yield call(getShiftDetails, FK_Shift_ID);
        yield put(getShifDetailsSuccess(response));
    } catch (error) {
        yield put(getShifDetailsFail(error));
    }
};

function* onGetManagerApproval({ payload: credentials }) {
    try {
        const response = yield call(getManagerApproval, credentials);
        toast.dismiss();
        if (response[0].Response === "Approved") {
            toast.success(`${response[0].Response}, Don't Close window`);
        } else {
            toast.error(response[0].Response);
        }
        yield put(getManagerApprovalSuccess(response));
    } catch (error) {
        yield put(getManagerApprovalFail(error));
    }
};

function* onGetManagerApprovalReset() {
    put(getManagerApprovalReset);
}

function* onSetEndShiftEmpId({ payload: EndShiftData }) {
    try {
        const response = yield call(setEndShiftEmpId, EndShiftData);
        yield put(setEndShiftEmpIdSuccess(response));
        yield* onGetShiftEmployees();
    } catch (error) {
        yield put(setEndShiftEmpIdFail(error));
    }
};

function* onSetEndShiftEmpIdReset() {
    put(setEndShiftEmpIdReset);
}

function* onGetEmails() {
    try {
        const response = yield call(getEmails);
        yield put(getEmailsSuccess(response));
    } catch (error) {
        yield put(getEmailsFail(error));
    }
};

function* onSendEmail({ payload: bodyData }) {
    try {
        const response = yield call(sendEmail, bodyData);
        toast.dismiss();
        toast.success(response);
        yield put(sendEmailSuccess(response));
    } catch (error) {
        yield put(sendEmailFail(error));
    }
};

function* shiftDetailsSaga() {
    yield takeEvery(GET_SHIF_DETAILS, onGetShiftDetails);
    yield takeEvery(GET_SHIF_EMPLOYEES, onGetShiftEmployees);
    yield takeEvery(GET_MANAGER_APPROVAL, onGetManagerApproval);
    yield takeEvery(GET_MANAGER_APPROVAL_RESET, onGetManagerApprovalReset);
    yield takeEvery(SET_END_SHIFT_EMP_ID, onSetEndShiftEmpId);
    yield takeEvery(SET_END_SHIFT_EMP_ID_RESET, onSetEndShiftEmpIdReset);
    yield takeEvery(GET_EMAILS, onGetEmails);
    yield takeEvery(SEND_EMAIL, onSendEmail);
}

export default shiftDetailsSaga;