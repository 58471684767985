import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import classnames from "classnames"

import { Col, Row, Card, CardBody, NavLink, NavItem, Spinner } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"

import DateFromTo from "pages/Reports/shared/DateFromTo"
import { useDispatch, useSelector } from "react-redux"
import { getItemsSales } from "store/reports/actions"
import { convertDate, convertParametersToString } from "pages/Reports/helpers"
import ItemsSalesList from "./ItemsSalesList"
import ItemsSalesCharts from "./ItemsSalesCharts"
import SendAndPrintPdf from "pages/Reports/shared/pdf/components/SendAndPrintPdf"

const ItemsSalesReport = props => {
  document.title = "Items Sales | Reports"
  const dispatch = useDispatch()
  const [date, setDate] = useState(null)
  const [activeTab, setActiveTab] = useState("list_view")

  const reports = useSelector(state => state.Reports)
  const { getItemsSales: getItemsSalesData, loadingItemsSales: loading } =
    reports

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    if (!date) return
    let params = {}
    if (date) {
      params = { ...date }
    }
    const paramters = convertParametersToString(params)
    dispatch(getItemsSales(paramters))
  }, [date])

  const getDateFromToHandler = date => {
    setDate(date)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Shift")}
            breadcrumbItem={props.t("Items Sales Reports")}
          />
          <Row>
            <Col lg="12">
              <Card>
                <ul className="nav nav-tabs nav-tabs-custom">
                  {[
                    {
                      content: props.t("List View"),
                      activeClass: "list_view",
                    },
                    {
                      content: props.t("Chart View"),
                      activeClass: "chart_view",
                    },
                  ].map((one, i) => (
                    <NavItem key={i}>
                      <NavLink
                        className={classnames({
                          active: activeTab === one.activeClass,
                        })}
                        onClick={() => {
                          toggleTab(one.activeClass)
                        }}
                      >
                        {one.content}
                      </NavLink>
                    </NavItem>
                  ))}
                </ul>
                <CardBody className="border-bottom ml-2">
                  <Row className="row-cols-auto gap-4 align-items-center">
                    <DateFromTo getDateFromTo={getDateFromToHandler} />
                  </Row>
                </CardBody>
                {loading ? (
                  <div className="text-center py-5">
                    <Spinner className="ms-2" color="primary" />
                  </div>
                ) : activeTab === "list_view" ? (
                  getItemsSalesData.length ? (
                    <ItemsSalesList />
                  ) : (
                    <></>
                  )
                ) : getItemsSalesData.length ? (
                  <ItemsSalesCharts />
                ) : (
                  <></>
                )}
                {getItemsSalesData &&
                  (!loading &&
                  activeTab === "list_view" &&
                  getItemsSalesData.length > 0 ? (
                    <div className="mt-2 d-flex justify-content-end">
                      <SendAndPrintPdf
                        data={getItemsSalesData}
                        extraData={{
                          Date_from:
                            date && date.dateFrom
                              ? convertDate(new Date(date.dateFrom))
                              : "First",
                          Date_to:
                            date && date.dateTo
                              ? convertDate(new Date(date.dateTo))
                              : convertDate(new Date()),
                          Items_no: getItemsSalesData.length || 0,
                        }}
                        filteredData={[
                          "No",
                          "ItemName",
                          "CategoryName",
                          "Price",
                          "totalQty",
                          "TotalSales",
                        ]}
                        extraTwoColData={null}
                      />
                    </div>
                  ) : (
                    <></>
                  ))}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

ItemsSalesReport.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ItemsSalesReport))
