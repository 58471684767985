import axios from "axios"

const axiosAuthInstanceImg2 = axios.create({
  baseURL: process.env.REACT_APP_EZDAN,
  Accept: "application/json",
  "Content-Type": "multipart/form-data",
})

axiosAuthInstanceImg2.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  return config
})

export default axiosAuthInstanceImg2
