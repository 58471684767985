import {
  
  GET_STATISTICS_VALUES,
  GET_STATISTICS_VALUES_SUCCESS,
  GET_STATISTICS_VALUES_FAIL,

  GET_DASHBOARD_DININ,
  GET_DASHBOARD_DININ_SUCCESS,
  GET_DASHBOARD_DININ_FAIL,

  GET_REVENUE_INOUT,
  GET_REVENUE_INOUT_SUCCESS,
  GET_REVENUE_INOUT_FAIL,

  GET_TIMELINE,
  GET_TIMELINE_SUCCESS,
  GET_TIMELINE_FAIL
} from "./actionTypes"

const INIT_STATE = {
  revenueDataLoading: false,
  revenueDataList: {},
  revenuDataErr: {},

  dashboardDinInLoading: false,
  dashboardDinInList: {},
  dashboardDinInErr: {},

  statisticsDataListLoading: false,
  statisticsDataList: {},
  statisticsDataErr: {},

  timeLineLoading: false,
  timeLineList: [],
  timeLineErr: {},
}

const dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_REVENUE_INOUT:
      return {
        ...state,
        revenueDataLoading: true,
        revenueDataList: {},
        revenuDataErr: {},
      }

    case GET_REVENUE_INOUT_SUCCESS:
      return {
        ...state,
        revenueDataLoading: false,
        revenueDataList: action.payload,
        revenuDataErr: {},
      }

    case GET_REVENUE_INOUT_FAIL:
      return {
        ...state,
        error: {
          ...state,
          revenueDataLoading: false,
          revenueDataList: {},
          revenuDataErr: action.payload,
        },
      }


    case GET_DASHBOARD_DININ:
      return {
        ...state,
        dashboardDinInLoading: true,
        dashboardDinInList: {},
        dashboardDinInErr: {},
      }

    case GET_DASHBOARD_DININ_SUCCESS:
      return {
        ...state,
        dashboardDinInLoading: false,
        dashboardDinInList: action.payload,
        dashboardDinInErr: {},
      }

    case GET_DASHBOARD_DININ_FAIL:
      return {
        ...state,
        dashboardDinInLoading: false,
        dashboardDinInList: {},
        dashboardDinInErr: action.payload,
      }

    case GET_STATISTICS_VALUES:
      return {
        ...state,
        statisticsDataListLoading: true,
      }

    case GET_STATISTICS_VALUES_SUCCESS:
      return {
        ...state,
        statisticsDataListLoading: false,
        statisticsDataList: action.payload.UpperData,
      }
    case GET_STATISTICS_VALUES_FAIL:
      return {
        ...state,
        statisticsDataListLoading: false,
        statisticsDataErr: action.payload,
      }

      case GET_TIMELINE:
        return {
          ...state,
          timeLineList: [],
          timeLineLoading: true,
        }
  
      case GET_TIMELINE_SUCCESS:
        return {
          ...state,
          timeLineLoading: false,
          timeLineList: action.payload,
        }
      case GET_TIMELINE_FAIL:
        return {
          ...state,
          timeLineLoading: false,
          timeLineList: [],
          timeLineErr: action.payload,
        }

    default:
      return state
  }
}

export default dashboard
