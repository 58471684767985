import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
    GET_TBL_SUPPLIER_PAYMENT,
    GET_TBL_SUPPLIER,
    ADD_NEW_SUPPLIER
} from "store/supplier/actionTypes";
import {
    tblSupplierPaymentSuccess,
    tblSupplierPaymentFail,
    tblSupplierSuccess,
    tblSupplierFail,
    addNewSupplierSuccess,
    addNewSupplierFail,

} from "store/supplier/actions";
import { getTblSuppliers, getTblSupplierPayment, addNewSupplier } from "helpers/backend_helper";
import i18n from "i18n";
function* onGetTblSupplierPayment() {
    try {
        const response = yield call(getTblSupplierPayment);
        yield put(tblSupplierPaymentSuccess(response.rs.data));
    } catch (error) {
        yield put(tblSupplierPaymentFail(error));
    }
}

function* onGetTblSuppliers() {
    try {
        const response = yield call(getTblSuppliers);
        yield put(tblSupplierSuccess(response.rs.data));
    } catch (error) {
        yield put(tblSupplierFail(error));
    }
}

function* onAddNewSupplier({ payload: bodyData }) {
    try {
        const response = yield call(addNewSupplier, bodyData);
        toast.dismiss();
        toast.success(i18n.t("Supplier added successfully"));
        yield put(addNewSupplierSuccess(response));
    } catch (error) {
        toast.dismiss();
        toast.error(i18n.t("Problem occured. Please, try again"));
        yield put(addNewSupplierFail(error));
    }
};

function* supplierSage() {
    yield takeEvery(GET_TBL_SUPPLIER, onGetTblSuppliers);
    yield takeEvery(GET_TBL_SUPPLIER_PAYMENT, onGetTblSupplierPayment);
    yield takeEvery(ADD_NEW_SUPPLIER, onAddNewSupplier);
}

export default supplierSage;