import {
  GET_MATERIALS_SUCCESS,
  GET_MATERIALS_FAIL,
  POST_MATERIAL_SUCCESS,
  POST_MATERIAL_FAIL,
  POST_MATERIAL,
  RESET_POST_MATERIAL,
  GET_ONE_MATERIAL,
  GET_ONE_MATERIAL_SUCCESS,
  GET_ONE_MATERIAL_FAIL,
  GET_ONE_COMPONENT,
  GET_ONE_COMPONENT_SUCCESS,
  GET_ONE_COMPONENT_FAIL,
  UPDATE_MATERIAL_COMPONENT,
  UPDATE_MATERIAL_COMPONENT_SUCCESS,
  UPDATE_MATERIAL_COMPONENT_FAIL
} from "./actionTypes"

const INIT_STATE = {
  allMaterials: [],
  newMaterialAdded: false,
  // userProfile: {},
  error: {},
  oneMaterial: [],
  loadingOneMaterial: false,
  oneComponent: [],
  loadingOneComponent: false,
  updateMaterialComponent: false,
  newMaterialValue: [],
}

const materials = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MATERIALS_SUCCESS:
      return {
        ...state,
        allMaterials: action.payload,
      }

    case GET_MATERIALS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case POST_MATERIAL:
      return {
        ...state,
        newMaterialAdded: false,
        newMaterialValue: [],

      }

    case POST_MATERIAL_SUCCESS:
      return {
        ...state,
        allMaterials: [...state.allMaterials, action.payload],
        newMaterialAdded: true,
        newMaterialValue: action.payload,
      }

    case RESET_POST_MATERIAL:
      return {
        ...state,
        newMaterialAdded: false,
      }

    case POST_MATERIAL_FAIL:
      return {
        ...state,
        error: { ...state, error: action.payload },
        newMaterialValue: [],
      }

    case GET_ONE_MATERIAL:
      return {
        ...state,
        loadingOneMaterial: true,
      }

    case GET_ONE_MATERIAL_SUCCESS:
      return {
        ...state,
        loadingOneMaterial: false,
        oneMaterial: action.payload,
      }

    case GET_ONE_MATERIAL_FAIL:
      return {
        ...state,
        loadingOneMaterial: false,
        error: { ...state, error: action.payload },
      }

    case GET_ONE_COMPONENT:
      return {
        ...state,
        loadingOneComponent: true,
      }

    case GET_ONE_COMPONENT_SUCCESS:
      return {
        ...state,
        loadingOneComponent: false,
        oneComponent: action.payload,
      }

    case GET_ONE_COMPONENT_FAIL:
      return {
        ...state,
        loadingOneComponent: false,
        error: { ...state, error: action.payload },
      }

    case UPDATE_MATERIAL_COMPONENT:
      return {
        ...state,
        updateMaterialComponent: false
      }

    case UPDATE_MATERIAL_COMPONENT_SUCCESS:
      return {
        ...state,
        updateMaterialComponent: true
      }

    case UPDATE_MATERIAL_COMPONENT_FAIL:
      return {
        ...state,
        updateMaterialComponent: false,
        error: { ...state, error: action.payload }
      }

    default:
      return state
  }
}

export default materials
