import React, { useMemo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Row,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
import TableContainer from "components/Common/TableContainer"
import { useSelector } from "react-redux"

const TransferDetailsModal = props => {
  const { isOpen, toggle } = props

  const [transferDetails, setTransferDetails] = useState([])
  const stockTransfer = useSelector(state => state.stockTransfer)
  const {
    stockTransferDetails: stockTransferDetails,
    stockTransferDetailsLoading: loading,
  } = stockTransfer

  useEffect(() => {
    setTransferDetails(stockTransferDetails)
  }, [stockTransferDetails])

  const columns = useMemo(
    () => [
      {
        Header: props.t("Material Name"),
        accessor: "MaterialName",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("Unit"),
        accessor: "Unit",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("storeFrom"),
        accessor: "FromStore",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("storeTo"),
        accessor: "ToStore",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },

      {
        Header: props.t("Qty."),
        accessor: "TransferedQty",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      //   {
      //     Header: props.t("Employee"),
      //     accessor: "EmpFromName",
      //     filterable: true,
      //     Cell: cellProps => {
      //       return <>{cellProps.value || ""}</>
      //     },
      //   },
      //   {
      //     Header: props.t("Notes"),
      //     accessor: "Notes",
      //     filterable: true,
      //     Cell: cellProps => {
      //       return <>{cellProps.value || 0}</>
      //     },
      //   },
    ],
    [props.t]
  )

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      className="exampleModal modal-xl"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>
          {transferDetails.length
            ? props.t(
                `ID #${transferDetails[0].FK_StockTransfere_ID || ""} ${props.t(
                  "transfer details"
                )}`
              )
            : `${props.t("transfer details")}`}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="text-center py-5">
                      <Spinner className="ms-2" color="primary" />
                    </div>
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={transferDetails}
                      isAddOptions={false}
                      customPageSize={5}
                      allListSize={
                        transferDetails ? transferDetails.length : []
                      }
                      customPageSizeOptions={true}
                    />
                  )}
                </CardBody>
                {/* {safeBalanceList &&
                  (safeBalanceList.length && !loading ? (
                    <div className="mt-2 d-flex justify-content-end">
                      <SendAndPrintPdf
                        data={safeBalanceList}
                        extraData={null}
                        filteredData={["No", "SafeName", "Balance"]}
                        extraTwoColData={null}
                      />
                    </div>
                  ) : (
                    <></>
                  ))} */}
              </Card>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="success" onClick={toggle}>
            <i className="bx bx-check-circle label-icon"></i> {props.t("Close")}
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

TransferDetailsModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(TransferDetailsModal))
