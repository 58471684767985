import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";

import loginSaga from "./login/saga";

import itemsSaga from "./products/item/saga";
import itemIngrediantSaga from "./products/ingrediants/saga";
import itemNotesSaga from "./products/itemNotes/saga";
import materialSaga from "./products/materials/saga";

import reportsSaga from "./reports/saga";
import expensesSaga from "store/expense/saga";
import shiftDetailsSaga from "store/end-shift/saga";
import tabelSafeSage from "store/tabel-safe/saga";
import endShiftReportSaga from "store/end-shift-report/saga";

import prepareAreaSaga from "./products/prepareArea/saga";
import supplierSage from "store/supplier/saga";
import safeTransferSaga from "./safe-transfer/saga";
import purchaseSaga from "./purchase/saga";

import componentSaga from "./products/components/saga";

import categoriesSaga from "./products/category/saga";
import businessSettingsSaga from "./business-settings/saga";

import unitsSaga from "./products/unit/saga";

import componentManufactureSaga from "./component/saga";

import stockTransferSaga from "./stock-transfer/saga";

import dashboardSaga from "./dashboard/saga";

import privillageSaga from "./privillage/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),

    fork(loginSaga),
    fork(reportsSaga),
    fork(itemsSaga),
    fork(itemNotesSaga),
    fork(itemIngrediantSaga),
    fork(expensesSaga),
    fork(shiftDetailsSaga),
    fork(tabelSafeSage),
    fork(materialSaga),
    fork(prepareAreaSaga),
    fork(unitsSaga),

    fork(componentSaga),
    fork(categoriesSaga),
    fork(supplierSage),
    fork(endShiftReportSaga),
    fork(safeTransferSaga),
    fork(purchaseSaga),
    fork(businessSettingsSaga),
    fork(componentManufactureSaga),

    fork(stockTransferSaga),
    fork(dashboardSaga),

    fork(privillageSaga)
  ]);
}
