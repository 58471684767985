import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"
import {
  GET_STOCKTRANSFER,
  GET_STOCKTRANSFER_CHECKSTOREQTY,
  GET_STOCKTRANSFER_ID,
  POST_STOCKTRANSFER,
} from "./actionTypes"
import {
  postStockTransferSuccess,
  postStockTransferFail,
  getStockTransferCheckStoreQtySuccess,
  getStockTransferCheckStoreQtyFail,
  getStockTransferSuccess,
  getStockTransferFail,
  getStockTransferByIdSuccess,
  getStockTransferByIdFail,
} from "./actions"

import {
  postStockTransferData,
  getStockTransferCheckStoreQty,
  getStockTransfer,
  getStockTransferById,
} from "helpers/backend_helper"
import i18n from "i18n"

function* onGetStockTransferCheckStoreQty({ payload: params }) {
  try {
    const response = yield call(getStockTransferCheckStoreQty, params)
    yield put(getStockTransferCheckStoreQtySuccess(response))
  } catch (error) {
    yield put(getStockTransferCheckStoreQtyFail(error))
  }
}

function* onPostStockTransfer({ payload: data }) {
  try {
    const response = yield call(postStockTransferData, data)
    toast.dismiss()
    toast.success(i18n.t("ItemstransferedSuccessfully"))
    yield put(postStockTransferSuccess(response))
  } catch (error) {
    toast.dismiss()
    toast.error(i18n.t("Problem occured. Please, try again"))
    yield put(postStockTransferFail(error))
  }
}

// Saga for getting all stock transfers
function* onGetStockTransfer() {
  try {
    const response = yield call(getStockTransfer)
    yield put(getStockTransferSuccess(response))
  } catch (error) {
    yield put(getStockTransferFail(error.message))
  }
}

function* onGetStockTransferById({ payload: params }) {
  try {
    const response = yield call(getStockTransferById, params)
    yield put(getStockTransferByIdSuccess(response))
  } catch (error) {
    yield put(getStockTransferByIdFail(error.message))
  }
}

function* stockTransferSaga() {
  yield takeEvery(GET_STOCKTRANSFER, onGetStockTransfer)
  yield takeEvery(GET_STOCKTRANSFER_ID, onGetStockTransferById)
  yield takeEvery(
    GET_STOCKTRANSFER_CHECKSTOREQTY,
    onGetStockTransferCheckStoreQty
  )
  yield takeEvery(POST_STOCKTRANSFER, onPostStockTransfer)
}

export default stockTransferSaga
