import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import TableContainer from "components/Common/TableContainer"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Spinner,
  Card,
  CardBody,
} from "reactstrap"

import { useSelector, useDispatch } from "react-redux"
import DateFromTo from "pages/Reports/shared/DateFromTo"
import FilterComp from "pages/Reports/SafeBalance/Filter/FilterComp"

const CompDetailsModal = props => {
  const { handleCompDetailsToggle, compDetailsShowModal, compDetails } = props
  const oneSafeData = useSelector(state => state.Reports.oneSafeData)
  const loadingOneSafeData = useSelector(
    state => state.Reports.loadingOneSafeData
  )
  const [modifiedData, setModifiedData] = useState([])

  useEffect(() => {
    if (!oneSafeData) return
    setModifiedData(oneSafeData)
  }, [oneSafeData])

  const toggle = () => {
    handleCompDetailsToggle()
  }
  const handleCancel = () => {
    toggle()
  }

  const columnsData = useMemo(
    () => [
      {
        Header: props.t("Order Id"),
        accessor: "PK_OrderMaster_ID",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("EmpName"),
        accessor: "EmpName",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      // {
      //   Header: props.t("OrderType"),
      //   accessor: "OrderType",
      //   filterable: true,
      //   Cell: cellProps => {
      //     return <>{cellProps.value || 0}</>
      //   },
      // },
      {
        Header: props.t("Time"),
        accessor: "OrderTime",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
      {
        Header: props.t("Date"),
        accessor: "OrderDate",
        filterable: true,
        Cell: cellProps => {
          const date = new Date(cellProps.value)
          const year = date.getFullYear()
          const month =
            date.getMonth() + 1 > 9
              ? date.getMonth() + 1
              : `0${date.getMonth() + 1}`
          const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
          return <>{cellProps.value ? year + "-" + month + "-" + day : ""}</>
        },
      },

      {
        Header: props.t("Total Price"),
        accessor: "TotalPrice",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },

      {
        Header: `${props.t("Discount")} '%'`,
        accessor: "Discount",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
      {
        Header: props.t("Paid"),
        accessor: "CompOrderValue",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
    ],
    [props.t, modifiedData]
  )

  const bearerFilterHandler = filter => {
    let filteredData = oneSafeData
    let filterByDate = true
    const { dateFrom, dateTo, idFrom, idTo } = filter

    if (!oneSafeData.length) return

    // check if date is valid
    if (dateFrom === "" || dateTo === "") filterByDate = false

    if (idFrom || idTo) {
      filteredData = filteredData.filter(item => {
        const id = item.PK_OrderMaster_ID
        if (idFrom && !idTo) {
          return id >= idFrom
        } else {
          return id >= idFrom && id <= idTo
        }
      })
    }

    if (filterByDate) {
      const fromDate = new Date(dateFrom)
      const toDate = new Date(dateTo)
      filteredData = filteredData.filter(item => {
        const orderDate = new Date(item.OrderDate)
        return orderDate >= fromDate && orderDate <= toDate
      })
    }

    setModifiedData(filteredData)
  }
  return (
    <Modal isOpen={compDetailsShowModal} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle} tag="h4">
        {`${compDetails.SafeName} ${props.t("Details")}`}
      </ModalHeader>
      <ModalBody>
        <FilterComp bearerFilterHandler={bearerFilterHandler} />
        <Row>
          <Col>
            <Card>
              <CardBody>
                {loadingOneSafeData ? (
                  <div className="text-center py-5">
                    <Spinner className="ms-2" color="primary" />
                  </div>
                ) : (
                  <TableContainer
                    columns={columnsData}
                    data={modifiedData ? modifiedData : []}
                    isAddOptions={false}
                    customPageSize={5}
                    allListSize={modifiedData ? modifiedData.length : []}
                    customPageSizeOptions={true}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-end">
              <Button
                type="button"
                color="secondary"
                className="mx-1"
                onClick={handleCancel}
              >
                <i className="mdi mdi-arrow-left me-1" />
                {props.t("Back")}
              </Button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

CompDetailsModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(CompDetailsModal))
