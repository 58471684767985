const SAVE_BUY_MATERIAL = "SAVE_BUY_MATERIAL";
const SAVE_BUY_MATERIAL_SUCCESS = "SAVE_BUY_MATERIAL_SUCCESS";
const SAVE_BUY_MATERIAL_FAIL = "SAVE_BUY_MATERIAL_FAIL";



export {
    SAVE_BUY_MATERIAL,
    SAVE_BUY_MATERIAL_SUCCESS,
    SAVE_BUY_MATERIAL_FAIL
};