import React, { useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import TableContainer from "../../../components/Common/TableContainer"

import { Col, Row, Card, CardBody, Spinner } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"

import { useDispatch, useSelector } from "react-redux"
import { getSupplierPayments } from "store/reports/actions"
import DateFromTo from "pages/Reports/shared/DateFromTo"
import { convertParametersToString, convertDate } from "pages/Reports/helpers"
import SendAndPrintPdf from "pages/Reports/shared/pdf/components/SendAndPrintPdf"

const SupplierPayment = props => {
  document.title = "Reports | Supplier Payment"
  const dispatch = useDispatch()
  const [date, setDate] = useState(null)

  useEffect(() => {
    if (!date) return
    const paramters = convertParametersToString(date)
    dispatch(getSupplierPayments(paramters))
  }, [date])

  const reports = useSelector(state => state.Reports)
  const {
    getSupplierPayments: supplierPayment,
    loadingSupplierPayments: loading,
  } = reports

  const getDateFromToHandler = date => {
    setDate(date)
  }

  const columns = useMemo(
    () => [
      {
        Header: props.t("Supplier"),
        accessor: "SupplierName",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("Notes"),
        accessor: "Notes",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("Value"),
        accessor: "Value",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
      {
        Header: props.t("Bill No."),
        accessor: "BillNo",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("Bill Date"),
        accessor: "Invoicedate",
        filterable: true,
        Cell: cellProps => {
          const date = new Date(cellProps.value)
          const year = date.getFullYear()
          const month = date.getMonth() + 1
          const day = date.getDate()

          return <>{cellProps.value ? day + "/" + month + "/" + year : ""}</>
        },
      },
      {
        Header: props.t("Employee"),
        accessor: "EmpName",
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("Record Date"),
        accessor: "Date",
        filterable: true,
        Cell: cellProps => {
          const date = new Date(cellProps.value)
          const year = date.getFullYear()
          const month = date.getMonth() + 1
          const day = date.getDate()

          return <>{cellProps.value ? day + "/" + month + "/" + year : ""}</>
        },
      },
    ],
    [props.t]
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Shift")}
            breadcrumbItem={props.t("Supplier Payment")}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom ml-2">
                  <DateFromTo getDateFromTo={getDateFromToHandler} />
                </CardBody>
                {loading ? (
                  <div className="text-center py-5">
                    <Spinner className="ms-2" color="primary" />
                  </div>
                ) : (
                  <CardBody>
                    {supplierPayment.length ? (
                      <TableContainer
                        columns={columns}
                        data={supplierPayment ? supplierPayment : []}
                        customPageSize={5}
                        allListSize={
                          supplierPayment ? supplierPayment.length : 0
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </CardBody>
                )}
                {supplierPayment &&
                  (!loading && supplierPayment.length > 0 ? (
                    <div className="mt-2 d-flex justify-content-end">
                      <SendAndPrintPdf
                        data={supplierPayment}
                        extraData={{
                          Date_From:
                            date && date.dateFrom
                              ? convertDate(date.dateFrom)
                              : "First",
                          Date_To:
                            date && date.dateTo
                              ? convertDate(date.dateTo)
                              : convertDate(new Date()),
                          Items_No: supplierPayment.length || 0,
                        }}
                        filteredData={[
                          "No",
                          "SupplierName",
                          "EmpName",
                          "BillNo",
                          "Value",
                          "Notes",
                          "Date",
                        ]}
                        extraTwoColData={null}
                      />
                    </div>
                  ) : (
                    <></>
                  ))}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

SupplierPayment.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SupplierPayment))
