import React, { useEffect } from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "components/Common/ChartsDynamicColor"

const SpineArea = ({ dataColors, data, periodData, periodType }) => {
  useEffect(() => {
    console.log(periodData)
  }, [periodData]) // eslint-disable-line
  
  const spineareaChartColors = getChartColorsArray(dataColors)
  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    colors: spineareaChartColors,
    xaxis: {
      type: "date",
      // type: "datetime",
      categories: periodData.xaxisData ? periodData.xaxisData : []
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: periodType === "monthly" ? "MM/yy" : periodType === "today" ? "yyyy-MM-dd" : "yyyy-MM-dd",
        // format: "dd/MM/yy HH:mm",
      },
    },
  }

  return (
    <ReactApexChart options={options} series={data} type="area" height="350" />
  )
}

export default SpineArea
