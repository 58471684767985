/* Material */
export const GET_MATERIALS = "GET_MATERIALS";
export const GET_MATERIALS_SUCCESS = "GET_MATERIALS_SUCCESS";
export const GET_MATERIALS_FAIL = "GET_MATERIALS_FAIL";

export const POST_MATERIAL = "POST_MATERIAL";
export const POST_MATERIAL_SUCCESS = "POST_MATERIAL_SUCCESS";
export const POST_MATERIAL_FAIL = "POST_MATERIAL_FAIL";

export const RESET_POST_MATERIAL = "RESET_ADD_MATERIAL";

export const GET_ONE_MATERIAL = "GET_ONE_MATERIAL";
export const GET_ONE_MATERIAL_SUCCESS = "GET_ONE_MATERIAL_SUCCESS";
export const GET_ONE_MATERIAL_FAIL = "GET_ONE_MATERIAL_FAIL";

export const GET_ONE_COMPONENT = "GET_ONE_COMPONENT";
export const GET_ONE_COMPONENT_SUCCESS = "GET_ONE_COMPONENT_SUCCESS";
export const GET_ONE_COMPONENT_FAIL = "GET_ONE_COMPONENT_FAIL"

export const UPDATE_MATERIAL_COMPONENT = "UPDATE_MATERIAL_COMPONENT"
export const UPDATE_MATERIAL_COMPONENT_SUCCESS = "UPDATE_MATERIAL_COMPONENT_SUCCESS"
export const UPDATE_MATERIAL_COMPONENT_FAIL = "UPDATE_MATERIAL_COMPONENT_FAIL"