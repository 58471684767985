import { jsPDF } from "jspdf"
// import logo from "../../../../../assets/images/buffalo-logo.png";

const preparePdfHeader = (fileName, extraData, logo) => {
  const doc = new jsPDF()

  const docWidth = doc.internal.pageSize.getWidth();

  // Add SVG as an image before the header
  const svg = {
    img: logo,
    format: "SVG",
    x: docWidth-25,
    y: 5,
    width: 16,
    height: 12,
  }

  doc.setFillColor(250, 147, 14)
  doc.rect(0, 0, docWidth, 20,'F');
  // Add the SVG image before the header
  doc.addImage(svg.img, svg.format, svg.x, svg.y, svg.width, svg.height)

  function capitalizeHeaderWord(str) {
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase()
    })
  }


  // Add a header to the PDF
  doc.setFontSize(18)
  doc.setTextColor(255, 255, 255)
  doc.setFont("helvetica", "bold")
  doc.text(capitalizeHeaderWord(fileName), 15, 13)
  

  // doc.line(0, 20, docWidth, 20);
  // reset font
  doc.setFontSize(10)
  doc.setTextColor(73, 80, 87)

  let startX = 15
  let startY = 28
  let margin = 6

  // exta details
  if (extraData) {
    Object.entries(extraData).forEach(one => {
      doc.text(`${one[0].replace(/_/g, " ")}: ${one[1]}`, startX, startY)
      startY += margin // Increase Y coordinate for the next text with a margin
    })
  }

  // reset font
  doc.setFont("helvetica", "normal") // Set font to bold
  doc.setTextColor(0, 0, 0)

  return { doc, margin: 5 + startY }
}

export { preparePdfHeader }
