const POST_STOCKTRANSFER = "POST_STOCKTRANSFER"
const POST_STOCKTRANSFER_SUCCESS = "POST_STOCKTRANSFER_SUCCESS"
const POST_STOCKTRANSFER_FAIL = "POST_STOCKTRANSFER_FAIL"

const GET_STOCKTRANSFER_CHECKSTOREQTY = "GET_STOCKTRANSFER_CHECKSTOREQTY"
const GET_STOCKTRANSFER_CHECKSTOREQTY_SUCCESS = "GET_STOCKTRANSFER_CHECKSTOREQTY_SUCCESS"
const GET_STOCKTRANSFER_CHECKSTOREQTY_FAIL = "GET_STOCKTRANSFER_CHECKSTOREQTY_FAIL"

const GET_STOCKTRANSFER = "GET_STOCKTRANSFER"
const GET_STOCKTRANSFER_SUCCESS = "GET_STOCKTRANSFER_SUCCESS"
const GET_STOCKTRANSFER_FAIL = "GET_STOCKTRANSFER_FAIL"

const GET_STOCKTRANSFER_ID = "GET_STOCKTRANSFER_ID"
const GET_STOCKTRANSFER_ID_SUCCESS = "GET_STOCKTRANSFER_ID_SUCCESS"
const GET_STOCKTRANSFER_ID_FAIL = "GET_STOCKTRANSFER_ID_FAIL"

export {
    POST_STOCKTRANSFER,
    POST_STOCKTRANSFER_SUCCESS,
    POST_STOCKTRANSFER_FAIL,
    
    GET_STOCKTRANSFER_CHECKSTOREQTY,
    GET_STOCKTRANSFER_CHECKSTOREQTY_SUCCESS,
    GET_STOCKTRANSFER_CHECKSTOREQTY_FAIL,
    
    GET_STOCKTRANSFER,
    GET_STOCKTRANSFER_SUCCESS,
    GET_STOCKTRANSFER_FAIL,
    
    GET_STOCKTRANSFER_ID,
    GET_STOCKTRANSFER_ID_SUCCESS,
    GET_STOCKTRANSFER_ID_FAIL
}