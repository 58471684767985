import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ITEM_NOTES } from "./actionTypes"

import { getItemNotesSuccess, getItemNotesFail } from "./actions"

import { getItemNotes } from "../../../helpers/backend_helper"

function* fetchItemNotes(item) {
  try {
    const response = yield call(getItemNotes, item.itemNote)
    yield put(getItemNotesSuccess(response.rs.data))
  } catch (err) {
    yield put(getItemNotesFail(err))
  }
}

function* itemNotesSaga() {
  yield takeEvery(GET_ITEM_NOTES, fetchItemNotes)
}

export default itemNotesSaga