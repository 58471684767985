// export async function get(url, config = {}) {
//     return await axiosApi
//       .get(url, { ...config })
//       .then((response) => response.data);
//   }
import { toast } from "react-toastify"

import axiosAuthInstance from "../axios/axiosAuthInstance"
import axiosAuthInstance2 from "../axios/axiosAuthInstance2"

import axiosAuthInstanceImg from "../axios/axiosAuthInstanceImg"
import axiosAuthInstanceImg2 from "../axios/axiosAuthInstanceImg2"

const unAuthorizedHandler = (err, url) => {
  toast.dismiss()
  toast.error(err.message || "Something went wrong")
  if (err.response.status === 401) {
    localStorage.removeItem("token")
    localStorage.removeItem("authUser")
    localStorage.removeItem("branchName")
    window.location.href = "/login"
  }
}

export async function get(url, config = {}) {
  if (localStorage.getItem("branchName") === "moq") {
    return await axiosAuthInstance
      .get(url, { ...config })
      .then(response => response.data)
      .catch(err => unAuthorizedHandler(err, url))
  } else if (localStorage.getItem("branchName") === "ezdan") {
    return await axiosAuthInstance2
      .get(url, { ...config })
      .then(response => response.data)
      .catch(err => unAuthorizedHandler(err, url))
  } else {
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    localStorage.removeItem("shiftID");
    localStorage.removeItem("branchName");
    window.location.href = "/login"
  }
}

export async function post(url, data, config = {}) {
  if (localStorage.getItem("branchName") === "moq") {
    return await axiosAuthInstance
      .post(url, data, config)
      .then(response => response.data)
      .catch(err => unAuthorizedHandler(err, url))
  } else if (localStorage.getItem("branchName") === "ezdan") {
    return await axiosAuthInstance2
      .post(url, data, config)
      .then(response => response.data)
      .catch(err => unAuthorizedHandler(err, url))
  } else {
    localStorage.removeItem("authUser")
    localStorage.removeItem("token")
    localStorage.removeItem("shiftID")
    localStorage.removeItem("branchName")
    window.location.href = "/login"
  }
}

export async function postImg(url, data) {
  try {
    if(localStorage.getItem("branchName") === "moq") {

        const res = await axiosAuthInstanceImg.post(url, data)
        return res
    } else if (localStorage.getItem("branchName") === "ezdan") {
        const res = await axiosAuthInstanceImg2.post(url, data)
        return res
    }
  } catch (e) {
    console.log(e)
    unAuthorizedHandler(e)
  }
  // return await axiosAuthInstanceImg.post(url, data)
  //     .then((response) => response.data)
  //     .catch(err => unAuthorizedHandler(err));
}
