import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_STATISTICS_VALUES,
  GET_REVENUE_INOUT,
  GET_DASHBOARD_DININ,
  GET_TIMELINE,
} from "./actionTypes"

import {
  getRevenueInOutSuccess,
  getRevenueInOutFail,
  getStatisticsSuccess,
  getStatisticsFail,
  getDashboardDineInSuccess,
  getDashboardDineInFail,
  getTimelineSuccess,
  getTimelineFail,
} from "./actions"

import {
  getTotalStatisticsValue,
  getDashboardInOutCome,
  getDashboardDineinValues,
  getTimeline,
} from "../../helpers/backend_helper"

import { toast } from "react-toastify"
import i18n from "i18n"

function* onGetRevenueInOut({ payload: bodyData }) {
  try {
    // const response = {
    //   incomeYaxis: [1, 2, 3, 4],
    //   outComeYaxis: [5, 6, 7, 8],
    //   xaxisData: [
    //     "2022-01-19T00:00:00",
    //     "2022-02-19T03:30:00",
    //     "2022-03-19T06:30:00",
    //     "2022-04-19T07:30:00",
    //   ],
    // }
    const response = yield call(getDashboardInOutCome, bodyData)
    yield put(getRevenueInOutSuccess(response))
  } catch (err) {
    yield put(getRevenueInOutFail(err))
  }
}

function* onGetDashboardDineIn() {
  try {
    const response = yield call(getDashboardDineinValues)
    yield put(getDashboardDineInSuccess(response.DinInData))
  } catch (err) {
    yield put(getDashboardDineInFail(err))
  }
}

function* onGetStatisticsValues() {
  try {
    const response = yield call(getTotalStatisticsValue)
    yield put(getStatisticsSuccess(response))
  } catch (err) {
    yield put(getStatisticsFail(err))
  }
}

function* onGetTimeline({ payload: bodyData }) {
  try {
    const response = yield call(getTimeline, bodyData)
    yield put(getTimelineSuccess(response))
  } catch (err) {
    yield put(getTimelineFail(err))
  }
}

function* dashboardSaga() {
  yield takeEvery(GET_REVENUE_INOUT, onGetRevenueInOut)
  yield takeEvery(GET_DASHBOARD_DININ, onGetDashboardDineIn)
  // yield takeEvery(GET_REVENUE_DAILY, onGetRevenueDaily)
  yield takeEvery(GET_STATISTICS_VALUES, onGetStatisticsValues)
  yield takeEvery(GET_TIMELINE, onGetTimeline)
}

export default dashboardSaga
