import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import TableContainer from "../../../components/Common/TableContainer"

import { Col, Row, Card, CardBody, Spinner } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"

import DailyDate from "pages/Reports/shared/DailyDate"
import { useSelector, useDispatch } from "react-redux"
import { getDayTrans } from "store/actions"
import SendAndPrintPdf from "pages/Reports/shared/pdf/components/SendAndPrintPdf"

const DailyReport = props => {
  document.title = "Daily Report | Reports"
  const dispatch = useDispatch()

  const [date, setDate] = useState(null)

  const reports = useSelector(state => state.Reports)
  const { getDayTrans: DayTransData, loadingDayTrans: loading } = reports

  useEffect(() => {
    console.log(DayTransData)
  }, [DayTransData])

  const getDataFilteredByDateHandler = (paramsInString, date) => {
    setDate(date)
    dispatch(getDayTrans(paramsInString))
  }

  const columns = useMemo(
    () => [
      {
        Header: props.t("Sales"),
        accessor: "Sells",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
      {
        Header: props.t("Date"),
        accessor: "DayDate",
        filterable: true,
        Cell: cellProps => {
          const date = new Date(cellProps.value)
          const year = date.getFullYear()
          const month = date.getMonth() + 1
          const day = date.getDate()

          return <>{cellProps.value ? day + "/" + month + "/" + year : ""}</>
        },
      },
      {
        Header: props.t("Deposit"),
        accessor: "BankDeposit",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
      {
        Header: props.t("Purchase Invoices"),
        accessor: "MainSafeBuyBill",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
      {
        Header: props.t("Expanses Safe"),
        accessor: "MainSafeSpendings",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
      {
        Header: props.t("Withdrawal"),
        accessor: "BankTake",
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
      {
        Header: props.t("Spending"),
        accessor: "Spendings",
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
    ],
    [props.t]
  )

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={props.t("Shift")}
          breadcrumbItem={props.t("Daily Report")}
        />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody className="border-bottom ml-2">
                <Row>
                  <DailyDate
                    getDataFilteredByDate={getDataFilteredByDateHandler}
                  />
                </Row>
              </CardBody>
              <CardBody>
                {loading ? (
                  <div className="text-center py-5">
                    <Spinner className="ms-2" color="primary" />
                  </div>
                ) : (
                  <TableContainer
                    columns={columns}
                    data={DayTransData ? DayTransData : []}
                    isAddOptions={false}
                    customPageSize={5}
                    allListSize={DayTransData ? DayTransData.length : 0}
                  />
                )}
              </CardBody>
              {DayTransData &&
                (!loading && DayTransData.length > 0 ? (
                  <div className="mt-2 d-flex justify-content-end">
                    <SendAndPrintPdf
                      data={DayTransData}
                      extraData={{
                        Date: date ? date : "First",
                        Items_no: DayTransData.length || 0,
                      }}
                      filteredData={[
                        "No",
                        "Sells",
                        "DayDate",
                        "BankDeposit",
                        "MainSafeBuyBill",
                        "MainSafeSpendings",
                        "BankTake",
                        "Spendings",
                      ]}
                      extraTwoColData={null}
                    />
                  </div>
                ) : (
                  <></>
                ))}
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

DailyReport.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(DailyReport))
