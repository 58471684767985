import { prepareTabelData, attachPdfTabelDataToDoc } from "./pdfHlpers";

const preparePdfTabel = (doc, data, filteredData, margin) => {
    const { TabelData } = prepareTabelData(data, filteredData);
    const { doc: returnedDoc } = attachPdfTabelDataToDoc(doc, TabelData, margin);
    doc = returnedDoc;

    return doc;
};

export {
    preparePdfTabel
};
