import { call, put, takeEvery } from "redux-saga/effects";
import { GET_TABEL_SAFE } from "store/tabel-safe/actionTypes";
import {
    getTabelSafeSuccess,
    getTabelSafeFail
} from "./actions";
import { getTabelSafe } from "helpers/backend_helper";

function* onGetTabelSafe() {
    try {
        const response = yield call(getTabelSafe);
        yield put(getTabelSafeSuccess(response.rs.data));
    } catch (error) {
        yield put(getTabelSafeFail(error));
    }
}

function* tabelSafeSage() {
    yield takeEvery(GET_TABEL_SAFE, onGetTabelSafe);
}

export default tabelSafeSage;