import React, { useEffect, useState } from "react"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import * as Yup from "yup"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  Input,
  Label,
  FormFeedback,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { getTblSafe } from "store/safe-transfer/actions"
import classes from "../../EndShift/index.module.scss"
import { useFormik } from "formik"
import {postSupplierPayment} from "store/reports/actions"

const PayDetailsModal = props => {
  const { isOpen, toggle, payDetailsData } = props
  const dispatch = useDispatch()

  const safeList = useSelector(state => state.safeTransfer.safeList)
  const reports = useSelector(state => state.Reports)

  const {
    loadingSupplierPay: loading,
  } = reports

  const [selectSafe, setSelectSafe] = useState("")
  const [valueToPay, setValueToPay] = useState(0)
  const [isSubmitDisable, setIsSubmitDisable] = useState(false)

  useEffect(() => {
    dispatch(getTblSafe())
  }, [])

  useEffect(() => {
    setValueToPay(payDetailsData && payDetailsData.realOwned)
  }, [payDetailsData])


  // reset form and toggle modal when update component
  useEffect(() => {
    if (loading) {
      resetForm()
      setIsSubmitDisable(false)
      //dataChanged()
      isOpen && toggle()
    }
  }, [loading])
  
  function resetForm () {
    setSelectSafe("")
    setValueToPay(payDetailsData.Owned)
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      className="exampleModal modal-lg"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>{`${props.t("Bill")}: ${
          payDetailsData.BillNo
        }`}</ModalHeader>
        <ModalBody>
          {/* <Row>
            <Col sm={6}>
              <div className="mb-3">
                <Label>{props.t("Owed to him")}</Label>
                <div className={`${classes.inputview}`}>{payDetailsData.realOwned}</div>
              </div>
            </Col>
            <Col>
              <Label>{props.t("paidValue")}</Label>
              <Input
                name="valueToPay"
                type="number"
                onChange={e => handleValueToPay(e.target.value)}
                onBlur={validation.handleBlur}
                value={validation.values.valueToPay || ""}
                invalid={
                  validation.touched.valueToPay && validation.errors.valueToPay
                    ? true
                    : false
                }
                min={1}
                max={payData.realOwned}
              />
              {validation.touched.valueToPay && validation.errors.valueToPay ? (
                <FormFeedback type="invalid">
                  {validation.errors.valueToPay}
                </FormFeedback>
              ) : null}
            </Col>
            <Col sm="12">
              <div className="mb-3">
                <Label>{props.t("availableSafe")}</Label>
                <Input
                  name="selectSafe"
                  type="select"
                  onChange={e => handleSelectSafe(e)}
                  onBlur={validation.handleBlur}
                  value={validation.values.selectSafe || ""}
                  invalid={
                    validation.touched.selectSafe &&
                    validation.errors.selectSafe
                      ? true
                      : false
                  }
                >
                  <option value="" disabled>
                    {props.t("Select Safe Name")}
                  </option>
                  {availableSafes.length ? (
                    availableSafes
                      .filter(
                        one =>
                          one.safe_type.toLowerCase() === "visa" ||
                          one.safe_type.toLowerCase() === "company"
                      )
                      .map((safe, index) => (
                        <option key={index} value={safe.pkSafeId}>
                          {safe.safeName}
                        </option>
                      ))
                  ) : (
                    <option disabled className="fst-italic">
                      {props.t("valueNotAvailbleInSafes")}..
                    </option>
                  )}
                </Input>
                {validation.touched.selectSafe &&
                validation.errors.selectSafe ? (
                  <FormFeedback type="invalid">
                    {validation.errors.selectSafe}
                  </FormFeedback>
                ) : null}
              </div>
              {availableSafes.length === 0 && (
                <div className="mb-2 text-center">
                  <span className="text-danger">
                    {props.t("youCanPayPartialValue")}..
                  </span>
                </div>
              )}
            </Col>
          </Row> */}
          <Row>
            <Col className="text-end mt-3">
              {/* <Button
                type="submit"
                color="primary"
                className="btn btn-success save-user"
                onClick={validation.handleSubmit}
                disabled={isSubmitDisable}

              >
                {isSubmitDisable ? (
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    ) : (
                      <i className="mdi mdi-check-circle-outline me-1" />
                    )}
                {props.t("Pay")}
              </Button> */}
              <Button
              type="button"
              color="secondary"
              className="mx-1"
              onClick={toggle}
            >
              <i className="mdi mdi-cancel me-1" />
              {props.t("Cancel")}
            </Button>
            </Col>
          </Row>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default withRouter(withTranslation()(PayDetailsModal))
