// import dashboard from "store/dashboard/reducer"

// main menu and pages keys
const privilegePageId = {
  dashboard: 27,
  mainProducts: 1,
  mainPurchase: 2,
  mainExpenses: 3,
  mainTransfer: 4,
  mainComponent: 5,
  mainShift: 6,
  mainReport: 7,
  products: 8,
  purchase: 9,
  expenses: 10,
  safeTransfer: 11,
  stockTransfer: 12,
  component: 13,
  endShift: 14,
  endShiftReport: 15,
  stockBalance: 16,
  safeBalance: 17,
  supplierPayment: 18,
  netProfit: 19,
  monthlyReport: 20,
  dailyReport: 21,
  supplierAccount: 22,
  expenseReport: 23,
  itemsSalesReport: 24,
  shiftReport: 25,
  safeTransferReport: 26,
  stockTransferReport: 28
}

const pageActionId = {
    products: {
        addProduct: 8,
        showProduct: 1,
        editProduct: 9,
        addCategory: 10,
        addSubCategory: 11,
        addPrepareArea: 12,
        addComponent: 13,
        addMaterial: 14,
        addUnit: 15
    }
}

export  {privilegePageId, pageActionId}
