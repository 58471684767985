import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  Button,
  Table,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import { useFormik } from "formik"
import * as Yup from "yup"
import SimpleBar from "simplebar-react"
import { getUnits, getMaterials, updateMaterialComponent } from "store/actions"
import SelectInputErrorMessage from "../../../Products/SelectInputErrorMessage"
import useSelectInputErrMsg from "../../../Products/hooks/use-selectInputErrMsg"

const ComponentModal = props => {
  const { componentModal, handleComponentToggle, componentData } = props
  const dispatch = useDispatch()
  const { errorMsgs, sets, states } = useSelectInputErrMsg({ t: props.t })

  const materials = useSelector(state => state.materials.allMaterials)
  const oneComponent = useSelector(state => state.materials.oneComponent)
  const units = useSelector(state => state.units.allUnits)
  const isUpdateMaterialComponent = useSelector(
    state => state.materials.updateMaterialComponent
  )

  const [PK_Material_ID, setPK_Material_ID] = useState("")
  const [componentName, setComponentName] = useState("")
  const [componentQty, setComponentQty] = useState(0)
  const [selectedUnit, setSelectedUnit] = useState("")
  const [unitList, setUnitList] = useState([])

  const [materialList, setMaterialList] = useState([])
  const [materialQuantity, setMaterialQty] = useState(0)
  const [selectedMaterial, setSelectedMaterial] = useState("")
  const [selectedMaterialName, setSelectedMaterialName] = useState("")
  // List of Ingrediants
  const [tableRows, setTableRows] = useState([])
  const [oldTableRow, setOldTableRow] = useState([])
  const [isSubmitDisable, setIsSubmitDisable] = useState(false)

  const [isEditing, setIsEditing] = useState(false)
  const [marialListToSendErr, setMarialListToSendErr] = useState(false)
  const [componentsList, setComponentsList] = useState([])
  const [compNameExist, setCompNameExist] = useState(false)

  useEffect(() => {
    //
    resetForm()
    // add onecomponent aded dependency
    setPK_Material_ID(componentData.PK_Material_ID)
    if (oneComponent.length > 0) {
      setComponentName(oneComponent[0].ComponentName)
      setComponentQty(oneComponent[0].MokQty)
      setSelectedUnit(oneComponent[0].FK_CompUnit_ID)

      let rowMaterialCopy = []
      let compMaterial = {}
      let id = 0
      for (let comp of oneComponent) {
        compMaterial = {
          id: ++id,
          FkMaterialId: comp.FK_MaterialID,
          materialName: comp.MaterialName,
          qty: comp.Qty,
        }
        rowMaterialCopy.push(compMaterial)
      }
      setTableRows(rowMaterialCopy)
    }
  }, [oneComponent])

  // reset form and toggle modal when update component
  useEffect(() => {
    if (isUpdateMaterialComponent) {
      resetForm()
      setIsSubmitDisable(false)
      componentModal && toggle()
    }
  }, [isUpdateMaterialComponent])

  useEffect(() => {
    if (units && !units.length) {
      dispatch(getUnits())
    }
  }, [dispatch, units])

  useEffect(() => {setUnitList(units)}, [units])

  // Get All Material From Api
  useEffect(() => {
    dispatch(getMaterials())
  }, [])

  useEffect(() => {
    let materialListCopy = []
    materials &&
      materials.map(
        item =>
          !item.material && item.materialName && materialListCopy.push(item)
      )
    setMaterialList(materialListCopy)
    handleComponenetList()
  }, [materials])

  const handleComponenetList = () => {
    let componentListCopy = []
    materials &&
      materials.map(item => {
        item.materialName && item.material && componentListCopy.push(item)
      })
    setComponentsList(componentListCopy)
  }

  function handleRemoveRow(id) {
    var modifiedRows = [...tableRows]
    modifiedRows = modifiedRows.filter(x => x["id"] !== id)
    setTableRows(modifiedRows)
  }

  const toggle = () => {
    handleComponentToggle()
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      componentName: componentName || "",
      componentQty: componentQty || "",
      selectedUnit: selectedUnit || "",
      PK_Material_ID: PK_Material_ID || "",
    },
    validationSchema: Yup.object({
      componentName: Yup.string().required(
        props.t("Please Enter Component Name")
      ),
      componentQty: Yup.string().required(
        props.t("Please Enter Component Qty")
      ),
      selectedUnit: Yup.number().required(props.t("Please select unit")),
    }),
    onSubmit: values => {
      if(!compNameExist){
        const materials = handleMaterialListToSend()
        const newComponent = {
          PK_Material_ID: Number(values["PK_Material_ID"]),
          // PK_Material_ID: 238,
          materialName: values["componentName"],
          mindemand: values["componentQty"],
          fk_Unit_ID: values["selectedUnit"],
          component: true,
          materialComponent: materials,
        }
  
        // save new user
        // dispatch(postComponent(newComponent))
        materials.length > 0
          ? dispatch(updateMaterialComponent(newComponent))
          : setMarialListToSendErr(true)
        materials.length > 0 && setIsSubmitDisable(true)
      }
    },
  })
  const handleMaterialListToSend = () => {
    let materialListToSend = []
    for (let element of tableRows) {
      element.id = 0
      element.mokQty = componentQty
      delete element["materialName"]
      materialListToSend.push(element)
    }
    return materialListToSend
  }
  const materialValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      selectedMaterialId: selectedMaterial || "",
      selectedMaterialName: selectedMaterialName || "",
      materialQuantity: materialQuantity || "",
    },
    validationSchema: Yup.object({
      selectedMaterialId: Yup.number().required(
        props.t("Please Select Material")
      ),
      materialQuantity: Yup.number().required(
        props.t("Please enter material quantity")
      ),
    }),
    onSubmit: values => {
      setMarialListToSendErr(false)
      const newMaterial = {
        FkMaterialId: values["selectedMaterialId"],
        materialName: values["selectedMaterialName"],
        qty: values["materialQuantity"],
      }
      addMaterialToList(newMaterial)
      materialValidation.resetForm()
      setSelectedMaterial("")
      setMaterialQty("")

      // // toggle()
    },
  })

  const resetForm = () => {
    setPK_Material_ID("")
    setSelectedMaterial("")
    setComponentName("")
    setComponentQty(0)
    setSelectedUnit("")
    setMaterialQty("")
    setIsEditing(false)
    setTableRows([])
    setMarialListToSendErr(false)
  }

  const handleSelectUnit = e => {
    setSelectedUnit(Number(e.target.value))
  }

  const handleCancel = () => {
    resetForm()
    setTableRows(oldTableRow)
    setIsSubmitDisable(false)
    validation.resetForm()
    componentModal && toggle()
  }

  function handleSelectMaterial(e) {
    setSelectedMaterial(Number(e.target.value))
    materialList.map(
      option =>
        option.pkMaterialId === Number(e.target.value) &&
        setSelectedMaterialName(option.materialName)
    )
  }

  const addMaterialToList = newMaterial => {
    // setOldTableRow(tableRows)
    const modifiedIngList = [...tableRows]
    let id
    // Check if item is exist in list
    for(let comp of modifiedIngList) {
      if(comp.FkMaterialId === newMaterial.FkMaterialId) {
        comp.qty = comp.qty + newMaterial.qty
        setTableRows(modifiedIngList)
        return
      }
    }
    tableRows.length > 0
      ? (id = tableRows[tableRows.length - 1].id + 1)
      : (id = 1)
    modifiedIngList.map(ingItem => (ingItem.id = ++id))
    newMaterial.id = modifiedIngList.length + 1
    modifiedIngList.push(newMaterial)
    setTableRows(modifiedIngList)
  }
  const handleBlurName = e => {
    setCompNameExist(false)
    componentsList &&
      componentsList.map(
        comp =>
          comp.materialName.trim().toLowerCase() === e.trim().toLowerCase() && comp.materialName.trim().toLowerCase() !== oneComponent[0].ComponentName.trim().toLowerCase() &&
          setCompNameExist(true)
      )
  }

  return (
    <Modal isOpen={componentModal} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} tag="h4">
        {isEditing ? props.t("Edit Component") : props.t("Component Details")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col xs={6}>
              <div className="mb-3">
                <Label>{props.t("Components")}</Label>
                <Input
                  name="componentName"
                  type="text"
                  placeholder={props.t("Enter component name")}
                  onChange={e => setComponentName(e.target.value)}
                  onBlur={validation.handleBlur}
                  onBlurCapture={e => handleBlurName(e.target.value)}
                  value={validation.values.componentName || ""}
                  invalid={
                    validation.touched.componentName &&
                    validation.errors.componentName
                      ? true
                      : false
                  }
                  disabled={isEditing ? false : true}
                />
                {validation.touched.componentName &&
                validation.errors.componentName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.componentName}
                  </FormFeedback>
                ) : null}
                {compNameExist ? (
                  <span className="invalid-feedback d-block">
                    Component name already exist
                  </span>
                ) : null}
              </div>
            </Col>
            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">{props.t("Quantity")}</Label>
                <Input
                  name="componentQty"
                  type="number"
                  placeholder={props.t("Enter Quantity")}
                  onChange={e => setComponentQty(Number(e.target.value))}
                  onBlur={validation.handleBlur}
                  value={validation.values.componentQty || ""}
                  invalid={
                    validation.touched.componentQty &&
                    validation.errors.componentQty
                      ? true
                      : false
                  }
                  min={0}
                  disabled={isEditing ? false : true}
                />
                {validation.touched.componentQty &&
                validation.errors.componentQty ? (
                  <FormFeedback type="invalid">
                    {validation.errors.componentQty}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="mb-3">
                <label className="form-label">{props.t("Default Unit")}</label>
                <div className="input-group">
                  <div className="col-12">
                    <Input
                      name="selectedUnit"
                      type="select"
                      onChange={e => {
                        handleSelectUnit(e)
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.selectedUnit || ""}
                      invalid={
                        validation.touched.selectedUnit &&
                        validation.errors.selectedUnit
                          ? true
                          : false
                      }
                      disabled
                    >
                      <option value="">{props.t("Select")}</option>
                      {unitList.map((unit, index) => (
                        <option key={index} value={unit.pkUnitId}>
                          {unit.unit}
                        </option>
                      ))}
                    </Input>
                    {validation.touched.selectedUnit &&
                    validation.errors.selectedUnit ? (
                      <FormFeedback type="invalid">
                        {validation.errors.selectedUnit}
                      </FormFeedback>
                    ) : null}
                  </div>
                  {/* <div className="col-2">
                    <button
                      className="btn btn-primary w-100"
                      style={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                      type="button"
                      onClick={handleUnitModal}
                    >
                      {props.t("Add")}
                    </button>
                  </div> */}
                  {validation.touched.selectedUnit &&
                  validation.errors.selectedUnit ? (
                    <FormFeedback type="invalid">
                      {validation.errors.selectedUnit}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>

          {isEditing && <Row>
            <Col sm={6}>
              <div className="mb-3">
                <Label>{props.t("Material")}</Label>
                <div className="input-group">
                  <Input
                    name="selectedMaterialId"
                    type="select"
                    onChange={e => {
                      handleSelectMaterial(e)
                    }}
                    onBlur={materialValidation.handleBlur}
                    value={materialValidation.values.selectedMaterialId || ""}
                    invalid={
                      materialValidation.touched.selectedMaterialId &&
                      materialValidation.errors.selectedMaterialId
                        ? true
                        : false
                    }
                    disabled={isEditing ? false : true}
                  >
                    <option value="">{props.t("Select")}</option>
                    {materialList.map(material => (
                      <option
                        key={material.pkMaterialId}
                        value={material.pkMaterialId}
                      >
                        {material.materialName}
                      </option>
                    ))}
                  </Input>

                  {materialValidation.touched.selectedMaterialId &&
                  materialValidation.errors.selectedMaterialId ? (
                    <FormFeedback type="invalid">
                      {materialValidation.errors.selectedMaterialId}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </Col>

            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">{props.t("Quantity")}</Label>
                <div className="input-group">
                  <Input
                    name="materialQuantity"
                    type="number"
                    placeholder={props.t("Enter Quantity")}
                    onChange={e => setMaterialQty(Number(e.target.value))}
                    onBlur={materialValidation.handleBlur}
                    value={materialValidation.values.materialQuantity || ""}
                    invalid={
                      materialValidation.touched.materialQuantity &&
                      materialValidation.errors.materialQuantity
                        ? true
                        : false
                    }
                    min={0}
                    disabled={isEditing ? false : true}
                  />
                  <button
                    className="btn btn-primary mx-1"
                    onClick={e => {
                      e.preventDefault()
                      materialValidation.handleSubmit()
                      return false
                    }}
                    type="button"
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    {props.t("Add Ingredients")}
                  </button>
                  {materialValidation.touched.materialQuantity &&
                  materialValidation.errors.materialQuantity ? (
                    <FormFeedback type="invalid">
                      {materialValidation.errors.materialQuantity}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>}
          <div className="mt-4 mx-auto">
            <SimpleBar
              style={{ maxHeight: "195px", width: "75%", margin: "0 auto" }}
            >
              <div className="table-responsive">
                <Table className="table table-nowrap align-middle table-hover mb-0">
                  <tbody>
                    {(tableRows || []).map((tableRow, key) => (
                      <tr key={key}>
                        <td>
                          <h5 className="text-truncate font-size-14 mb-1">
                            {tableRow.materialName}
                          </h5>
                        </td>
                        <td>
                          <h5 className="text-truncate font-size-14 mb-1">
                            {tableRow.qty}
                          </h5>
                        </td>
                        <td style={{ width: "90px" }}>
                          {isEditing && (
                            <div>
                              <ul className="list-inline mb-0 font-size-16">
                                <li className="list-inline-item">
                                  <Link
                                    onClick={() => handleRemoveRow(tableRow.id)}
                                    className="text-danger p-1 border-none"
                                  >
                                    <i className="bx bxs-trash" />
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {marialListToSendErr && (
                  <div className="text-danger text-center">
                    Please select at least one material...
                  </div>
                )}
              </div>
            </SimpleBar>
          </div>

          <Row>
            <Col>
              <div className="text-end">
                {isEditing && (
                  <button
                    type="submit"
                    className="btn btn-success save-user"
                    disabled={isSubmitDisable}
                  >
                    {isSubmitDisable ? (
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    ) : (
                      <i className="mdi mdi-check-circle-outline me-1" />
                    )}
                    {props.t("Save")}
                  </button>
                )}

                {!isEditing && (
                  <Button
                    onClick={() => setIsEditing(true)}
                    className="btn btn-warning mx-1"
                  >
                    <i className="mdi mdi-pencil me-1" />
                    {props.t("Edit")}
                  </Button>
                )}
                <Button
                  type="button"
                  color="secondary"
                  className="mx-1"
                  onClick={handleCancel}
                >
                  <i className="mdi mdi-cancel me-1" />
                  {props.t("Cancel")}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

ComponentModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ComponentModal))
