import React, { useEffect } from "react"
import ReactApexChart from "react-apexcharts"

const Dountchart = ({ data, labels }) => {
  const series = data
  useEffect(() => {
  }, [series])
  const options = {
    labels: labels,
    colors: ["#3E4954", "#fb940e", "#34c38f"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  }

  return (
    <div dir="ltr">
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height="420"
      />
    </div>
  )
}

export default Dountchart
