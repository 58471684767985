import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import { Col, Row, Card, CardBody, Spinner } from "reactstrap"
import DateFromTo from "pages/Reports/shared/DateFromTo"
import Breadcrumbs from "components/Common/Breadcrumb"

import { useDispatch, useSelector } from "react-redux"
import { getNetProfit } from "store/actions"
import { convertDate, convertParametersToString } from "pages/Reports/helpers"
import SendAndPrintPdf from "pages/Reports/shared/pdf/components/SendAndPrintPdf"

const NetProfit = props => {
  //meta title
  document.title = "Net Profit | Reports"
  const dispatch = useDispatch()

  const [date, setDate] = useState(null)
  const reports = useSelector(state => state.Reports)
  const { getNetProfit: getNetProfitData, loadingNetProfit: loading } = reports

  useEffect(() => {
    if (!date) return
    const parameters = convertParametersToString(date)
    dispatch(getNetProfit(parameters))
  }, [date])

  const getDateFromToHandler = date => {
    setDate(date)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Shift")}
            breadcrumbItem={props.t("Net Profit")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody className="border-bottom ml-2">
                  <Row>
                    <DateFromTo getDateFromTo={getDateFromToHandler} />
                  </Row>
                </CardBody>
                {getNetProfitData && getNetProfitData.length > 0 && (
                  <>
                    {loading ? (
                      <div className="text-center py-5">
                        <Spinner className="ms-2" color="primary" />
                      </div>
                    ) : (
                      <>
                        <CardBody className="border-bottom text-center">
                          <h6>{props.t("Net Profit")}</h6>
                          <h4 className="mb-3">
                            $ {getNetProfitData[0].profit || 0.0}
                          </h4>
                        </CardBody>
                        <CardBody>
                          <div
                            className="table-responsive"
                            style={{ maxWidth: "600px", margin: "0 auto" }}
                          >
                            <table className="table table-bordered table-striped table-nowrap mb-0">
                              <tbody>
                                <tr>
                                  <th className="text-nowrap" scope="row">
                                    {props.t("Total Sales")}
                                  </th>
                                  <td>{getNetProfitData[0].sum_sell || 0.0}</td>
                                </tr>
                                <tr>
                                  <th className="text-nowrap" scope="row">
                                    {props.t("Total Cost")}
                                  </th>
                                  <td>
                                    {getNetProfitData[0].sum_payments || 0.0}
                                  </td>
                                </tr>
                                <tr>
                                  <th className="text-nowrap" scope="row">
                                    {props.t("Total Expenses return")}
                                  </th>
                                  <td>
                                    {getNetProfitData[0].sum_payreturn || 0.0}
                                  </td>
                                </tr>
                                <tr>
                                  <th className="text-nowrap" scope="row">
                                    {props.t("Total return")}
                                  </th>
                                  <td>
                                    {getNetProfitData[0].sum_return || 0.0}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </>
                    )}
                    {!loading &&
                    getNetProfitData &&
                    getNetProfitData[0] &&
                    getNetProfitData[0].profit ? (
                      <div className="mt-2 d-flex justify-content-end">
                        <SendAndPrintPdf
                          extraData={{
                            Date_from:
                              date && date.dateFrom
                                ? convertDate(new Date(date.dateFrom))
                                : "First",
                            Date_to:
                              date && date.dateTo
                                ? convertDate(new Date(date.dateTo))
                                : convertDate(new Date()),
                          }}
                          data={Object.entries({
                            "Net profit": getNetProfitData[0].profit || 0.0,
                            "Total sales": getNetProfitData[0].sum_sell || 0.0,
                            "Total cost":
                              getNetProfitData[0].sum_payments || 0.0,
                            "Total expenses return":
                              getNetProfitData[0].sum_payreturn || 0.0,
                            "Total return":
                              getNetProfitData[0].sum_return || 0.0,
                          }).map(one => ({ [one[0]]: one[1] }))}
                          filteredData={null}
                          extraTwoColData={null}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

NetProfit.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(NetProfit))
