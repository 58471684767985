import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  FormGroup,
  Button,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { useFormik } from "formik"
import * as Yup from "yup"
import UnitModal from "./UnitModal"
import { toast } from "react-toastify"
import { postMaterial } from "../../../store/products/materials/actions"
import { getUnits, resetPostUnit, resetModelType } from "store/actions"
import { get } from "helpers/axios_auth_api"
import { pageActionId } from "common/privilege"

const MaterialModal = props => {
  const dispatch = useDispatch()
  const { handleMaterialToggle, materialModal, materialList } = props

  const materialErr = useSelector(state => state.materials.error)
  const units = useSelector(state => state.units.allUnits)
  const newUnitAdded = useSelector(state => state.units.newUnitAdded)
  const modelType = useSelector(state => state.units.modelType)
  const pageActionData = useSelector(state => state.privillage.pageActionData)


  const [materialName, setMaterialName] = useState("")
  const [selectedUnit, setSelectedUnit] = useState("")
  const [minOrder, setMinOrder] = useState(0)
  const [unitModal, setUnitModal] = useState(false)
  const [unit, setUnit] = useState([])
  const [unitUpdated, setUnitUpdated] = useState(false)

  const [materialAddErr, setMaterialAddErr] = useState(null)
  const [matNameExist, setMatNameExist] = useState(false)

  const toggle = () => {
    handleMaterialToggle()
  }

  // useEffect to get unit when added
  useEffect(() => {
    if (newUnitAdded && !unitModal && modelType === "material") {
      setSelectedUnit(+units[units.length - 1].pkUnitId)
      dispatch(resetPostUnit())
    }
  }, [newUnitAdded, unitModal])

  const handleUnitUpdated = () => {
    setUnitUpdated(!unitUpdated)
  }

  useEffect(() => {
    if (units && !units.length) {
      dispatch(getUnits())
    }
  }, [dispatch, units])

  useEffect(() => {
    // fetchUnits();
    setUnit(units)
  }, [unitUpdated, units])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      materialName: materialName || "",
      selectedUnit: selectedUnit || "",
      minOrder: minOrder || "",
    },
    validationSchema: Yup.object({
      materialName: Yup.string().required(
        props.t("Please Enter Material Name")
      ),
      selectedUnit: Yup.string().required(props.t("Please select unit")),
      minOrder: Yup.number().required(props.t("Please enter min. order")),
    }),
    onSubmit: async values => {
      const newMaterial = {
        materialName: values["materialName"],
        fkUnitId: Number(values["selectedUnit"]),
        mindemand: Number(values["minOrder"]),
        material: false,
        matPrice: null,
      }
      if (!matNameExist) {
        try {
          dispatch(postMaterial(newMaterial))
          if (Object.keys(materialErr).length !== 0) {
          } else {
            validation.resetForm()
            toggle()
          }
        } catch (err) {
          console.log(err)
        }
      }
    },
  })

  const handleCancel = () => {
    toggle()
  }
  const handleSelectUnit = e => {
    setSelectedUnit(e.target.value)
  }

  const handleUnitModal = () => {
    setUnitModal(true)
    dispatch(resetModelType("material"))
  }

  const handleUnitToggle = () => {
    setUnitModal(!unitModal)
  }

  // To Check if material Name is already exist
  const handleBlurName = e => {
    setMatNameExist(false)
    materialList &&
      materialList.map(
        material =>
        material.materialName.trim() !== "" && material.materialName.trim().toLowerCase() === e.trim().toLowerCase() && setMatNameExist(true)
      )
  }

  return (
    <Modal isOpen={materialModal} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} tag="h4">
        {props.t("Add Material")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">{props.t("Raw Material")}</Label>
                <Input
                  name="materialName"
                  type="text"
                  placeholder={props.t("Enter Raw Material")}
                  onChange={e => setMaterialName(e.target.value)}
                  onBlur={validation.handleBlur}
                  onBlurCapture={e => handleBlurName(e.target.value)}
                  value={validation.values.materialName || ""}
                  invalid={
                    validation.touched.materialName &&
                    validation.errors.materialName
                      ? true
                      : false
                  }
                />
                {validation.touched.materialName &&
                validation.errors.materialName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.materialName}
                  </FormFeedback>
                ) : null}
                {matNameExist ? <span className="invalid-feedback d-block">{props.t("Material name already exist")}</span> : null}

              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <label className="form-label">{props.t("Default Unit")}</label>
              <div className="input-group">
                <Col xs={10}>
                  <Input
                    name="selectedUnit"
                    type="select"
                    onChange={e => {
                      handleSelectUnit(e)
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.selectedUnit || ""}
                    invalid={
                      validation.touched.selectedUnit &&
                      validation.errors.selectedUnit
                        ? true
                        : false
                    }
                  >
                    <option value="">{props.t("Select")}</option>
                    {unit.map((unit, index) => (
                      <option key={index} value={unit.pkUnitId}>
                        {unit.unit}
                      </option>
                    ))}
                  </Input>
                  {validation.touched.selectedUnit &&
                  validation.errors.selectedUnit ? (
                    <FormFeedback type="invalid">
                      {validation.errors.selectedUnit}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col xs={2}>
                  {pageActionData.some(
                                      action =>
                                        action.PK_PageAction_ID ===
                                        pageActionId.products.addUnit
                                    ) && <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleUnitModal}
                  >
                    {props.t("Add")}
                  </button>}
                </Col>

                {validation.touched.unit && validation.errors.unit ? (
                  <FormFeedback type="invalid">
                    {validation.errors.unit}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col sm={6}>
              <div className="mb-3">
                <Label className="form-label">{props.t("Minimum Order")}</Label>
                <div className="input-group">
                  <Input
                    name="minOrder"
                    type="number"
                    placeholder={props.t("Enter Minimum Order")}
                    onChange={e => setMinOrder(e.target.value)}
                    onBlur={validation.handleBlur}
                    value={validation.values.minOrder || ""}
                    invalid={
                      validation.touched.minOrder && validation.errors.minOrder
                        ? true
                        : false
                    }
                    min={0}
                  />
                  {validation.touched.minOrder && validation.errors.minOrder ? (
                    <FormFeedback type="invalid">
                      {validation.errors.minOrder}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  <i className="mdi mdi-check-circle-outline me-1" />
                  {props.t("Save")}
                </button>
                <Button
                  type="button"
                  color="secondary"
                  className="mx-1"
                  onClick={handleCancel}
                >
                  <i className="mdi mdi-cancel me-1" />
                  {props.t("Cancel")}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
        <UnitModal
          unitModal={unitModal}
          handleUnitToggle={handleUnitToggle}
          handleUnitUpdated={handleUnitUpdated}
        />
      </ModalBody>
    </Modal>
  )
}

MaterialModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(MaterialModal))
