const GET_SHIF_IN_PERIOD = "GET_SHIF_IN_PERIOD";
const GET_SHIF_IN_PERIOD_SUCCESS = "GET_SHIF_IN_PERIOD_SUCCESS";
const GET_SHIF_IN_PERIOD_FAIL = "GET_SHIF_IN_PERIOD_FAIL";

const GET_SHIF_DETAILS_BY_SHIFT_ID = "GET_SHIF_DETAILS_BY_SHIFT_ID";
const GET_SHIF_DETAILS_BY_SHIFT_ID_SUCCESS = "GET_SHIF_DETAILS_BY_SHIFT_ID_SUCCESS";
const GET_SHIF_DETAILS_BY_SHIFT_ID_FAIL = "GET_SHIF_DETAILS_BY_SHIFT_ID_FAIL";

export {
    GET_SHIF_IN_PERIOD,
    GET_SHIF_IN_PERIOD_SUCCESS,
    GET_SHIF_IN_PERIOD_FAIL,
    GET_SHIF_DETAILS_BY_SHIFT_ID,
    GET_SHIF_DETAILS_BY_SHIFT_ID_SUCCESS,
    GET_SHIF_DETAILS_BY_SHIFT_ID_FAIL
};