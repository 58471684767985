import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import {
  Row,
  TabPane,
  Button,
  UncontrolledTooltip,
  CardBody,
  Spinner,
  Col,
  Card,
  CardHeader,
} from "reactstrap"
import { Link, useLocation, useNavigate } from "react-router-dom"
import DateFromTo from "pages/Reports/shared/DateFromTo"

import { useDispatch, useSelector } from "react-redux"
import {
  getShifInPeriod,
  getShifDetailsByShiftId,
} from "store/end-shift-report/actions"
import { convertParametersToString } from "pages/Reports/helpers"
import ShiftDetails from "pages/EndShiftReport/ShiftDetails"
import TableContainer from "components/Common/TableContainer"
import SendAndPrintPdf from "pages/Reports/shared/pdf/components/SendAndPrintPdf"

const FilterByDate = props => {
  const dispatch = useDispatch()

  const navigation = useNavigate()
  const { search } = useLocation()

  const query = new URLSearchParams(search)
  const id = query.get("id")

  const endShiftReport = useSelector(state => state.endShiftReport)
  const {
    getShiftInPreiod: ShiftInPreiod,
    loadingShiftInPreiod: loading,
    getshiftDetails: shiftDetails,
  } = endShiftReport

  const [allShiftInPeriod, setAllShiftInPeriod] = useState([])
  const [finalTabelData, setFinalTabelData] = useState([])
  const [periodDate, setPeriodDate] = useState({
    dateFrom: "",
    dateTo: "",
  })
  const [preventPrint, setPreventPrint] = useState(true)
  const [labelsSum, setLabelsSum] = useState({
    Total_Orders: 0,
    Net_Orders_Value: 0,
    Net_Cash: 0,
    Total_Visa: 0,
    Total_Companies: 0,
  })
  // Fetch shift details by shift id in query
  useEffect(() => {
    if (!id) return
    dispatch(getShifDetailsByShiftId(id))
  }, [id])

  useEffect(() => {
    setAllShiftInPeriod(ShiftInPreiod)
    let ShiftInPreiodCopy = []
    ShiftInPreiodCopy = ShiftInPreiod.Table
    ShiftInPreiodCopy &&
      ShiftInPreiodCopy.map(
        shift => shift.StartTime && (shift.startTimeCopy = shift.StartTime)
      )
    ShiftInPreiodCopy &&
      ShiftInPreiodCopy.map(shift =>
        shift.Endtime
          ? (shift.endTimeCopy = shift.Endtime)
          : (shift.endTimeCopy = "")
      )

    setAllShiftInPeriod(ShiftInPreiodCopy)
    setFinalTabelData(ShiftInPreiodCopy)
  }, [ShiftInPreiod])

  // add the additional labels
  useEffect(() => {
    if (!allShiftInPeriod || !allShiftInPeriod.length) return

    setLabelsSum({
      Total_Orders: 0,
      Net_Orders_Value: 0,
      Net_Cash: 0,
      Total_Visa: 0,
      Total_Companies: 0,
    })
    setPreventPrint(true)
    const fetchData = async () => {
      await Promise.all(
        allShiftInPeriod.map(shift => {
          if (shift && shift.PK_Shift_ID) {
            dispatch(getShifDetailsByShiftId(shift.PK_Shift_ID))
          }
        })
      )
      setPreventPrint(false)
    }

    const timer = setTimeout(() => {
      // Invoke the async function
      fetchData()
    }, 700)

    return () => {
      clearTimeout(timer)
    }
  }, [allShiftInPeriod])

  useEffect(() => {
    if (id) return
    if (
      !finalTabelData ||
      !finalTabelData.length ||
      !shiftDetails.Table11 ||
      !shiftDetails.Table11
    )
      return

    const rowIndex = finalTabelData.findIndex(
      t => t.PK_Shift_ID === shiftDetails.Table11[0].PK_Shift_ID
    )

    if (rowIndex > -1) {
      const newRowData = finalTabelData[rowIndex]
      newRowData.Total_Orders =
        shiftDetails.Table && shiftDetails.Table.length > 0
          ? shiftDetails.Table[0].ordercount || 0
          : 0
      newRowData.Net_Orders_Value = shiftDetails.Table[0].TotalAfterDiscount
        ? shiftDetails.Table[0].TotalAfterDiscount -
            shiftDetails.Table1[0].returnedvalue || 0
        : 0
      newRowData.Net_Cash =
        shiftDetails.Table10 && shiftDetails.Table10.length > 0
          ? shiftDetails.Table10[0].NetCashAfterCLose || 0
          : 0
      newRowData.Total_Visa =
        shiftDetails.Table && shiftDetails.Table.length > 0
          ? shiftDetails.Table[0].Visaprice || 0
          : 0

      // retieve total companies count
      // newRowData.Total_Companies =
      //   shiftDetails.Table12 && shiftDetails.Table12.length > 0
      //     ? shiftDetails.Table12.length || 0
      //     : 0

      // retieve total companies value
      const getTotalCoValues = data => {
        const sum = data.reduce((init, current) => {
          return init + current.CompOrderValue
        }, 0)
        return sum
      }

      newRowData.Total_Companies =
        shiftDetails.Table12 && shiftDetails.Table12.length > 0
          ? getTotalCoValues(shiftDetails.Table12) || 0
          : 0
      // parseFloat(totalPrice).toFixed(2)
      setLabelsSum(prevState => ({
        Total_Orders: prevState.Total_Orders + newRowData.Total_Orders,
        Net_Orders_Value:
          prevState.Net_Orders_Value + newRowData.Net_Orders_Value,
        Net_Cash: prevState.Net_Cash + newRowData.Net_Cash,
        Total_Visa: prevState.Total_Visa + newRowData.Total_Visa,
        Total_Companies: prevState.Total_Companies + newRowData.Total_Companies,
      }))

      const newTableData = [...finalTabelData]
      newTableData[rowIndex] = newRowData
      setFinalTabelData(newTableData)
    }
  }, [shiftDetails])

  const getDateFromToHandler = date => {
    setPeriodDate(date)
    const parameters = convertParametersToString(date)
    dispatch(getShifInPeriod(parameters))
  }
  const columns = useMemo(
    () => [
      {
        Header: props.t("ID"),
        accessor: "PK_Shift_ID",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("Start Date"),
        accessor: "StartTime",
        filterable: true,
        Cell: cellProps => {
          const date = new Date(cellProps.value)
          const year = date.getFullYear()
          const month = date.getMonth() + 1
          const day = date.getDate()
          return <>{cellProps.value ? day + "/" + month + "/" + year : ""}</>
        },
      },
      {
        Header: props.t("Start Time"),
        accessor: "startTimeCopy",
        filterable: true,
        Cell: cellProps => {
          const date = new Date(cellProps.value)
          const hour = date.getHours()
          const minutes = date.getMinutes()
          const secondes = date.getSeconds()
          return <>{`${hour}:${minutes}:${secondes}`}</>
        },
      },
      {
        Header: props.t("End Date"),
        accessor: "Endtime",
        filterable: true,
        Cell: cellProps => {
          const date = new Date(cellProps.value)
          const year = date.getFullYear()
          const month = date.getMonth() + 1
          const day = date.getDate()
          return <>{cellProps.value ? day + "/" + month + "/" + year : ""}</>
        },
      },
      {
        Header: props.t("End Time"),
        accessor: "endTimeCopy",
        filterable: true,
        Cell: cellProps => {
          if (cellProps.value !== "") {
            const date = new Date(cellProps.value)
            const hour = date.getHours()
            const minutes = date.getMinutes()
            const secondes = date.getSeconds()
            return <>{`${hour}:${minutes}:${secondes}`}</>
          } else {
            return <></>
          }
        },
      },
      {
        Header: props.t("Orders"),
        accessor: "Total_Orders",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || "0"}</>
        },
      },
      {
        Header: props.t("Orders Value"),
        accessor: "Net_Orders_Value",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || "0"}</>
        },
      },
      {
        Header: props.t("Net Cash"),
        accessor: "Net_Cash",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || "0"}</>
        },
      },
      {
        Header: props.t("Cards"),
        accessor: "Total_Visa",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || "0"}</>
        },
      },
      {
        Header: props.t("Total Co."),
        accessor: "Total_Companies",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || "0"}</>
        },
      },
      {
        Header: props.t("View"),
        Cell: cellProps => {
          const { PK_Shift_ID } = cellProps.row.original
          return (
            <div className="list-unstyled hstack gap-1 mb-0">
              <li>
                <Button
                  type="button"
                  className="btn btn-sm btn-soft-primary"
                  onClick={() =>
                    navigation(`/end-shift-report?id=${PK_Shift_ID}`)
                  }
                >
                  <i className="mdi mdi-eye-outline" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    {props.t("View")}
                  </UncontrolledTooltip>
                </Button>
              </li>
            </div>
          )
        },
      },
    ],
    [finalTabelData, props.t]
  )

  const footerData = {
    PK_Shift_ID: "Total",
    StartTime: "",
    startTimeCopy: "",
    Endtime: "",
    endTimeCopy: "",
    Total_Orders: labelsSum.Total_Orders,
    Net_Orders_Value: labelsSum.Net_Orders_Value,
    Net_Cash: labelsSum.Net_Cash,
    Total_Visa: labelsSum.Total_Visa,
    Total_Companies: labelsSum.Total_Companies,
    View: "",
  }

  return (
    <TabPane tabId="2">
      <Row>
        <CardBody>
          <DateFromTo getDateFromTo={getDateFromToHandler} />
        </CardBody>

        <div className="mt-4 mx-auto">
          {id && (
            <Link to="/end-shift-report">
              <Button
                type="button"
                color="primary"
                className="btn btn-primary btn-label mx-4"
              >
                <i className="bx bx-left-arrow label-icon align-middle fs-16 me-2" />
                {props.t("Back")}
              </Button>
            </Link>
          )}
          <CardBody>
            {loading ? (
              <div className="text-center py-5">
                <Spinner className="ms-2" color="primary" />
              </div>
            ) : (
              <>
                {id ? (
                  <ShiftDetails />
                ) : (
                  <>
                    {!preventPrint && (
                      <Row className="justify-content-center">
                        <Col lg="12" xxl="6">
                          <Card>
                            <CardHeader className="align-items-center d-flex">
                              <h4 className="card-title mb-0 flex-grow-1">
                                {props.t("Summary")}
                              </h4>
                            </CardHeader>
                            <CardBody>
                              <div
                                className="table-responsive"
                                style={{ maxWidth: "800px", margin: "0 auto" }}
                              >
                                <table className="table table-bordered table-striped table-nowrap mb-0">
                                  <tbody>
                                    <tr>
                                      <th className="text-nowrap" scope="row">
                                        {props.t("Orders")}
                                      </th>
                                      <td>
                                        {parseFloat(
                                          labelsSum.Total_Orders
                                        ).toFixed(2)}
                                      </td>
                                      <th className="text-nowrap" scope="row">
                                        {props.t("Orders Value")}
                                      </th>
                                      <td>
                                        {parseFloat(
                                          labelsSum.Net_Orders_Value
                                        ).toFixed(2)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className="text-nowrap" scope="row">
                                        {props.t("Net Cash")}
                                      </th>
                                      <td>
                                        {parseFloat(labelsSum.Net_Cash).toFixed(
                                          2
                                        )}
                                      </td>
                                      <th className="text-nowrap" scope="row">
                                        {props.t("Cards")}
                                      </th>
                                      <td>
                                        {parseFloat(
                                          labelsSum.Total_Visa
                                        ).toFixed(2)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className="text-nowrap" scope="row">
                                        {props.t("Total Co.")}
                                      </th>
                                      <td>
                                        {parseFloat(
                                          labelsSum.Total_Companies
                                        ).toFixed(2)}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    )}
                    {!preventPrint && (
                      <TableContainer
                        columns={columns}
                        data={
                          allShiftInPeriod && allShiftInPeriod
                            ? allShiftInPeriod
                            : []
                        }
                        isGlobalFilter={false}
                        customPageSize={5}
                        allListSize={
                          ShiftInPreiod && ShiftInPreiod.Table
                            ? ShiftInPreiod.Table.length
                            : 0
                        }
                        footerData={footerData}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </CardBody>

          {!preventPrint &&
          finalTabelData &&
          finalTabelData.length > 0 &&
          !id ? (
            <div className="mt-2 d-flex justify-content-end">
              <SendAndPrintPdf
                data={finalTabelData.map(one => {
                  const date = d => {
                    const date = new Date(d)
                    const hour = date.getHours()
                    const minutes = date.getMinutes()
                    const secondes = date.getSeconds()
                    return `${hour}:${minutes}:${secondes}`
                  }
                  return {
                    ...one,
                    startTimeCopy: one.StartTime ? date(one.StartTime) : null,
                    endTimeCopy: one.Endtime ? date(one.Endtime) : null,
                  }
                })}
                extraData={{
                  Start: periodDate.dateFrom || "",
                  End: periodDate.dateTo || "",
                  Orders: parseFloat(labelsSum.Total_Orders).toFixed(2),
                  Orders_Value: parseFloat(labelsSum.Net_Orders_Value).toFixed(
                    2
                  ),
                  Net_Cash: parseFloat(labelsSum.Net_Cash).toFixed(2),
                  Cards: parseFloat(labelsSum.Total_Visa).toFixed(2),
                  Total_Companies: parseFloat(
                    labelsSum.Total_Companies
                  ).toFixed(2),
                  Items_no: finalTabelData.length || 0,
                }}
                filteredData={[
                  "No",
                  "PK_Shift_ID",
                  "StartTime",
                  "startTimeCopy",
                  "Endtime",
                  "endTimeCopy",
                  "Total_Orders",
                  "Net_Orders_Value",
                  "Net_Cash",
                  "Total_Visa",
                  "Total_Companies",
                ]}
                extraTwoColData={null}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </Row>
    </TabPane>
  )
}

FilterByDate.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(FilterByDate))
