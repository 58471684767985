import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useSelector, useDispatch } from "react-redux"
import { postPrepareArea } from "../../../store/products/prepareArea/actions"
import { toast } from "react-toastify"

const PrepareAreaModal = props => {
  const { prepareAreaModal, handlePrepareAreaToggle } = props
  const dispatch = useDispatch()

  // const [modal, setModal] = useState(false);
  const [areaName, setAreaName] = useState("")
  const [isSubmitDisable, setIsSubmitDisable] = useState(false)

  const prepareAreaAdded = useSelector(
    state => state.prepareAreas.prepareAreaAdded
  )

  const toggle = () => {
    handlePrepareAreaToggle()
  }

  useEffect(() => {
    if (Object.keys(prepareAreaAdded).length > 0) {
      setIsSubmitDisable(false)
      validation.resetForm()
      toggle()
    }
  }, [prepareAreaAdded])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      areaName: areaName || "",
    },
    validationSchema: Yup.object({
      areaName: Yup.string().required(props.t("Please Enter Area Name")),
    }),
    onSubmit: values => {
      const newArea = {
        areaName: values["areaName"],
      }
      // save new prepareArea
      try {
        dispatch(postPrepareArea(newArea))
        setIsSubmitDisable(true)
      } catch (err) {
        console.log(err)
      }
    },
  })

  return (
    <Modal isOpen={prepareAreaModal} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} tag="h4">
        {props.t("Add Area")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">{props.t("Add Area name")}</Label>
                <Input
                  name="areaName"
                  type="text"
                  placeholder={props.t("Enter Area Name")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.areaName || ""}
                  invalid={
                    validation.touched.areaName && validation.errors.areaName
                      ? true
                      : false
                  }
                />
                {validation.touched.areaName && validation.errors.areaName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.areaName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                {/* <button type="submit" className="btn btn-success save-user">
                  <i className="mdi mdi-check-circle-outline me-1" />
                  {props.t("Save")}
                </button> */}

                <button
                  type="submit"
                  disabled={isSubmitDisable}
                  className="btn btn-success save-user"
                >
                  {isSubmitDisable ? (
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  ) : (
                    <i className="mdi mdi-check-circle-outline me-1" />
                  )}
                  {props.t("Save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

PrepareAreaModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(PrepareAreaModal))
