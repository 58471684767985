import {
    GET_TBL_SAFE,
    GET_TBL_SAFE_SUCCESS,
    GET_TBL_SAFE_FAIL,
    ADD_SAFE_TRANSFER,
    ADD_SAFE_TRANSFER_SUCCESS,
    ADD_SAFE_TRANSFER_FAIL,
    UPLOAD_SAFE_TRANSFER_IMAGES,
    UPLOAD_SAFE_TRANSFER_IMAGES_SUCCESS,
    UPLOAD_SAFE_TRANSFER_IMAGES_FAIL
} from "./actionTypes";

const getTblSafe = () => ({
    type: GET_TBL_SAFE,
});

const getTblSafeSuccess = tblSafe => ({
    type: GET_TBL_SAFE_SUCCESS,
    payload: tblSafe,
});

const getTblSafeFail = error => ({
    type: GET_TBL_SAFE_FAIL,
    payload: error,
});

const addSafeTransfer = safeTransfer => ({
    type: ADD_SAFE_TRANSFER,
    payload: safeTransfer
});

const addSafeTransferSuccess = res => ({
    type: ADD_SAFE_TRANSFER_SUCCESS,
    payload: res
});

const addSafeTransferFail = error => ({
    type: ADD_SAFE_TRANSFER_FAIL,
    payload: error
});

const uploadSafeTransferImages = (safeTransfer) => ({
    type: UPLOAD_SAFE_TRANSFER_IMAGES,
    payload: safeTransfer
});

const uploadSafeTransferImagesSuccess = safeTransfer => ({
    type: UPLOAD_SAFE_TRANSFER_IMAGES_SUCCESS,
    payload: safeTransfer
});

const uploadSafeTransferImagesFail = error => ({
    type: UPLOAD_SAFE_TRANSFER_IMAGES_FAIL,
    payload: error
});

export {
    getTblSafe,
    getTblSafeSuccess,
    getTblSafeFail,
    addSafeTransfer,
    addSafeTransferSuccess,
    addSafeTransferFail,
    uploadSafeTransferImages,
    uploadSafeTransferImagesSuccess,
    uploadSafeTransferImagesFail
};