import {
  GET_ITEMS,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_FAIL,
  POST_ITEM,
  POST_ITEM_SUCCESS,
  POST_ITEM_FAIL,
  POST_ITEM_IMG_SUCCESS,
  POST_ITEM_IMG_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  loadingItems: false,
  itemIdAdded: 0,
  items: [],
  // userProfile: {},
  error: {},
}

const items = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ITEMS:
      return {
        ...state,
        loadingItems: true,
      }

    case GET_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        loadingItems: false,
      }

    case GET_ITEMS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingItems: false,
      }
    case POST_ITEM:
      return {
        ...state,
        itemIdAdded: 0,
      }

    case POST_ITEM_SUCCESS:
      return {
        ...state,
        // items: [...state.items, action.payload],
        items: [...state.items],
        itemIdAdded: action.payload,
      }

    case POST_ITEM_FAIL:
      return {
        ...state,
        error: { ...state, error: action.payload },
        
      }

    case POST_ITEM_IMG_SUCCESS:
      return {
        ...state,
        // items: [...state.items, action.payload],
        items: [...state.items],
      }

    case POST_ITEM_IMG_FAIL:
      return {
        ...state,
        error: { ...state, error: action.payload },
      }
    default:
      return state
  }
}

export default items
