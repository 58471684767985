import React, { useState, useEffect } from "react"
import DateFromTo from "pages/Reports/shared/DateFromTo"
import FilterByIdFromTo from "pages/Reports/SafeBalance/Filter/FilterByIdFromTo"
import { Row } from "reactstrap"

const FilterComp = ({ bearerFilterHandler }) => {
  const [filter, setFilter] = useState({
    dateFrom: "",
    dateTo: "",
    idFrom: 0,
    idTo: 0,
  })

  useEffect(() => {
    bearerFilterHandler(filter)
  }, [filter])

  const getDateFromToHandler = date => {
    const { dateFrom, dateTo } = date
    if (dateFrom === "" || dateTo === "") {
      setFilter(prevState => ({
        ...prevState,
        dateFrom: "",
        dateTo: "",
      }))
    } else {
      setFilter(prevState => ({
        ...prevState,
        dateFrom: dateFrom,
        dateTo: dateTo,
      }))
    }
  }

  const getIdFromToHandler = order => {
    const { idFrom, idTo } = order
    setFilter(prevState => ({
      ...prevState,
      idFrom: idFrom === "" ? 0 : +idFrom,
      idTo: idTo === "" ? 0 : +idTo,
    }))
  }

  return (
    <Row className="row flex-wrap row-cols-auto gap-2 align-items-center">
      <DateFromTo getDateFromTo={getDateFromToHandler} returnAllActions />
      <FilterByIdFromTo getIdFromTo={getIdFromToHandler} returnAllActions />
    </Row>
  )
}

export default FilterComp
