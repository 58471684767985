import {
  GET_REVENUE_INOUT,
  GET_REVENUE_INOUT_SUCCESS,
  GET_REVENUE_INOUT_FAIL,

  GET_DASHBOARD_DININ,
  GET_DASHBOARD_DININ_SUCCESS,
  GET_DASHBOARD_DININ_FAIL,

  GET_STATISTICS_VALUES,
  GET_STATISTICS_VALUES_SUCCESS,
  GET_STATISTICS_VALUES_FAIL,

  GET_TIMELINE,
  GET_TIMELINE_SUCCESS,
  GET_TIMELINE_FAIL
} from "./actionTypes"




export const getRevenueInOut = (params) => ({
  type: GET_REVENUE_INOUT,
  payload: params
})

export const getRevenueInOutSuccess = data => ({
  type: GET_REVENUE_INOUT_SUCCESS,
  payload: data,
})
export const getRevenueInOutFail = err => ({
  type: GET_REVENUE_INOUT_FAIL,
  payload: err,
})

export const getDashboardDineIn = () => ({
  type: GET_DASHBOARD_DININ
})

export const getDashboardDineInSuccess = (data) => ({
  type: GET_DASHBOARD_DININ_SUCCESS,
  payload: data
})

export const getDashboardDineInFail = () => ({
  type: GET_DASHBOARD_DININ_FAIL,
  payload: data
})



export const getStatistics = () => ({
  type: GET_STATISTICS_VALUES
})
export const getStatisticsSuccess = data => ({
  type: GET_STATISTICS_VALUES_SUCCESS,
  payload: data
})

export const getStatisticsFail = err => ({
  type: GET_STATISTICS_VALUES_FAIL,
  payload: err
})

export const getTimeline = params => ({
  type: GET_TIMELINE,
  payload: params
})
export const getTimelineSuccess = data => ({
  type: GET_TIMELINE_SUCCESS,
  payload: data
})
export const getTimelineFail = err => ({
  type: GET_TIMELINE_FAIL,
  payload: err
})
