import {
    GET_COMPONENTS,
    GET_COMPONENTS_SUCCESS,
    GET_COMPONENTS_FAIL,

    POST_COMPONENT,
    POST_COMPONENT_SUCCESS,
    POST_COMPONENT_FAIL,
  } from "./actionTypes"
  
  // Get material actions
  export const getComponents = () => ({
    type: GET_COMPONENTS,
  })
  
  export const getComponentsSuccess = components => ({
    type: GET_COMPONENTS_SUCCESS,
    payload: components,
  })
  
  export const getComponentsFail = err => ({
    type: GET_COMPONENTS_FAIL,
    payload: err,
  })


  // Post New Component action

  export const postComponent = cat => ({
    type: POST_COMPONENT,
    payload: cat,
  })
  
  export const postComponentSuccess = component => ({
    type: POST_COMPONENT_SUCCESS,
    payload: component,
  })
  
  export const postComponentFail = err => ({
    type: POST_COMPONENT_FAIL,
    payload: err,
  })
  