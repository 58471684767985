import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_COMPONENTS, POST_COMPONENT } from "./actionTypes";

import {
  getComponentsSuccess,
  getComponentsFail,
  postComponentSuccess,
  postComponentFail,
} from "./actions";

import { postMaterialSuccess, getMaterials } from "../materials/actions";

import {
  getMaterialComponents,
  postComponent,
} from "../../../helpers/backend_helper";
import { toast } from "react-toastify";
import i18n from "i18n";

function* fetchComponents() {
  try {
    const response = yield call(getMaterialComponents);
    yield put(getComponentsSuccess(response.rs.data));
  } catch (err) {
    yield put(getComponentsFail(err));
  }
}

function* postNewComponent(comp) {
  try {
    const response = yield call(postComponent, comp.payload);
    if (response > 0) {
      toast.dismiss();
      toast.success(i18n.t("Component added successfully"));
      yield put(postComponentSuccess(response));
      yield put(getMaterials());
      yield* fetchComponents();
    }
  } catch (err) {
    toast.dismiss();
    toast.error(i18n.t("Something Error"));
    yield put(postComponentFail(err));
  }
}

function* componentSaga() {
  yield takeEvery(GET_COMPONENTS, fetchComponents);
  yield takeEvery(POST_COMPONENT, postNewComponent);
}

export default componentSaga;
