import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import Select from "react-select"

import { useSelector, useDispatch } from "react-redux"
import { getTblEmp } from "store/actions"
import { selectInputBgColors, layoutModeTypes } from "constants/layout"

const SelectEmployee = props => {
  const [empData, setEmpData] = useState([])
  const [currentEmp, setcurrentEmp] = useState({})
  const [currentEmpId, setcurrentEmpId] = useState("")

  const dispatch = useDispatch()

  const layout = useSelector(state => state.Layout)
  const reports = useSelector(state => state.Reports)
  const { layoutModeType } = layout
  const { getTblEmp: tblEmp } = reports

  useEffect(() => {
    dispatch(getTblEmp(""))
  }, [])

  useEffect(() => {
    if (!tblEmp || !tblEmp.length) return
    const newEmpData = tblEmp.map(one => ({
      value: one.empName,
      label: one.empName,
      pkEmpId: one.pkEmpId,
    }))
    newEmpData.unshift({ value: "Select", label: "Select", pkEmpId: "Select" })
    setcurrentEmp(newEmpData[0])
    setEmpData(newEmpData)
  }, [tblEmp])

  // send currentEmpId to parent
  useEffect(() => {
    const currentEmp =
      empData.length > 0
        ? empData.find(one => one.pkEmpId === currentEmpId).label
        : ""
    props.onChangeEmpId(currentEmpId, currentEmp)
  }, [currentEmpId])

  return (
    <div className="row">
      <div className="col-md-auto mx-3 mx-lg-0">
        <div className="form-group row px-0 align-items-center">
          <label className="col-auto col-form-label px-0">
            {props.t("Employee")}
          </label>
          <div className="col-auto" style={{ width: "220px" }}>
            <Select
              value={currentEmp}
              onChange={selectedOption => {
                setcurrentEmp(selectedOption)
                setcurrentEmpId(selectedOption.pkEmpId)
              }}
              options={empData}
              className="select2-selection form-control p-0 border-0"
              styles={{
                menu: base => ({
                  ...base,
                  backgroundColor:
                    layoutModeType === layoutModeTypes.LIGHT
                      ? selectInputBgColors.light
                      : selectInputBgColors.dark,
                }),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

SelectEmployee.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SelectEmployee))
