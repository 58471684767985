import React, { useState, useEffect } from "react"

import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import Select from "react-select"
import { useFormik } from "formik"
import * as Yup from "yup"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import { selectInputBgColors, layoutModeTypes } from "constants/layout"
import {
  getMaterials,
  getOneComponent,
  getTblStore,
  getStockBalanceFilteredByMaterial,
} from "store/actions"

const ComponentMaterials = props => {
  const dispatch = useDispatch()

  const reports = useSelector(state => state.Reports)
  const {
    getTblMaterial: getTblMaterialData,
    getStockBalanceFilteredByMaterial: storeListForMaterial,
    loadingStockBalanceFilteredByMaterial: loading,
  } = reports

  const { selectedComponentDetails, t, handleMaterialsToSend } = props
  const [selectStore, setSelectStore] = useState([])
  const [storeListAvailable, setStoreListAvailable] = useState([])
  const [compMaterialsList, setCompMaterialsList] = useState(
    selectedComponentDetails
  )
  const [storeList, setStoreList] = useState([])
  const [err, setErr] = useState([])
  const [submitDisabled, setSubmitDisabled] = useState(false)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectStore: selectStore || [],
    },
    validationSchema: Yup.object({
      selectStore: Yup.array().of(
        Yup.number()
          .required(props.t("Please Select Store"))
          .test("selectStore", "Please Select Store", value => value > 0)
      ),
    }),
    onSubmit: values => {
      resetForm()
    },
  })

  // set list of store with number of row
  useEffect(() => {
    setCompMaterialsList(selectedComponentDetails)
    let storeListDemo = []
    let errDemo = []
    selectedComponentDetails.length > 0 &&
      selectedComponentDetails.map(() => storeListDemo.push(0))
    selectedComponentDetails.length > 0 &&
      selectedComponentDetails.map(() => errDemo.push(false))
    setStoreListAvailable(storeListDemo)
    setErr(errDemo)
    setSelectStore(storeListDemo)
  }, [selectedComponentDetails])

  useEffect(() => {
    const fetchStoreMaterial = async () => {
      selectedComponentDetails.length > 0 &&
        (await Promise.all(
          selectedComponentDetails.map(shift =>
            dispatch(
              getStockBalanceFilteredByMaterial(
                `fk_material_id=${shift.FK_MaterialID}`
              )
            )
          )
        ))
    }
    const timer = setTimeout(() => {
      fetchStoreMaterial()
    }, 500)
    return () => {
      clearTimeout(timer)
    }
  }, [selectedComponentDetails])

  // detect each change in store for storeListForMaterial
  useEffect(() => {
    let rowIndex
    // set retrieve store list for each material with same index
    storeListForMaterial.length > 0 &&
      selectedComponentDetails.length > 0 &&
      (rowIndex = selectedComponentDetails.findIndex(
        comp => comp.FK_MaterialID === storeListForMaterial[0].PK_Material_ID
      ))
    //  set All Available store for Each material
    if (rowIndex > -1) {
      setStoreListAvailable(prev => {
        const newValues = [...prev]
        newValues[rowIndex] = [...storeListForMaterial]
        return newValues
      })
    }
  }, [storeListForMaterial])

  // Detect Each store list available change and remove store that don't have enough qty

  useEffect(() => {
    const storeListAvailableDemo = [...storeListAvailable]
    let newListStore = []
    if (storeListAvailableDemo.length === 0) return
    // Filter store list to that only have enough qty and not null
    for (let i = 0; i < storeListAvailableDemo.length; i++) {
      const newList =
        storeListAvailableDemo[i].length > 0
          ? storeListAvailableDemo[i].filter(
              item =>
                item.PK_Store_ID !== null &&
                item.Qty >= selectedComponentDetails[i].Qty
            )
          : 0
      newListStore.push(newList)
    }
    setStoreList(newListStore)
  }, [storeListAvailable])

  const handleSelectRowStore = (value, index) => {
    setSelectStore(prev => {
      const newValues = [...prev]
      newValues[index] = Number(value)
      return newValues
    })
    // compMaterialsList[index].store = value
    setCompMaterialsList(prev => {
      const newValues = [...prev]
      newValues[index] = { ...newValues[index], fromStoreId: Number(value) }
      return newValues
    })
  }

  useEffect(() => {
    // selectStore.map((item) => {
    //   item === 0 ? setSubmitDisabled(true) : setSubmitDisabled(false)
    // })
    for (let i of selectStore) {
      if (i === 0) {
        setSubmitDisabled(true)
        return
      }
    }
    setSubmitDisabled(false)
  }, [compMaterialsList])

  const handleSelectStoreErr = (e, index) => {
    e === ""
      ? setErr(prev => {
          const newValues = [...prev]
          newValues[index] = true
          return newValues
        })
      : setErr(prev => {
          const newValues = [...prev]
          newValues[index] = false
          return newValues
        })
  }
  const handleMaterialsList = () => {
    handleMaterialsToSend(compMaterialsList)
  }

  return (
    <div className="mt-4">
      {selectedComponentDetails.length > 0 &&
        selectedComponentDetails.map((material, index) => (
          <Row key={index}>
            <Col sm={4} className="mb-3">
            {index === 0 && <Label>{props.t("Material")}<span className="text-danger">*</span></Label>}
              <Input
                name="materialName"
                type="select"
                onChange={e => e.preventDefault()}
                value={material.FK_MaterialID}
                readOnly
                disabled
              >
                <option value={material.FK_MaterialID}>
                  {material.MaterialName}
                </option>
              </Input>
            </Col>

            <Col sm={2} className="mb-3">
              {index === 0 && <Label className="form-label">{props.t("Unit")}</Label>}
              <Input
                disabled
                type="text"
                id="unit"
                className="form-control"
                value={material.Unit ? material.Unit : ""}
                onChange={e => e.preventDefault}
              />
            </Col>

            <Col sm={2} className="mb-3">
              {index === 0 && <Label className="form-label">
                {props.t("Quantity")}<span className="text-danger">*</span>
              </Label>}
              <Input
                type="number"
                id="rowMaterialQty"
                min="0"
                className="form-control"
                value={parseFloat(material.Qty.toFixed(2))}
                disabled
                onChange={e => e.preventDefault()}
              />
            </Col>
            <Col sm={4}>
              {index === 0 && <Label className="form-label">
                {props.t("From Store")}
                <span className="text-danger">*</span>
              </Label>}
              <Input
                name={`selectStore[${index}]`}
                type="select"
                onChange={e => {
                  handleSelectRowStore(e.target.value, index)
                }}
                onBlur={e => handleSelectStoreErr(e.target.value, index)}
                value={validation.values.selectStore[index] || ""}
                invalid={
                  validation.touched.selectStore &&
                  validation.errors.selectStore
                    ? true
                    : false
                }
                className={err[index] ? "is-invalid" : ""}
              >
                <option value="" disabled>
                  {props.t("Select store")}
                </option>
                {storeList[index] &&
                storeList[index].length > 0 &&
                storeList[index] !== 0 ? (
                  storeList[index].map((store, index) => (
                    <option key={index} value={store.PK_Store_ID}>
                      {store.StoreName}
                    </option>
                  ))
                ) : (
                  <option disabled className="fst-italic">
                    {props.t("Quantity not available")}..
                  </option>
                )}
              </Input>
              {err[index] && (
                <div className="mb-2">
                  <span className="text-danger">
                    {props.t("Please select store")}
                  </span>
                </div>
              )}
            </Col>
          </Row>
        ))}

      {selectedComponentDetails.length > 0 && (
        <div>
          <hr style={{ borderTop: "1px solid #d5d5d5" }} />
          <div className="text-end">
            <Button
              disabled={submitDisabled}
              type="button"
              onClick={handleMaterialsList}
              style={
                !submitDisabled
                  ? {
                      backgroundColor: "#FB940E",
                      border: "none",
                    }
                  : { backgroundColor: "#fcb961" }
              }
            >
              {props.t("Submit")}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

ComponentMaterials.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ComponentMaterials))
