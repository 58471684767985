import {
    GET_TBL_SAFE_SUCCESS,
    GET_TBL_SAFE_FAIL,
    ADD_SAFE_TRANSFER_SUCCESS,
    ADD_SAFE_TRANSFER_FAIL,
    UPLOAD_SAFE_TRANSFER_IMAGES_SUCCESS,
    UPLOAD_SAFE_TRANSFER_IMAGES_FAIL
} from "./actionTypes";

const INIT_STATE = {
    safeList: [],
    safeTransfer: {},
    uploadSafeTransfer: {},
    error: {},
};

const safeTransfer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TBL_SAFE_SUCCESS:
            return {
                ...state,
                safeList: action.payload,
            };

        case ADD_SAFE_TRANSFER_SUCCESS:
            return {
                ...state,
                safeTransfer: action.payload,
            };

        case UPLOAD_SAFE_TRANSFER_IMAGES_SUCCESS:
            return {
                ...state,
                uploadSafeTransfer: action.payload,
            };

        case GET_TBL_SAFE_FAIL:
        case ADD_SAFE_TRANSFER_FAIL:
        case UPLOAD_SAFE_TRANSFER_IMAGES_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    };
};

export default safeTransfer;