import React from 'react';
import classes from "./index.module.scss";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";

import { useDispatch, useSelector } from 'react-redux';
import { setLogo } from 'store/actions';

const UploadLogo = (props) => {
    const dispatch = useDispatch();
    const { logo } = useSelector(state => state.businessSettings);

    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
            const dataURL = reader.result;
            dispatch(setLogo(dataURL));
        };

        reader.readAsDataURL(file);
    };

    return (
        <div className="pb-4 pt-3">
            <span className="mb-2 d-block">{props.t("Change Logo")}</span>
            <div className={`px-4 ${classes.uploadLogo_container}`}>
                <input id="logo" style={{ display: "none" }} type="file" accept="image/*" onChange={handleLogoChange} />
                <div className={classes.uploadLogo_img_container}>
                    {logo && <img
                        className={classes.uploadLogo_img}
                        src={logo}
                        alt="Logo"
                    />
                    }
                <label
                    htmlFor="logo"
                    className={classes.uploadLogo_icon_container}
                >
                    <i className={`bx bxs-camera ${classes.uploadLogo_icon}`}></i>
                </label>
                </div>
            </div>
        </div >
    );
};

UploadLogo.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(UploadLogo));
