import {
  POST_COMP_MANUFACTURE,
  POST_COMP_MANUFACTURE_SUCCESS,
  POST_COMP_MANUFACTURE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  postLoading: false,
  compAdded: "",
  error: {},
}

const compManufacture = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_COMP_MANUFACTURE:
      return {
        ...state,
        postLoading: true,
      }

    case POST_COMP_MANUFACTURE_SUCCESS:
      return {
        ...state,
        postLoading: false,
        compAdded: action.payload,
      }

    case POST_COMP_MANUFACTURE_FAIL:
      return {
        ...state,
        error: { ...state, error: action.payload, postLoading: false },
      }

    default:
      return state
  }
}

export default compManufacture
