import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

import { useFormik } from "formik"
import * as Yup from "yup"
import {
  Button,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"

const InsufficientFundModel = ({
  isOpen,
  toggle,
  purchaseValue,
  safeList,
  selectedSafe,
  t,
  handleSelectSafe,
}) => {
  const [availableSafe, setAvailableSafe] = useState(false)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectSafe: selectedSafe || "",
    },
    validationSchema: Yup.object({
      selectSafe: Yup.string().required(t("Field is required")),
    }),
  })

  const availableSafes = useMemo(() => {
    return safeList.filter(
      one =>
        (one.safe_type.toLowerCase() === "visa" ||
          one.safe_type.toLowerCase() === "company") &&
        one.balance >= purchaseValue
    )
  }, [safeList, purchaseValue])

  useEffect(() => {
    setAvailableSafe(
      !!availableSafes.find(one => +one.pkSafeId === +selectedSafe)
    )
  }, [selectedSafe, availableSafes])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <div className="modal-content">
        <ModalHeader toggle={toggle}>{t("insufficientFund")}</ModalHeader>
        <ModalBody>
          {availableSafes.length && !availableSafe ? (
            <p className="text-danger fw-bold">{t("selectSafe")}</p>
          ) : null}
          <div className="mb-3">
            <Label>{t("Safe")}</Label>
            <Input
              name="selectSafe"
              type="select"
              onChange={e => {
                validation.handleChange
                handleSelectSafe(e)
              }}
              onBlur={validation.handleBlur}
              value={validation.values.selectSafe || ""}
              invalid={
                validation.touched.selectSafe && validation.errors.selectSafe
                  ? true
                  : false
              }
            >
              <option value="">{t("Select Safe Name")}</option>
              {availableSafes.map((safe, index) => (
                <option key={index} value={safe.pkSafeId}>
                  {safe.safeName}
                </option>
              ))}
            </Input>
            {validation.touched.selectStore && validation.errors.selectSafe ? (
              <FormFeedback type="invalid">
                {validation.errors.selectSafe}
              </FormFeedback>
            ) : null}
          </div>

          {!availableSafes.length ? (
            <p className="text-danger fw-bold">{t("canNotSelectSafe")}</p>
          ) : null}

          {/* User provide safe */}
          {availableSafe ? (
            <p className="text-success fw-bold">
              {t("safeSelectedSuccessfully")}
            </p>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="primary" onClick={toggle}>
            <i className="bx bx-check-circle label-icon"></i> {t("Close")}
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

InsufficientFundModel.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(InsufficientFundModel))
