import { XAxis } from "recharts"

// const counterSectionDummyData = [
//   {
//     count: "279",
//     title: "Total Orders",
//     percentage: 4,
//     days: "30 days",
//     status: "increase",
//     iconClass: "bx bx-notification",
//   },
//   {
//     count: "56",
//     title: "Total Menus",
//     percentage: 4,
//     days: "30 days",
//     status: "increase",
//     iconClass: "bx bxs-coffee-alt",
//   },
//   {
//     count: "126k",
//     title: "Total Companies",
//     percentage: 26,
//     days: "30 days",
//     status: "decrease",
//     iconClass: "bx bx-dollar",
//   },

//   {
//     count: "65",
//     title: "Revenue Average",
//     percentage: 4,
//     days: "30 days",
//     status: "increase",
//     iconClass: "bx bxs-group",
//   },
// ]

const todayDummyData = [
  {
    data: [
      44, 55, 41, 67, 22, 43, 36, 52, 24, 18, 36, 48, 44, 55, 41, 67, 22, 43,
      36, 52, 24, 18, 36, 48
    ],
  },
]

const monthDummyData = [
  {
    data: [
      34, 55, 21, 77, 32, 63, 86, 42, 34, 18, 16, 41, 34, 55, 21, 77, 32, 63,
      86, 42, 34, 18, 16, 41,
    ],
  },
]

const weekDummyData = [
  {
    data: [
      14, 52, 11, 57, 22, 33, 31, 22, 64, 14, 32, 68, 14, 52, 11, 57, 22, 33,
      31, 22, 64, 14, 32, 68, 
    ],
  },
]

const dummyCategoriesChart = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
]

const orderSummaryDountTodayDummyDataDummyData = [
  {
    title: "canceled",
    percentage: 7.5,
    value: 14,
    color: "#3E4954",
    bg: "dark",
  },
  {
    title: "Open Orders",
    percentage: 27.5,
    value: 55,
    color: "#fb940e",
    bg: "primary",
  },
  {
    title: "Paid Orders",
    percentage: 65,
    value: 130,
    color: "#34c38f",
    bg: "success",
  },
]
const orderSummaryDountWeekDummyDataDummyData = [
  {
    title: "canceled",
    percentage: 10,
    value: 20,
    color: "#3E4954",
  },
  {
    title: "On Delivery",
    percentage: 40,
    value: 80,
    color: "#FF6D4C",
  },
  {
    title: "Delivery",
    percentage: 50,
    value: 100,
    color: "#2BC155",
  },
]
const orderSummaryDountMonthDummyDataDummyData = [
  {
    title: "canceled",
    percentage: 25,
    value: 100,
    color: "#3E4954",
  },
  {
    title: "On Delivery",
    percentage: 40,
    value: 160,
    color: "#FF6D4C",
  },
  {
    title: "Delivery",
    percentage: 35,
    value: 140,
    color: "#2BC155",
  },
]

const spineAreaColors = {
  incomeColor: "#34c38f",
  expenseColor: "#FB940E",
}

const SpineAreaTodayDummyDataDummyData = {
  yaxisData: [
    {
      name: "Income",
      color: spineAreaColors.incomeColor,
      data: [34, 40, 28, 52, 42, 109, 100, 40, 28],
    },
    {
      name: "Outcome",
      color: spineAreaColors.expenseColor,
      data: [32, 60, 34, 46, 34, 52, 41, 60, 34, 46, 34, 52],
    },
  ],
  xaxisData: [
    "2023-01-19T00:00:00",
    "2023-02-19T03:30:00",
    "2023-03-19T06:30:00",
    "2023-04-19T07:30:00",
    "2023-05-19T08:30:00",
    "2023-06-19T10:30:00",
    "2023-07-19T11:30:00",
    "2023-08-19T13:30:00",
    "2023-09-19T14:30:00",
    "2023-10-19T16:30:00",
    "2023-11-19T18:30:00",
    "2023-12-19T22:30:00",
    "2023-10-19T23:30:00",
    "2023-11-19T23:35:00",
    "2023-12-19T23:40:00",
  ],
}

const SpineAreaMonthDummyDataDummyData = {
  yaxisData: [
    {
      name: "Income",
      color: spineAreaColors.incomeColor,
      data: [64, 20, 56, 35, 84, 218, 80, 80, 84, 100, 200, 300],
    },
    {
      name: "Outcome",
      color: spineAreaColors.expenseColor,
      data: [50, 2, 105, 200, 10, 50, 70, 80, 20, 75, 95, 85],
    },
  ],
  xaxisData: [
    "2022-01-19T00:00:00",
    "2022-02-19T03:30:00",
    "2022-03-19T06:30:00",
    "2022-04-19T07:30:00",
    "2022-05-19T08:30:00",
    "2022-06-19T10:30:00",
    "2022-07-19T11:30:00",
    "2022-08-19T13:30:00",
    "2022-09-19T14:30:00",
    "2022-10-19T16:30:00",
    "2022-11-19T18:30:00",
    "2022-12-19T22:30:00",
  ],
}

const SpineAreaWeekDummyDataDummyData = {
  yaxisData: [
    {
      name: "Income",
      color: spineAreaColors.incomeColor,
      data: [50, 80, 50, 100, 150, 200, 150, 60, 50, 30, 40, 182],
    },
    {
      name: "Outcome",
      color: spineAreaColors.expenseColor,
      data: [20, 57, 21, 38, 48, 71, 56, 80, 14, 68, 75, 15],
    },
  ],
  xaxisData: [
    "2018-01-19T00:00:00",
    "2018-02-19T01:30:00",
    "2018-03-19T02:30:00",
    "2018-04-19T03:30:00",
    "2018-05-19T04:30:00",
    "2018-06-19T05:30:00",
    "2018-07-19T06:30:00",
    "2018-08-19T06:30:00",
    "2018-09-19T06:30:00",
    "2018-10-19T06:30:00",
    "2018-11-19T06:30:00",
    "2018-12-19T06:30:00",
  ],
}

const monthData = {
  incomeYaxis: [1, 2, 3, 4],
  outComeYaxis: [5, 6, 7, 8],
  xaxisData: [
    "2022-01-19T00:00:00",
    "2022-02-19T03:30:00",
    "2022-03-19T06:30:00",
    "2022-04-19T07:30:00",
  ],
}

export {
  // counterSectionDummyData,
  monthDummyData,
  weekDummyData,
  todayDummyData,
  dummyCategoriesChart,
  orderSummaryDountMonthDummyDataDummyData,
  orderSummaryDountTodayDummyDataDummyData,
  orderSummaryDountWeekDummyDataDummyData,
  SpineAreaTodayDummyDataDummyData,
  SpineAreaMonthDummyDataDummyData,
  SpineAreaWeekDummyDataDummyData,
  spineAreaColors,
}
