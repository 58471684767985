import { GET_ITEM_INGREDIANTS, GET_ITEM_INGREDIANTS_SUCCESS, GET_ITEM_INGREDIANTS_FAIL } from "./actionTypes"

export const getItemIngrediants = itemId => ({
  type: GET_ITEM_INGREDIANTS,
  itemId
})

export const getItemIngrediantSuccess = items => ({
  type: GET_ITEM_INGREDIANTS_SUCCESS,
  payload: items,
})

export const getItemIngrediantsFail = items => ({
  type: GET_ITEM_INGREDIANTS_FAIL,
  payload: items,
})
