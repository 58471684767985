import {
  ADD_EXPENSE_SUCCESS,
  ADD_EXPENSE_FAIL,
  UPLOAD_EXPENSE_IMAGES_SUCCESS,
  UPLOAD_EXPENSE_IMAGES_FAIL,
  ADD_EXPENSE_CAT,
  ADD_EXPENSE_CAT_SUCCESS,
  ADD_EXPENSE_CAT_FAIL,
  GET_EXPENSE_CAT,
  GET_EXPENSE_CAT_SUCCESS,
  GET_EXPENSE_CAT_FAIL,
} from "store/expense/actionTypes"
import { error } from "toastr"

const INIT_STATE = {
  expenseId: "",
  uploadExpense: {},
  error: {},
  expenseCatLoading: false,
  expenseCatAdded: {},
  getExpenseCatLoading: false,
  allExpensesCat: [],
}

const expenses = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_EXPENSE_SUCCESS:
      return {
        ...state,
        expenseId: action.payload,
      }

    case UPLOAD_EXPENSE_IMAGES_SUCCESS:
      return {
        ...state,
        uploadExpense: action.payload,
      }

    case ADD_EXPENSE_CAT:
      return {
        ...state,
        expenseCatLoading: true,
        expenseCatAdded: {}
      }

    case ADD_EXPENSE_CAT_SUCCESS:
      return {
        ...state,
        allExpensesCat: [...state.allExpensesCat, action.payload],
        expenseCatLoading: false,
        expenseCatAdded: action.payload,
      }

    case GET_EXPENSE_CAT:
      return {
        ...state,
        getExpenseCatLoading: true,
        expenseCatAdded: {}
      }

    case GET_EXPENSE_CAT_SUCCESS:
      return {
        ...state,
        getExpenseCatLoading: false,
        allExpensesCat: action.payload,
      }

    case GET_EXPENSE_CAT_FAIL:
      return {
        ...state,
        getExpenseCatLoading: false,
        error: action.payload,
      }

    case ADD_EXPENSE_FAIL:
    case UPLOAD_EXPENSE_IMAGES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_EXPENSE_CAT_FAIL:
      return {
        ...state,
        error: action.payload,
        expenseCatLoading: false,
      }

    default:
      return state
  }
}

export default expenses
