import {
  POST_STOCKTRANSFER,
  POST_STOCKTRANSFER_SUCCESS,
  POST_STOCKTRANSFER_FAIL,
  GET_STOCKTRANSFER_CHECKSTOREQTY,
  GET_STOCKTRANSFER_CHECKSTOREQTY_SUCCESS,
  GET_STOCKTRANSFER_CHECKSTOREQTY_FAIL,
  GET_STOCKTRANSFER,
  GET_STOCKTRANSFER_SUCCESS,
  GET_STOCKTRANSFER_FAIL,
  GET_STOCKTRANSFER_ID,
  GET_STOCKTRANSFER_ID_SUCCESS,
  GET_STOCKTRANSFER_ID_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  stockTransferAdded: "",
  stockTransferAddedLoading: false,
  stockTransferError: {},
  stockTransferCheckQtyRes: "",
  stockTransferCheckQtyLoading: false,
  stockTransferCheckQtyErr: {},
  stockTransferData: [],
  getStockTransferLoading: false,
  stockTransferErr: {},
  stockTransferDetails: [],
  stockTransferDetailsLoading: false,
  stockTransferDetailsErr: {},
}

const stockTransfer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_STOCKTRANSFER:
      return {
        ...state,
        stockTransferAddedLoading: true,
        stockTransferAdded: "",
      }

    case POST_STOCKTRANSFER_SUCCESS:
      return {
        ...state,
        stockTransferAdded: action.payload,
        stockTransferAddedLoading: false,
      }

    case POST_STOCKTRANSFER_FAIL:
      return {
        ...state,
        stockTransferError: action.payload,
        stockTransferAddedLoading: false,
        stockTransferAdded: "",
      }

    case GET_STOCKTRANSFER_CHECKSTOREQTY:
      return {
        ...state,
        stockTransferCheckQtyRes: "",
        stockTransferCheckQtyLoading: true,
      }
    case GET_STOCKTRANSFER_CHECKSTOREQTY_SUCCESS:
      return {
        ...state,
        stockTransferCheckQtyRes: action.payload,
        stockTransferCheckQtyLoading: false,
      }

    case GET_STOCKTRANSFER_CHECKSTOREQTY_FAIL:
      return {
        ...state,
        stockTransferCheckQtyRes: "",
        stockTransferCheckQtyLoading: false,
        stockTransferCheckQtyErr: action.payload,
      }

    case GET_STOCKTRANSFER:
      return {
        ...state,
        stockTransferData: [],
        getStockTransferLoading: true,
        stockTransferErr: {},
      }

    case GET_STOCKTRANSFER_SUCCESS:
      return {
        ...state,
        stockTransferData: action.payload,
        getStockTransferLoading: false,
        stockTransferErr: {},
      }

    case GET_STOCKTRANSFER_FAIL:
      return {
        ...state,
        stockTransferData: [],
        getStockTransferLoading: false,
        stockTransferErr: action.payload,
      }

    case GET_STOCKTRANSFER_ID:
      return {
        ...state,
        stockTransferDetails: [],
        stockTransferDetailsLoading: true,
        stockTransferDetailsErr: {},
      }

    case GET_STOCKTRANSFER_ID_SUCCESS:
      return {
        ...state,
        stockTransferDetails: action.payload,
        stockTransferDetailsLoading: false,
        stockTransferDetailsErr: {},
      }

      case GET_STOCKTRANSFER_ID_FAIL:
        return {
          ...state,
          stockTransferDetails: [],
          stockTransferDetailsLoading: false,
          stockTransferDetailsErr: action.payload
        }

    default:
      return state
  }
}

export default stockTransfer
