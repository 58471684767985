import {
  POST_COMP_MANUFACTURE,
  POST_COMP_MANUFACTURE_SUCCESS,
  POST_COMP_MANUFACTURE_FAIL,
} from "./actionTypes"

export const postCompManufacture = data => ({
  type: POST_COMP_MANUFACTURE,
  payload: data,
})

export const postCompManufactureSuccess = data => ({
  type: POST_COMP_MANUFACTURE_SUCCESS,
  payload: data,
})
export const postCompManufactureFail = err => ({
    type: POST_COMP_MANUFACTURE_FAIL,
    payload: err,
  })
  