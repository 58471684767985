import React from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getEmails, sendEmail } from "store/end-shift/actions"
import { preparePdf } from "../helpers"

const SendPdf = props => {
  const dispatch = useDispatch()
  const { data, filteredData, fileName, extraData, logo, extraTwoColData } =
    props

  const shiftDetails = useSelector(state => state.shiftDetails)
  const { emails, loadingSendEmail } = shiftDetails

  React.useEffect(() => {
    if (emails && emails.length === 0) {
      dispatch(getEmails())
    }
  }, [emails])

  const sendPdfHandler = () => {
    const doc = preparePdf(
      data,
      filteredData,
      fileName,
      extraData,
      logo,
      extraTwoColData
    )

    // emails
    const formData = new FormData()
    formData.append("Subject", fileName)
    emails.forEach((email, i) => {
      formData.append(`emailToRequest.mailToRpt[${i}].MailTo`, email.MailTo)
    })

    // Convert the PDF to a Blob
    const pdfBlob = doc.output("blob")
    formData.append("file", pdfBlob, `${fileName}.pdf`)
    dispatch(sendEmail(formData))
  }

  return (
    <button
      type="button"
      className="btn btn-primary btn-label mx-1"
      onClick={sendPdfHandler}
      disabled={loadingSendEmail}
    >
      {loadingSendEmail ? (
        <>
          <i className="bx bx-loader-circle label-icon"></i>{" "}
          {props.t("Sending")}
        </>
      ) : (
        <>
          <i className="bx bxs-send label-icon"></i> {props.t("Send Pdf")}
        </>
      )}
    </button>
  )
}

SendPdf.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SendPdf))
