import React, { useMemo, useState, useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import TableContainer from "../../../components/Common/TableContainer"

import {
  Col,
  Row,
  Card,
  CardBody,
  Spinner,
  Badge,
  Button,
  UncontrolledTooltip
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"

import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { getSupplierAccount } from "store/reports/actions"
import { tblSupplier } from "store/supplier/actions"
import DateFromTo from "../shared/DateFromTo"
import { convertDate, convertParametersToString } from "../helpers"
import SendAndPrintPdf from "pages/Reports/shared/pdf/components/SendAndPrintPdf"
import { selectInputBgColors, layoutModeTypes } from "constants/layout"
import PayModal from "./PayModal"
import { Link } from "react-router-dom"
import PayDetailsModal from "./PayDetailsModal"

const SupplierAccount = props => {
  document.title = "Supplier Account | Reports"
  const dispatch = useDispatch()
  const [date, setDate] = useState(null)
  const [supplierData, setSupplierData] = useState([])
  const [currentSupplier, setcurrentSupplier] = useState({})
  const [currentSupplierId, setcurrentSupplierId] = useState("")
  const [modifiedSupplierAccountData, setModifiedSupplierAccountData] =
    useState([])
  const [payData, setPayData] = useState({})
  const [payModal, setPaymodal] = useState(false)
  const [dataFetched, setDataFetched] = useState(false)
  const [footerSum, setFooterSum] = useState({
    TotalPrice: 0,
    PaidUp: 0,
    Owned: 0,
  })
  const [payDetailsData, setPayDetailsData] = useState({})
  const [payDetailsModal, setPayDetailsModal] = useState(false)

  const layout = useSelector(state => state.Layout)
  const suppliers = useSelector(state => state.suppliers)
  const reports = useSelector(state => state.Reports)
  const { layoutModeType } = layout
  const { tblSupplier: tblSupplierData } = suppliers
  const {
    getSupplierAccount: getSupplierAccountData,
    loadingSupplierAccount: loading,
    supplierPay: pay,
  } = reports

  const getTotalCoValues = (data, key) => {
    const sum = data.reduce((init, current) => {
      return init + current[key]
    }, 0)
    return sum
  }

  // Sum data for footer
  useEffect(() => {
    const sumValue = getTotalCoValues(getSupplierAccountData, "TotalPrice")
    const sumPaidUp = getTotalCoValues(getSupplierAccountData, "PaidUp")
    setFooterSum({
      TotalPrice: sumValue,
      PaidUp: sumPaidUp,
    })
  }, [getSupplierAccountData])

  // update modifiedSupplierAccountData
  useEffect(() => {
    if (!getSupplierAccountData.length) return
    const modifiedData = getSupplierAccountData.map((one, i) => {
      // const OpeningBalance = i === 0 ? one.OpeningBalance : getSupplierAccountData[i - 1].Balance
      const paidUpValue =
        one.RealPaid === 0
          ? props.t("Deferred")
          : one.RealPaid === one.TotalPrice
          ? props.t("Paid")
          : props.t("Partial")
      return {
        ...one,
        //OpeningBalance: OpeningBalance,
        PaidStatus: paidUpValue,
        realOwned: one.TotalPrice - one.RealPaid,
      }
    })
    setModifiedSupplierAccountData(modifiedData)
  }, [getSupplierAccountData, getSupplierAccountData.length, props.t])

  useEffect(() => {
    dispatch(tblSupplier())
  }, [])

  useEffect(() => {
    const newSuppliersData = tblSupplierData.map(one => ({
      value: one.supplierName,
      label: one.supplierName,
      pkSupplierId: one.pkSupplierId,
    }))
    // setcurrentSupplier(newSuppliersData[0]);
    // if (newSuppliersData && newSuppliersData[0] && newSuppliersData[0].pkSupplierId) {
    //     setcurrentSupplierId(newSuppliersData[0].pkSupplierId);
    // }

    setSupplierData(newSuppliersData)
  }, [tblSupplierData])

  useEffect(() => {
    if (!date || !currentSupplierId) return
    let params = { ...date }
    if (currentSupplierId) {
      params.suppID = currentSupplierId
    }
    const paramters = convertParametersToString(params)
    setModifiedSupplierAccountData([])
    dispatch(getSupplierAccount(paramters))
  }, [date, currentSupplierId, pay, dataFetched])

  const getDateFromToHandler = date => {
    setDate(date)
  }
  const togglePayModel = () => {
    setPaymodal(prevState => !prevState)
  }

  const handlePayModal = data => {
    setPayData(data)
    togglePayModel()
  }

  const togglePayDetailsModal = () => {
    setPayDetailsModal(prevState => !prevState)
  }

  const handlePaymentDetailsModal = data => {
    setPayDetailsData(data)
    togglePayDetailsModal()
  }

  const dataChanged = () => {
    setDataFetched(prevState => !prevState)
  }
  const columns = useMemo(
    () => [
      {
        Header: props.t("Date"),
        accessor: "RealDate",
        filterable: true,
        Cell: cellProps => {
          const date = new Date(cellProps.value)
          const year = date.getFullYear()
          const month =
            date.getMonth() + 1 > 9
              ? date.getMonth() + 1
              : `0${date.getMonth() + 1}`
          const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
          return <>{cellProps.value ? year + "-" + month + "-" + day : ""}</>
        },
      },
      {
        Header: props.t("Bill No."),
        accessor: "BillNo",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
      {
        Header: props.t("Value"),
        accessor: "TotalPrice",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
      {
        Header: props.t("Pay Status"),
        accessor: "PaidStatus",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <Badge
                className={
                  "font-size-11 badge-soft-" +
                  (cellProps.value === props.t("Paid")
                    ? "success"
                    : cellProps.value === props.t("Partial")
                    ? "warning_blue"
                    : "danger")
                }
              >
                {/* {cellProps.value === props.t("Paid") ||
                cellProps.value === props.t("Partial") ? (
                  <a to="#" id="edittooltip" onClick={e => handlePaymentDetailsModal(cellProps.row.original)}>
                    {" "}
                    {cellProps.value}
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Details
                    </UncontrolledTooltip>
                  </a>
                ) : (
                  cellProps.value
                )} */}
                  {cellProps.value}
              </Badge>
            </>
          )
        },
      },
      {
        Header: props.t("Amount Paid"),
        accessor: "RealPaid",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
      {
        Header: props.t("Owed to him"),
        accessor: "realOwned",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {cellProps.row.original.TotalPrice -
                cellProps.row.original.RealPaid}
            </>
          )
        },
      },
      // {
      //   Header: props.t("Previous Credit"),
      //   accessor: "OpeningBalance",
      //   Cell: cellProps => {
      //     return <>{cellProps.value || 0}</>
      //   },
      // },
      // {
      //   Header: props.t("Post Credit"),
      //   accessor: "Balance",
      //   Cell: cellProps => {
      //     return <>{cellProps.value || 0}</>
      //   },
      // },
      {
        Header: props.t("Pay"),
        Cell: cellProps => {
          const { PaidStatus } = cellProps.row.original
          return (
            <div className="list-unstyled hstack gap-1 mb-0">
              <li>
                {(PaidStatus === props.t("Deferred") ||
                  PaidStatus === props.t("Partial")) && (
                  <Button
                    type="button"
                    className="btn btn-sm btn-soft-primary"
                    onClick={() => handlePayModal(cellProps.row.original)}
                  >
                    <i className="bx bx-money" id="viewtooltip" />
                  </Button>
                )}
              </li>
            </div>
          )
        },
      },
    ],
    [props.t]
  )

  const footerData = {
    RealDate: props.t("Total"),
    BillNo: "",
    TotalPrice: footerSum.TotalPrice,
    PaidStatus: "",
    PaidUp: footerSum.PaidUp,
    Owned: footerSum.TotalPrice - footerSum.PaidUp,
    Pay: "",
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={props.t("Shift")}
          breadcrumbItem={props.t("Supplier Account")}
        />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody className="border-bottom ml-2">
                <Row className="row-cols-auto gap-4 align-items-center">
                  <DateFromTo getDateFromTo={getDateFromToHandler} />
                  <div className="row">
                    <div className="col-md-auto mx-3 mx-lg-0">
                      <div className="form-group row px-0 align-items-center">
                        <label className="col-auto col-form-label px-0">
                          {props.t("Supplier")}
                        </label>
                        <div className="col-auto" style={{ width: "220px" }}>
                          <Select
                            value={
                              Object.keys(currentSupplier).length > 0
                                ? currentSupplier
                                : ""
                            }
                            onChange={selectedOption => {
                              setcurrentSupplier(selectedOption)
                              setcurrentSupplierId(selectedOption.pkSupplierId)
                            }}
                            options={supplierData}
                            className="select2-selection form-control p-0 border-0"
                            styles={{
                              menu: base => ({
                                ...base,
                                backgroundColor:
                                  layoutModeType === layoutModeTypes.LIGHT
                                    ? selectInputBgColors.light
                                    : selectInputBgColors.dark,
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>
              </CardBody>
              <CardBody>
                {loading ? (
                  <div className="text-center py-5">
                    <Spinner className="ms-2" color="primary" />
                  </div>
                ) : modifiedSupplierAccountData.length ? (
                  <TableContainer
                    columns={columns}
                    data={modifiedSupplierAccountData}
                    isJobListGlobalFilter={true}
                    customPageSize={5}
                    allListSize={modifiedSupplierAccountData.length || 0}
                    footerData={footerData}
                  />
                ) : (
                  <div style={{ height: "200px" }}></div>
                )}
              </CardBody>
              {!loading && modifiedSupplierAccountData.length > 0 ? (
                <div className="mt-2 d-flex justify-content-end">
                  <SendAndPrintPdf
                    data={modifiedSupplierAccountData}
                    extraData={{
                      Date_from:
                        date && date.dateFrom
                          ? convertDate(date.dateFrom)
                          : "First",
                      Date_to:
                        date && date.dateTo
                          ? convertDate(date.dateTo)
                          : convertDate(new Date()),
                      Supplier:
                        currentSupplier && currentSupplier.value
                          ? currentSupplier.value
                          : null,
                      Items_no: modifiedSupplierAccountData.length || 0,
                    }}
                    filteredData={[
                      "No",
                      "LogDate",
                      "BillNo",
                      "Value",
                      "PaidStatus",
                      "PaidUp",
                      "Owned",
                      "OpeningBalance",
                      "Balance",
                    ]}
                    extraTwoColData={null}
                  />
                </div>
              ) : (
                <></>
              )}
            </Card>
          </Col>
        </Row>
      </div>
      <PayModal
        isOpen={payModal}
        toggle={togglePayModel}
        payData={payData}
        dataChanged={dataChanged}
      />
      <PayDetailsModal isOpen={payDetailsModal} toggle={togglePayDetailsModal} payDetailsData={payDetailsData} />
    </div>
  )
}

SupplierAccount.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SupplierAccount))
