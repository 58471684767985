import autoTable from "jspdf-autotable"
import { preparePdfTabel } from "pages/Reports/shared/pdf/helpers/preparePdfTabel"
import { checkForRemainingSpacePage } from "./pdfHlpers"

const preparePdfTwoColumnsTabel = (doc, data, margin, extraTwoColData) => {
  const docWidth = doc.internal.pageSize.getWidth()
  const docHeight = doc.internal.pageSize.getHeight()

  const bodyData = []
  // Add a Tabel to the PDF
  data.forEach((obj, i) => {
    bodyData[i] = [
      Object.keys(obj).toString().replace(/_/g, " "),
      ...Object.values(obj),
    ]
  })

  autoTable(doc, {
    body: bodyData,
    startY: margin,
    theme: "grid",
    didParseCell: function (data) {
      if (data.row.index % 2 === 0) {
        data.cell.styles.fillColor = [255, 241, 224] // background color
      }
      data.cell.styles.fontStyle = "bold" // font style
    },
  })

  if (!extraTwoColData) return doc

  const extraSpace = 15
  let marginTop = doc.autoTable.previous.finalY + 7

  // reset font
  doc.setFontSize(14)
  doc.setFont("helvetica", "bold")
  doc.setTextColor(251, 148, 14)

  doc.text(extraTwoColData.header, 10, marginTop + 10)
  marginTop += extraSpace


// extraTwoColData.data,

  doc = preparePdfTabel(
    doc,
    extraTwoColData.data,
    extraTwoColData.filteredData,
    marginTop
  )

  doc.setFontSize(10)
  doc.setFont("helvetica", "bold")
  doc.setTextColor(73, 80, 87)

  
  marginTop = doc.autoTable.previous.finalY + 1
  function addTextToDoc(text, x, y, marginTop, goToNextPage) {
    doc.text(text, x, y)
    const { modifiedMarginTop } = checkForRemainingSpacePage(
      doc,
      marginTop,
      y + marginTop,
      goToNextPage
    )
    marginTop = modifiedMarginTop
  }
  const { modifiedMarginTop } = checkForRemainingSpacePage(
    doc,
    marginTop,
    marginTop,
    true
  )
  marginTop = modifiedMarginTop
  addTextToDoc(
    "Printed by: _______________",
    docWidth - 77,
    marginTop + 10,
    0,
    false
  )
  addTextToDoc(
    "Operation Manager: _______________",
    docWidth - 77,
    marginTop + 17,
    0,
    false
  )

  // doc.text("Printed by: _______________", docWidth-77, marginTableTop + 10)
  // doc.text("Operation Manager: _______________", docWidth-77, marginTableTop + 19)


  return doc
}

export { preparePdfTwoColumnsTabel }
