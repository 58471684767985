import {
    GET_TABEL_SAFE_SUCCESS,
    GET_TABEL_SAFE_FAIL,
} from "store/tabel-safe/actionTypes";

const INIT_STATE = {
    tabelSafeData: [],
    error: {},
};

const tabelSafe = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TABEL_SAFE_SUCCESS:
            return {
                ...state,
                tabelSafeData: action.payload,
            };

        case GET_TABEL_SAFE_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    };
};

export default tabelSafe;
