import {
    GET_SHIF_EMPLOYEES,
    GET_SHIF_EMPLOYEES_SUCCESS,
    GET_SHIF_EMPLOYEES_FAIL,
    GET_SHIF_DETAILS,
    GET_SHIF_DETAILS_SUCCESS,
    GET_SHIF_DETAILS_FAIL,
    GET_MANAGER_APPROVAL,
    GET_MANAGER_APPROVAL_SUCCESS,
    GET_MANAGER_APPROVAL_FAIL,
    GET_MANAGER_APPROVAL_RESET,
    SET_END_SHIFT_EMP_ID,
    SET_END_SHIFT_EMP_ID_SUCCESS,
    SET_END_SHIFT_EMP_ID_FAIL,
    SET_END_SHIFT_EMP_ID_RESET,
    GET_EMAILS,
    GET_EMAILS_SUCCESS,
    GET_EMAILS_FAIL,
    SEND_EMAIL,
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_FAIL
} from "store/end-shift/actionTypes";

const getShifEmplyees = () => ({
    type: GET_SHIF_EMPLOYEES,
});

const getShifEmplyeesSuccess = data => ({
    type: GET_SHIF_EMPLOYEES_SUCCESS,
    payload: data
});

const getShifEmplyeesFail = error => ({
    type: GET_SHIF_EMPLOYEES_FAIL,
    payload: error
});

const getShifDetails = FK_Shift_ID => ({
    type: GET_SHIF_DETAILS,
    payload: FK_Shift_ID
});

const getShifDetailsSuccess = data => ({
    type: GET_SHIF_DETAILS_SUCCESS,
    payload: data
});

const getShifDetailsFail = error => ({
    type: GET_SHIF_DETAILS_FAIL,
    payload: error
});

const getManagerApproval = credentials => ({
    type: GET_MANAGER_APPROVAL,
    payload: credentials
});

const getManagerApprovalSuccess = data => ({
    type: GET_MANAGER_APPROVAL_SUCCESS,
    payload: data
});

const getManagerApprovalFail = error => ({
    type: GET_MANAGER_APPROVAL_FAIL,
    payload: error
});

const getManagerApprovalReset = () => ({
    type: GET_MANAGER_APPROVAL_RESET,
});

const setEndShiftEmpId = EndShiftData => ({
    type: SET_END_SHIFT_EMP_ID,
    payload: EndShiftData
});

const setEndShiftEmpIdSuccess = data => ({
    type: SET_END_SHIFT_EMP_ID_SUCCESS,
    payload: data
});

const setEndShiftEmpIdFail = error => ({
    type: SET_END_SHIFT_EMP_ID_FAIL,
    payload: error
});

const setEndShiftEmpIdReset = () => ({
    type: SET_END_SHIFT_EMP_ID_RESET,
});

const getEmails = () => ({
    type: GET_EMAILS,
});

const getEmailsSuccess = data => ({
    type: GET_EMAILS_SUCCESS,
    payload: data
});

const getEmailsFail = error => ({
    type: GET_EMAILS_FAIL,
    payload: error
});

const sendEmail = bodyData => ({
    type: SEND_EMAIL,
    payload: bodyData
});

const sendEmailSuccess = data => ({
    type: SEND_EMAIL_SUCCESS,
    payload: data
});

const sendEmailFail = error => ({
    type: SEND_EMAIL_FAIL,
    payload: error
});

export {
    getShifEmplyees,
    getShifEmplyeesSuccess,
    getShifEmplyeesFail,
    getShifDetails,
    getShifDetailsSuccess,
    getShifDetailsFail,
    getManagerApproval,
    getManagerApprovalSuccess,
    getManagerApprovalFail,
    getManagerApprovalReset,
    setEndShiftEmpId,
    setEndShiftEmpIdSuccess,
    setEndShiftEmpIdFail,
    setEndShiftEmpIdReset,
    getEmails,
    getEmailsSuccess,
    getEmailsFail,
    sendEmail,
    sendEmailSuccess,
    sendEmailFail
};