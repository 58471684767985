import {
    GET_ITEM_INGREDIANTS_SUCCESS,
    GET_ITEM_INGREDIANTS_FAIL
  } from "./actionTypes"
  
  const INIT_STATE = {
    itemIngrediants: [],
    // userProfile: {},
    // error: {},
  }
  
  const itemIngrediant = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_ITEM_INGREDIANTS_SUCCESS:
        return {
          ...state,
          itemIngrediants: action.payload,
        }
  
      case GET_ITEM_INGREDIANTS_FAIL:
        return {
          ...state,
          error: action.payload,
        }
      default:
        return state
    }
  }
  
  export default itemIngrediant
  