import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import classes from "../pages/Products/index.module.scss"
import {
    Card,
    Col,
    Row,
  } from "reactstrap"


const UploadFile = ({handleSelectedFiles, t, resetFiles, toggleResetFiles}) => {
  const [selectedFiles, setselectedFiles] = useState([])
  const videoFormats = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg'],
    'application/pdf': ['.pdf'],
  };
  useEffect(() => {
    if (resetFiles) {
      setselectedFiles([])
      toggleResetFiles(false)
    }
  }, [resetFiles])
  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    handleSelectedFiles(files)
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <div>
      <Dropzone
        onDrop={acceptedFiles => {
          handleAcceptedFiles(acceptedFiles)
        }}
        accept={videoFormats}
        multiple={false}
      >
        {({ getRootProps, getInputProps }) => (
          <div className={`dropzone ${classes.dropzone_area}`}>
            <div
              className={`dz-message needsclick mt-2 ${classes.dz_message_area}`}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div className="mb-3">
                <i className="display-4 text-muted bx bxs-cloud-upload" />
              </div>
              <h4>{t("Drop files here or click to upload")}</h4>
            </div>
          </div>
        )}
      </Dropzone>
      <span>{t("Only .pdf, .png and .jpeg")}</span>
      <div className="dropzone-previews mt-3" id="file-previews">
        {selectedFiles.map((f, i) => {
          return (
            <Card
              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
              key={i + "-file"}
            >
              <div className="p-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      data-dz-thumbnail=""
                      height="80"
                      className="avatar-sm rounded bg-light"
                      alt={f.name}
                      src={f.preview}
                    />
                  </Col>
                  <Col>
                    <Link to="#" className="text-muted font-weight-bold">
                      {f.name}
                    </Link>
                    <p className="mb-0">
                      <strong>{f.formattedSize}</strong>
                    </p>
                  </Col>
                </Row>
              </div>
            </Card>
          )
        })}
      </div>
    </div>
  )
}

UploadFile.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
  }
  
  export default withRouter(withTranslation()(UploadFile))