import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  LOGIN_USER_DATA,
  LOGIN_DATA_RESET,
  LOGOUT_USER_CLEAR,
} from "./actionTypes"
import {
  loginError,
  loginDataReset,
  loginSuccessData,
  logoutUserSuccessClear,
} from "./actions"

import { postLogin } from "../../helpers/backend_helper"
import { privilegePageId } from "common/privilege"


function* loginUserData({ payload: { user, history } }) {
  // Buffalo Login Code

  let userData = {
    username: user.userName,
    password: user.password,
  }
  try {
    const response = yield call(postLogin, userData)
    if (response.status === 200) {
      if (
        response.data.sts.code === 200 &&
        (response.data.rs.employee.fkJobCategoryId === 2 || response.data.rs.employee.fkJobCategoryId === 7 )
      ) {
        localStorage.setItem(
          "authUser",
          JSON.stringify(response.data.rs.employee)
        )
        localStorage.setItem("token", response.data.rs.token)
        localStorage.setItem("shiftID", response.data.rs.shiftID)
        yield put(loginSuccessData(response.data.rs))
        yield put(loginError(""))
            response.data.rs.mainPrivileges.some(privilege =>  privilege.pK_Page_ID === privilegePageId.dashboard) ?
            history("/dashboard") : history("/products")
        
      } else if (
        response.data.sts.code === 401 ||
        response.data.rs.employee.fkJobCategoryId !== 2 || response.data.rs.employee.fkJobCategoryId === 7 
      ) {
        yield put(loginError("unauthorized"))
      }
    }
  } catch (error) {
    yield put(loginError(error))
  }
}

function* onLoginDataReset() {
  put(loginDataReset)
}

function* loginSaga() {
  yield takeEvery(LOGIN_USER_DATA, loginUserData)
  yield takeEvery(LOGIN_DATA_RESET, onLoginDataReset)
}

export default loginSaga
