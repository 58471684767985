import {
    SET_LOGO,
    SET_LOGO_SUCCESS,
    SET_LOGO_FAIL,
} from "./actionTypes";

const setLogo = (logo) => ({
    type: SET_LOGO,
    payload: logo,
});

const setLogoSuccess = (logo) => ({
    type: SET_LOGO_SUCCESS,
    payload: logo,
});

const setLogoFail = (error) => ({
    type: SET_LOGO_FAIL,
    payload: error,
});

export {
    setLogo,
    setLogoSuccess,
    setLogoFail,
};