import React from "react"
import ReactApexChart from "react-apexcharts"

const barchart = ({ data, categories }) => {
  const series = [
    {
      data: data,
    },
  ]
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#fb940e"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: categories,
    },
  }

  return (
    <div dir="ltr">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height="350"
      />
    </div>
  )
}

export default barchart
