import {
  GET_UNITS_SUCCESS,
  GET_UNITS_FAIL,
  POST_UNIT_SUCCESS,
  POST_UNIT_FAIL,
  POST_UNIT,
  RESET_POST_UNIT,
  RESET_MODEL_TYPE
} from "./actionTypes";


const INIT_STATE = {
  allUnits: [],
  newUnitAdded: false,
  modelType: "", // material or component to auto select unit value in specific model eiter materail or component
  // userProfile: {},
  error: {},
};

const units = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_UNITS_SUCCESS:
      return {
        ...state,
        allUnits: action.payload,
      };

    case GET_UNITS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case POST_UNIT:
      return {
        ...state,
        newUnitAdded: false,
      };

    case POST_UNIT_SUCCESS:
      return {
        ...state,
        allUnits: [...state.allUnits, action.payload],
        newUnitAdded: true,
      };

    case RESET_POST_UNIT:
      return {
        ...state,
        newUnitAdded: false,
      };

    case POST_UNIT_FAIL:
      return {
        ...state,
        error: { ...state, error: action.payload },
      };

    case RESET_MODEL_TYPE:
      return {
        ...state,
        modelType: action.payload,
      };

    default:
      return state;
  }
};

export default units;
