/* Get Unit */
export const GET_UNITS = "GET_UNITS";
export const GET_UNITS_SUCCESS = "GET_UNITS_SUCCESS";
export const GET_UNITS_FAIL = "GET_UNITS_FAIL";

/*Post Unit*/
export const POST_UNIT = "POST_UNIT";
export const POST_UNIT_SUCCESS = "POST_UNIT_SUCCESS";
export const POST_UNIT_FAIL = "POST_UNIT_FAIL";

export const RESET_POST_UNIT = "RESET_ADD_UNIT";
export const RESET_MODEL_TYPE = "RESET_MODEL_TYPE";