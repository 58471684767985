import {
  GET_MATERIALS,
  GET_MATERIALS_SUCCESS,
  GET_MATERIALS_FAIL,
  POST_MATERIAL,
  POST_MATERIAL_SUCCESS,
  POST_MATERIAL_FAIL,
  RESET_POST_MATERIAL,
  GET_ONE_MATERIAL,
  GET_ONE_MATERIAL_SUCCESS,
  GET_ONE_MATERIAL_FAIL,
  GET_ONE_COMPONENT,
  GET_ONE_COMPONENT_SUCCESS,
  GET_ONE_COMPONENT_FAIL,
  UPDATE_MATERIAL_COMPONENT,
  UPDATE_MATERIAL_COMPONENT_SUCCESS,
  UPDATE_MATERIAL_COMPONENT_FAIL
} from "./actionTypes";

// Get material actions
export const getMaterials = () => ({
  type: GET_MATERIALS,
});

export const getMaterialsSuccess = material => ({
  type: GET_MATERIALS_SUCCESS,
  payload: material,
});

export const getMaterialsFail = err => ({
  type: GET_MATERIALS_FAIL,
  payload: err,
});

// Post material actions
export const postMaterial = (material) => ({
  type: POST_MATERIAL,
  payload: material
});

export const postMaterialSuccess = material => ({
  type: POST_MATERIAL_SUCCESS,
  payload: material,
});

export const postMaterialFail = err => ({
  type: POST_MATERIAL_FAIL,
  payload: err,
});

export const resetPostMaterial = () => ({
  type: RESET_POST_MATERIAL,
});

export const getOneMaterial = (id) => ({
  type: GET_ONE_MATERIAL,
  id
})

export const getOneMaterialSuccess = material => ({
  type: GET_ONE_MATERIAL_SUCCESS,
  payload: material,
})

export const getOneMaterialFail = err => ({
  type: GET_ONE_MATERIAL_FAIL,
  payload: err,
})

export const getOneComponent = (id) => ({
  type: GET_ONE_COMPONENT,
  id
})

export const getOneComponentSuccess = component => ({
  type: GET_ONE_COMPONENT_SUCCESS,
  payload: component,
})

export const getOneComponentFail = err => ({
  type: GET_ONE_COMPONENT_FAIL,
  payload: err,
})

export const updateMaterialComponent = (data) => ({
  type: UPDATE_MATERIAL_COMPONENT,
  payload: data
})

export const updateMaterialComponentSuccess = component => ({
  type: UPDATE_MATERIAL_COMPONENT_SUCCESS,
  payload: component
})

export const updateMaterialComponentFail = err => ({
  type: UPDATE_MATERIAL_COMPONENT_FAIL,
  payload: err
})