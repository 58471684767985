import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Col, Row, Spinner } from "reactstrap"
// import { counterSectionDummyData } from "pages/Dashboard/data"
import classes from "../index.module.scss"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

import { getStatistics } from "store/actions"

const CounterSection = props => {
  const dispatch = useDispatch()
  const {
    statisticsDataList: statisticsData,
    statisticsDataListLoading: isLoading,
  } = useSelector(state => state.dashboard)

  const [statisticsDataList, setStatisticsDataList] = useState({})
  useEffect(() => {
    dispatch(getStatistics())
  }, [])

  useEffect(() => {
    setStatisticsDataList(statisticsData)
  }, [statisticsData])

  return (
    <Row className="px-3">
      {/* {counterSectionDummyData.map((one, key) => (
        <Col lg={3} md={6} xs={12} className="" key={"_col_" + key}>
          <Card className={`mini-stats-wid  p-0 ${classes.counterCard}`}>
            <CardBody className={ classes.cardBody}>
              <div className="d-flex justify-content-center align-items-center">
              <div className="flex-grow-1">
                  <p className="text-muted fw-medium">{one.title}</p>
                  <h3 className="mb-0">{one.count}</h3>
                  <div className="d-flex mt-2 text-muted">
                    <img
                      src={one.status === "increase" ? greenchart : redchart}
                      alt="chart"
                      className={`d-block mx-2 ${classes.chartIcon}`}
                    />
                    <span className={classes.counterSmallText}>
                      {one.percentage}%
                    </span>
                    <span className={classes.counterSmallText}>
                      ({one.days})
                    </span>
                  </div>
                </div>
                <div
                  className={`avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon`}
                >
                  <span className={`avatar-title rounded-circle bg-primary`}>
                    <i
                      className={`${one.iconClass} bx bx-copy-alt font-size-24`}
                      style={{ fontSize: "40px" }}
                    ></i>
                  </span>
                </div>
                
              </div>
            </CardBody>
          </Card>
        </Col>
      ))} */}
      {/* Total Orders */}
      <Col lg={3} md={6} xs={12}>
        <Card className={`mini-stats-wid  p-0 ${classes.counterCard}`}>
          <CardBody className={classes.cardBody}>
            <div className="d-flex justify-content-center align-items-center">
              <div className="flex-grow-1">
                <p className="text-muted fw-medium">{props.t("totalOrders")}</p>
                {isLoading ? (
                  <div className="text-center">
                    <Spinner className="ms-2" color="primary" />
                  </div>
                ) : Object.keys(statisticsDataList).length && (
                  <h3 className="mb-0">{statisticsData.OrdersCount}</h3>
                )}
                <div className="d-flex mt-2 text-muted">
                  <span className={classes.counterSmallText}>30 {props.t("days")}</span>
                </div>
              </div>
              <div
                className={`avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon`}
              >
                <span className={`avatar-title rounded-circle bg-primary`}>
                  <i
                    className={`bx bxs-coffee-alt bx bx-copy-alt font-size-24`}
                    style={{ fontSize: "40px" }}
                  ></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      {/* Total Menus */}
      <Col lg={3} md={6} xs={12}>
        <Card className={`mini-stats-wid  p-0 ${classes.counterCard}`}>
          <CardBody className={classes.cardBody}>
            <div className="d-flex justify-content-center align-items-center">
              <div className="flex-grow-1">
                <p className="text-muted fw-medium">{props.t("totalRevenue")}</p>
                {isLoading ? (
                  <div className="text-center">
                    <Spinner className="ms-2" color="primary" />
                  </div>
                ) : Object.keys(statisticsDataList).length && (
                  <h3 className="mb-0">{statisticsData.TotalRevenue || 0}</h3>
                )}
                <div className="d-flex mt-2 text-muted">
                  <span className={classes.counterSmallText}>30 {props.t("days")}</span>
                </div>
              </div>
              <div
                className={`avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon`}
              >
                <span className={`avatar-title rounded-circle bg-primary`}>
                  <i
                    className={`bx bx-dollar bx bx-copy-alt font-size-24`}
                    style={{ fontSize: "40px" }}
                  ></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      {/* Total Companies */}
      <Col lg={3} md={6} xs={12}>
        <Card className={`mini-stats-wid  p-0 ${classes.counterCard}`}>
          <CardBody className={classes.cardBody}>
            <div className="d-flex justify-content-center align-items-center">
              <div className="flex-grow-1">
                <p className="text-muted fw-medium">{props.t("totalCompanies")}</p>
                {isLoading ? (
                  <div className="text-center">
                    <Spinner className="ms-2" color="primary" />
                  </div>
                ) : Object.keys(statisticsDataList).length && (
                  <h3 className="mb-0">{statisticsData.TotalCompanies || 0}</h3>
                )}
                <div className="d-flex mt-2 text-muted">
                  <span className={classes.counterSmallText}>30 {props.t("days")}</span>
                </div>
              </div>
              <div
                className={`avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon`}
              >
                <span className={`avatar-title rounded-circle bg-primary`}>
                  <i
                    className={`bx bx-notification bx bx-copy-alt font-size-24`}
                    style={{ fontSize: "40px" }}
                  ></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      {/* Revenue Average */}
      <Col lg={3} md={6} xs={12}>
        <Card className={`mini-stats-wid  p-0 ${classes.counterCard}`}>
          <CardBody className={classes.cardBody}>
            <div className="d-flex justify-content-center align-items-center">
              <div className="flex-grow-1">
                <p className="text-muted fw-medium">{props.t("averageOrderValue")}</p>
                {isLoading ? (
                  <div className="text-center">
                    <Spinner className="ms-2" color="primary" />
                  </div>
                ) : (
                  Object.keys(statisticsDataList).length && (
                    <h3 className="mb-0">{statisticsData.AVGRevenue || 0}</h3>
                  )
                )}
                <div className="d-flex mt-2 text-muted">
                  <span className={classes.counterSmallText}>30 {props.t("days")}</span>
                </div>
              </div>
              <div
                className={`avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon`}
              >
                <span className={`avatar-title rounded-circle bg-primary`}>
                  <i
                    className={`bx bxs-group bx bx-copy-alt font-size-24`}
                    style={{ fontSize: "40px" }}
                  ></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

CounterSection.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(CounterSection))