import React from "react"
import { Link } from "react-router-dom"
import { Button, Col, Input, Row } from "reactstrap"

const RowsTabel = ({ rows, onDeleteFormRow }) => {
  return rows.map(row => (
    <Row key={row.id} style={{ margin: "5px 0" }}>
      <Col sm={3} xs={6} className="mb-3">
        <Input
          type="text"
          className="fom-control"
          value={
            row.material
              ? row.material.materialName
              : row.component
              ? row.component.materialName
              : ""
          }
          disabled
        />
      </Col>
      <Col sm={1} xs={6} className="mb-3">
        <Input
          type="text"
          id="unit"
          className="form-control"
          value={row.unit || ""}
          disabled
        />
      </Col>
      <Col sm={1} xs={6} className="mb-3">
        <Input
          type="number"
          className="form-control"
          value={row.qty || ""}
          disabled
        />
      </Col>
      <Col sm={3} className="mb-3">
        <Input
          type="text"
          id="matUnit"
          className="fom-control"
          value={row.storeFrom.StoreName}
          disabled
        />
      </Col>
      <Col sm={3} className="mb-3">
        <Input
          type="text"
          id="matUnit"
          className="fom-control"
          value={row.storeTo.StoreName}
          disabled
        />
      </Col>
      <Col sm={1} xs={6}>
        <span
          onClick={() => onDeleteFormRow(row.id)}
          style={{ cursor: "pointer" }}
          className="text-danger p-1 border-none"
        >
          <i
            className="bx bxs-trash"
            style={{
              padding: "5px",
              fontSize: "22px",
              lineHeight: "27px",
            }}
          />
        </span>
      </Col>
    </Row>
  ))
}

export default RowsTabel
