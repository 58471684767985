import React, { useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import { Row } from "reactstrap"
import * as Yup from "yup"

import { useFormik } from "formik"

const DateFrom = props => {
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      dateFrom: "",
    },
    validationSchema: Yup.object({
      dateFrom: Yup.string(),
    }),
    onSubmit: values => {
      //
    },
  })

  const { dateFrom } = validation.values
  useEffect(() => {
    props.getDateFrom({ dateFrom: dateFrom })
  }, [dateFrom])

    return (
        <Row className="gap-4 mx-1 col-auto">
            <div className="col-md-auto">
                <div className="form-group row px-0">
                    <label htmlFor="dateFrom" className="col-auto col-form-label px-0">
                        {props.labelName ? props.labelName : props.t("Date From")}
                        {/* {props.t("Date From")} */}
                    </label>
                    <div className="col-auto">
                        <input
                            className="form-control"
                            type="date"
                            id="dateFrom"
                            name="dateFrom"
                            placeholder="Date From"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.dateFrom || ""}
                            style={{ width: "200px" }}
                        />
                    </div>
                </div>
            </div>
        </Row>
    );
};

DateFrom.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(DateFrom))
