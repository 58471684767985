import React, { useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import { Row } from "reactstrap"
import { toast } from "react-toastify"

import { useFormik } from "formik"

const FilterByIdFromTo = props => {
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      idFrom: "",
      idTo: "",
    },
  })

  const { idFrom, idTo } = validation.values
  useEffect(() => {
    if (!idFrom && !props.returnAllActions) return

    const bearerIds = () => {
      if (idTo !== "" && idFrom > idTo) {
        toast.dismiss()
        toast.error(props.t("IdFromHaveToLessThanFrom"))
        validation.setFieldValue("idTo", "")
        return
      }

      props.getIdFromTo({
        idFrom: idFrom,
        idTo: idTo,
      })
    }

    const timer = setTimeout(bearerIds, 1000)
    return () => clearTimeout(timer)
  }, [idFrom, idTo])

  return (
    <Row className="gap-4 mx-1 col-auto">
      <div className="col-md-auto">
        <div className="form-group row px-0">
          <label htmlFor="idFrom" className="col-auto col-form-label px-0">
            {props.t("idFrom")}
          </label>
          <div className="col-auto">
            <input
              className="form-control"
              type="number"
              id="idFrom"
              name="idFrom"
              min={1}
              placeholder={props.t("idFrom")}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.idFrom || ""}
              style={{ width: "100px" }}
            />
          </div>
        </div>
      </div>
      <div className="col-md-auto">
        <div className="form-group row px-0">
          <label htmlFor="idTo" className="col-auto col-form-label px-0">
            {props.t("idTo")}
          </label>
          <div className="col-auto">
            <input
              className="form-control"
              type="number"
              id="idTo"
              name="idTo"
              min={1}
              placeholder={props.t("idTo")}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.idTo || ""}
              style={{ width: "100px" }}
            />
          </div>
        </div>
      </div>
    </Row>
  )
}

FilterByIdFromTo.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(FilterByIdFromTo))
