// Utils action types
const GET_TBL_STORE = "GET_TBL_STORE";
const GET_TBL_STORE_SUCCESS = "GET_TBL_STORE_SUCCESS";
const GET_TBL_STORE_FAIL = "GET_TBL_STORE_FAIL";

const GET_TBL_MATERIAL = "GET_TBL_MATERIAL";
const GET_TBL_MATERIAL_SUCCESS = "GET_TBL_MATERIAL_SUCCESS";
const GET_TBL_MATERIAL_FAIL = "GET_TBL_MATERIAL_FAIL";

const GET_TBL_EMP = "GET_TBL_EMP";
const GET_TBL_EMP_SUCCESS = "GET_TBL_EMP_SUCCESS";
const GET_TBL_EMP_FAIL = "GET_TBL_EMP_FAIL";

// Main action types
const GET_STOCK_BALANCE_FILTERED_BY_STOCK = "GET_STOCK_BALANCE_FILTERED_BY_STOCK";
const GET_STOCK_BALANCE_FILTERED_BY_STOCK_SUCCESS = "GET_STOCK_BALANCE_FILTERED_BY_STOCK_SUCCESS";
const GET_STOCK_BALANCE_FILTERED_BY_STOCK_FAIL = "GET_STOCK_BALANCE_FILTERED_BY_STOCK_FAIL";

const GET_STOCK_BALANCE_FILTERED_BY_MATERIAL = "GET_STOCK_BALANCE_FILTERED_BY_MATERIAL";
const GET_STOCK_BALANCE_FILTERED_BY_MATERIAL_SUCCESS = "GET_STOCK_BALANCE_FILTERED_BY_MATERIAL_SUCCESS";
const GET_STOCK_BALANCE_FILTERED_BY_MATERIAL_FAIL = "GET_STOCK_BALANCE_FILTERED_BY_MATERIAL_FAIL";

const GET_SAFE_BALANCE = "GET_SAFE_BALANCE";
const GET_SAFE_BALANCE_SUCCESS = "GET_SAFE_BALANCE_SUCCESS";
const GET_SAFE_BALANCE_FAIL = "GET_SAFE_BALANCE_FAIL";

const GET_NET_PROFIT = "GET_NET_PROFIT";
const GET_NET_PROFIT_SUCCESS = "GET_NET_PROFIT_SUCCESS";
const GET_NET_PROFIT_FAIL = "GET_NET_PROFIT_FAIL";

const GET_DAY_TRANS = "GET_DAY_TRANS";
const GET_DAY_TRANS_SUCCESS = "GET_DAY_TRANS_SUCCESS";
const GET_DAY_TRANS_FAIL = "GET_DAY_TRANS_FAIL";

const GET_MONTH_TRANS = "GET_MONTH_TRANS";
const GET_MONTH_TRANS_SUCCESS = "GET_MONTH_TRANS_SUCCESS";
const GET_MONTH_TRANS_FAIL = "GET_MONTH_TRANS_FAIL";

const GET_SUPPLIER_ACCOUNT = "GET_SUPPLIER_ACCOUNT";
const GET_SUPPLIER_ACCOUNT_SUCCESS = "GET_SUPPLIER_ACCOUNT_SUCCESS";
const GET_SUPPLIER_ACCOUNT_FAIL = "GET_SUPPLIER_ACCOUNT_FAIL";

const GET_ITEMS_SALES = "GET_ITEMS_SALES";
const GET_ITEMS_SALES_SUCCESS = "GET_ITEMS_SALES_SUCCESS";
const GET_ITEMS_SALES_FAIL = "GET_ITEMS_SALES_FAIL";

const GET_EXPENSE_REPORT = "GET_EXPENSE_REPORT";
const GET_EXPENSE_REPORT_SUCCESS = "GET_EXPENSE_REPORT_SUCCESS";
const GET_EXPENSE_REPORT_FAIL = "GET_EXPENSE_REPORT_FAIL";

const GET_SUPPLIER_PAYMENTS = "GET_SUPPLIER_PAYMENTS";
const GET_SUPPLIER_PAYMENTS_SUCCESS = "GET_SUPPLIER_PAYMENTS_SUCCESS";
const GET_SUPPLIER_PAYMENTS_FAIL = "GET_SUPPLIER_PAYMENTS_FAIL";

const GET_SHIFT_REPORT = "GET_SHIFT_REPORT";
const GET_SHIFT_REPORT_SUCCESS = "GET_SHIFT_REPORT_SUCCESS";
const GET_SHIFT_REPORT_FAIL = "GET_SHIFT_REPORT_FAIL";

const GET_ONE_SAFE_DATA = "GET_ONE_SAFE_DATA";
const GET_ONE_SAFE_DATA_SUCCESS = "GET_ONE_SAFE_DATA_SUCCESS";
const GET_ONE_SAFE_DATA_FAIL = "GET_ONE_SAFE_DATA_FAIL";

const GET_SAFE_TRANSFER = "GET_SAFE_TRANSFERE";
const GET_SAFE_TRANSFER_SUCCESS = "GET_SAFE_TRANSFERE_SUCCESS";
const GET_SAFE_TRANSFER_FAIL = "GET_SAFE_TRANSFERE_FAIL";

const POST_SUPPLIER_PAYMENT = "POST_SUPPLIER_PAYMENT";
const POST_SUPPLIER_PAYMENT_SUCCESS = "POST_SUPPLIER_PAYMENT_SUCCESS";
const POST_SUPPLIER_PAYMENT_FAIL = "POST_SUPPLIER_PAYMENT_FAIL";

export {
    // Utils action types
    GET_TBL_STORE,
    GET_TBL_STORE_SUCCESS,
    GET_TBL_STORE_FAIL,
    GET_TBL_MATERIAL,
    GET_TBL_MATERIAL_SUCCESS,
    GET_TBL_MATERIAL_FAIL,
    GET_TBL_EMP,
    GET_TBL_EMP_SUCCESS,
    GET_TBL_EMP_FAIL,
    // Main action types
    GET_STOCK_BALANCE_FILTERED_BY_STOCK,
    GET_STOCK_BALANCE_FILTERED_BY_STOCK_SUCCESS,
    GET_STOCK_BALANCE_FILTERED_BY_STOCK_FAIL,
    GET_STOCK_BALANCE_FILTERED_BY_MATERIAL,
    GET_STOCK_BALANCE_FILTERED_BY_MATERIAL_SUCCESS,
    GET_STOCK_BALANCE_FILTERED_BY_MATERIAL_FAIL,
    GET_SAFE_BALANCE,
    GET_SAFE_BALANCE_SUCCESS,
    GET_SAFE_BALANCE_FAIL,
    GET_NET_PROFIT,
    GET_NET_PROFIT_SUCCESS,
    GET_NET_PROFIT_FAIL,
    GET_DAY_TRANS,
    GET_DAY_TRANS_SUCCESS,
    GET_DAY_TRANS_FAIL,
    GET_MONTH_TRANS,
    GET_MONTH_TRANS_SUCCESS,
    GET_MONTH_TRANS_FAIL,
    GET_SUPPLIER_ACCOUNT,
    GET_SUPPLIER_ACCOUNT_SUCCESS,
    GET_SUPPLIER_ACCOUNT_FAIL,
    GET_ITEMS_SALES,
    GET_ITEMS_SALES_SUCCESS,
    GET_ITEMS_SALES_FAIL,
    GET_EXPENSE_REPORT,
    GET_EXPENSE_REPORT_SUCCESS,
    GET_EXPENSE_REPORT_FAIL,
    GET_SUPPLIER_PAYMENTS,
    GET_SUPPLIER_PAYMENTS_SUCCESS,
    GET_SUPPLIER_PAYMENTS_FAIL,
    GET_SHIFT_REPORT,
    GET_SHIFT_REPORT_SUCCESS,
    GET_SHIFT_REPORT_FAIL,
    GET_ONE_SAFE_DATA,
    GET_ONE_SAFE_DATA_SUCCESS,
    GET_ONE_SAFE_DATA_FAIL,
    GET_SAFE_TRANSFER,
    GET_SAFE_TRANSFER_SUCCESS,
    GET_SAFE_TRANSFER_FAIL,

    POST_SUPPLIER_PAYMENT,
    POST_SUPPLIER_PAYMENT_SUCCESS,
    POST_SUPPLIER_PAYMENT_FAIL
};