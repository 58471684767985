import React from "react"
import ReactApexChart from "react-apexcharts"

const Dountchart = ({ data, labels }) => {
  const series = data

  const options = {
    labels: labels,
    // colors: [
    //   "#fb8d0e",
    //   "#fb860e",
    //   "#fb7f0e",
    //   "#fb780e",
    //   "#fb710e",
    //   "#fb6a0e",
    //   "#fb630e",
    //   "#fb5c0e",
    //   "#fb550e",
    //   "#fb4e0e",
    // ],
    colors: [
      "#FB940E",
      "#FFBE4F",
      "#FFD67D",
      "#FFEDAB",
      "#FFFFD9",
      "#E8DA78",
      "#CBB767",
      "#AEA457",
      "#918246",
      "#746035",
      "#583E25",
      "#3B2D14",
      "#F9C177",
      "#FAC587",
      "#CDB27B",
      "#FAE1A7",
      "#FAEAB6",
      "#FBF3C6",
      "#FCFCD6",
      "#FBF3C6",
      "#FCFCD6",
      "#EBF2CF",
      "#D9E9C9",
      "#C7E0C3",
      "#B5D7BD",
      "#A3CEB6",
      "#91C5B0",
      "#7FBDA9",
      "#6DB4A3",
      "#5BAB9D",
      "#49A396",
      "#37A08F",
    ],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  }

  return (
    <div dir="ltr">
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height="420"
      />
    </div>
  )
}

export default Dountchart
