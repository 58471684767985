import React, { useMemo } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import TableContainer from "components/Common/TableContainer"
import {
  Category,
  Name,
  PrepareArea,
  Price,
  SecondName,
} from "pages/Products/contactlistCol"
import { Link } from "react-router-dom"
import { UncontrolledTooltip } from "reactstrap"
import { useSelector } from "react-redux"

import { pageActionId } from "common/privilege"

const ProductList = props => {
  const { item, handleCreateNewItem, handleEditItem, pageActionData } = props

  const loadingItems = useSelector(state => state.items.loadingItems)

  const columnData = [
    {
      Header: props.t("Item Name “1st Lang”"),
      accessor: "itemName",
      filterable: true,
      Cell: cellProps => {
        return <Name {...cellProps} />
      },
    },
    {
      Header: props.t("Item Name “2nd Lang”"),
      accessor: "itemNameLang2",
      filterable: true,
      Cell: cellProps => {
        return <SecondName {...cellProps} />
      },
    },
    {
      Header: props.t("Prepare Area"),
      accessor: "prepareAreaName",
      filterable: true,
      Cell: cellProps => {
        return <PrepareArea {...cellProps} />
      },
    },
    {
      Header: props.t("Category"),
      accessor: "mainCategoryName",
      filterable: true,
      Cell: cellProps => {
        return <Category {...cellProps} />
      },
    },
    {
      Header: props.t("Price"),
      accessor: "price",
      filterable: true,
      Cell: cellProps => {
        return (
          <>
            {" "}
            <Price {...cellProps} />{" "}
          </>
        )
      },
    },
  ]

  const columnsNoAction = useMemo(() => columnData, [props.t])

  const columnsWithAction = useMemo(() => {
    const columnDataCopy = [...columnData]
    columnDataCopy.push({
      Header: props.t("Action"),
      Cell: cellProps => {
        return (
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => {
                const userData = cellProps.row.original
                handleEditItem(userData)
              }}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                {props.t("Edit")}
              </UncontrolledTooltip>
            </Link>
          </div>
        )
      },
    })
    return columnDataCopy
  }, [props.t])
  
  return (
    <>
      <TableContainer
        columns={
          pageActionData.some(
            action =>
              action.PK_PageAction_ID === pageActionId.products.editProduct
          )
            ? columnsWithAction
            : columnsNoAction
        }
        data={item}
        isGlobalFilter={true}
        isAddUserList={true}
        handleUserClick={handleCreateNewItem}
        customPageSize={5}
        allListSize={item.length}
        newItemBtnTxt={
          pageActionData.some(
            action =>
              action.PK_PageAction_ID === pageActionId.products.addProduct
          )
            ? props.t("Create New Product")
            : ""
        }
        className="custom-header-css"
        isLoading={loadingItems}
        dataAvailable={
          pageActionData.some(
            action =>
              action.PK_PageAction_ID === pageActionId.products.showProduct
          )
            ? "yes"
            : "no"
        }
      />
    </>
  )
}

ProductList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ProductList))
