import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Routes, Route, useNavigate } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
// Import Routes all
import { authProtectedRoutes, publicRoutes, errorRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

import "./assets/scss/theme.scss"
import "react-toastify/dist/ReactToastify.css"

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// import fakeBackend from "./helpers/AuthType/fakeBackend"

import { ToastContainer } from "react-toastify"

import Pages401 from "pages/Utility/pages-401"
import { getMainPrivillage } from "store/actions"

// Activating fake backend
//fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout
      break
    default:
      break
  }
  return Layout
}

const App = () => {
  const [mainPrivillage, setMainPrivillage] = useState([])
  const layoutType = useSelector(state => state.Layout.layoutType)
  const mainPrivillageData = useSelector(
    state => state.privillage.mainPrivillageData
  )
  const Layout = getLayout(layoutType)
  const dispatch = useDispatch()

  useEffect(() => {
    JSON.parse(localStorage.getItem("authUser")) &&
      dispatch(
        getMainPrivillage(JSON.parse(localStorage.getItem("authUser")).pkEmpId)
      )
    // setMainPrivillage(JSON.parse(localStorage.getItem("mainPrivileges")))
  }, [])

  useEffect(() => {
    mainPrivillageData &&
      mainPrivillageData.length &&
      setMainPrivillage(mainPrivillageData)
  }, [mainPrivillageData])

  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                <ToastContainer theme="colored" />

                {route.component}
              </NonAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}
        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <ToastContainer theme="colored" />

                <Layout>
                  {mainPrivillage.length &&
                    (mainPrivillage.some(
                      privilege => privilege.PK_Page_ID === route.pageId
                    ) ? (
                      route.component
                    ) : (
                      <Pages401 />
                    ))}
                </Layout>
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}
        {errorRoutes.map((route, idx) => (
          <Route
            path={route.path}
            key={idx}
            element={<>{route.component}</>}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
