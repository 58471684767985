// export const GET_REVENUE_MONTHLY = "GET_REVENUE_MONTHLY"
// export const GET_REVENUE_MONTHLY_SUCCESS = "GET_REVENUE_MONTHLY_SUCCESS"
// export const GET_REVENUE_MONTHLY_FAIL = "GET_REVENUE_MONTHLY_FAIL"

// export const GET_REVENUE_WEEKLY = "GET_REVENUE_WEEKLY"
// export const GET_REVENUE_WEEKLY_SUCCESS = "GET_REVENUE_WEEKLY_SUCCESS"
// export const GET_REVENUE_WEEKLY_FAIL = "GET_REVENUE_WEEKLY_FAIL"

// export const GET_REVENUE_DAILY = "GET_REVENUE_DAILY"
// export const GET_REVENUE_DAILY_SUCCESS = "GET_REVENUE_DAILY_SUCCESS"
// export const GET_REVENUE_DAILY_FAIL = "GET_REVENUE_DAILY_FAIL"


export const GET_REVENUE_INOUT = "GET_REVENUE_INOUT"
export const GET_REVENUE_INOUT_SUCCESS = "GET_REVENUE_INOUT_SUCCESS"
export const GET_REVENUE_INOUT_FAIL = "GET_REVENUE_INOUT_FAIL"

export const GET_DASHBOARD_DININ = "GET_DASHBOARD_DININ"
export const GET_DASHBOARD_DININ_SUCCESS = "GET_DASHBOARD_DININ_SUCCESS"
export const GET_DASHBOARD_DININ_FAIL = "GET_DASHBOARD_DININ_FAIL"

export const GET_STATISTICS_VALUES = "GET_STATISTICS_VALUES"
export const GET_STATISTICS_VALUES_SUCCESS = "GET_STATISTICS_VALUES_SUCCESS"
export const GET_STATISTICS_VALUES_FAIL = "GET_STATISTICS_VALUES_FAIL"

export const GET_TIMELINE = "GET_TIMELINE"
export const GET_TIMELINE_SUCCESS = "GET_TIMELINE_SUCCESS"
export const GET_TIMELINE_FAIL = "GET_TIMELINE_FAIL"