import { call, put, takeEvery } from "redux-saga/effects"
import {
  POST_COMP_MANUFACTURE,
  POST_COMP_MANUFACTURE_SUCCESS,
  POST_COMP_MANUFACTURE_FAIL,
} from "./actionTypes"
import {
  postCompManufactureFail,
  postCompManufactureSuccess,
} from "./actions"
import { postCompManufacture } from "../../helpers/backend_helper"
import { toast } from "react-toastify"
import i18n from "i18n"

function* postNewCompManufacture(comp) {
  try {
    const response = yield call(postCompManufacture, comp.payload)
    if (response > 0) {
      toast.dismiss()
      toast.success(i18n.t("Component added successfully"))
      yield put(postCompManufactureSuccess(response))
    }
  } catch (err) {
    toast.dismiss()
    toast.error(i18n.t("Something Error"))
    yield put(postCompManufactureFail(err))
  }
}

function* componentManufactureSaga() {
  yield takeEvery(POST_COMP_MANUFACTURE, postNewCompManufacture)
}

export default componentManufactureSaga
