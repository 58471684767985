import { call, put, takeEvery } from "redux-saga/effects";

import {
    SET_LOGO,
} from "./actionTypes";

import {
    setLogoSuccess,
    setLogoFail
} from "./actions";

function* onGetTblStore({ payload: logo }) {
    localStorage.setItem('logo', logo);
    try {
        yield put(setLogoSuccess(logo));
    } catch (error) {
        yield put(setLogoFail(error));
    }
}

function* businessSettingsSaga() {
    yield takeEvery(SET_LOGO, onGetTblStore);
}

export default businessSettingsSaga;