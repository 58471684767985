import React, { useState, useMemo, useEffect } from "react"

import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useSelector, useDispatch } from "react-redux"
import {
  getMaterials,
  getStockTransferCheckStoreQty,
  getTblStore,
  postStockTransfer,
} from "store/actions"

import MutualSelectInput from "pages/StockTransfer/MutualSelectInput"
import StoreInput from "pages/StockTransfer/StoreInput"
import RowsTabel from "pages/StockTransfer/RowsTabel"
import ConfirmModel from "pages/StockTransfer/ConfirmModel"

const StockTransfer = props => {
  //meta title
  document.title = "Stock-Transfer | Buffalo - Admin"
  const dispatch = useDispatch()

  const [formRows, setFormRows] = useState([])
  const [selectedMaterial, setSelectedMaterial] = useState(null)
  const [selectedComponent, setSelectedComponent] = useState(null)
  const [openConfirmModel, setOpenConfirmModel] = useState(false)
  const [itemRowSubmit, setItemRowSubmit] = useState(false)
  const [addRowTransferBtnDisable, setAddRowTransferBtnDisable] =
    useState(false)
  const [stockTranferBtnDisabled, setStockTranferBtnDisabled] = useState(false)

  const materials = useSelector(state => state.materials.allMaterials)
  const materialDetails = useSelector(state => state.materials.oneMaterial)

  const stockBalanceFilteredByMaterial = useSelector(
    state => state.Reports.getStockBalanceFilteredByMaterial
  )

  const tblStore = useSelector(state => state.Reports.getTblStore)

  const {
    stockTransferCheckQtyLoading: checkStoreLoading,
    stockTransferCheckQtyRes: checkStoreQuantity,
    stockTransferAdded: stockTransferAdded,
    stockTransferAddedLoading: stockTransferAddedLoading,
  } = useSelector(state => state.stockTransfer)

  useEffect(() => {
    checkStoreLoading
      ? setAddRowTransferBtnDisable(true)
      : setAddRowTransferBtnDisable(false)
  }, [checkStoreLoading])

  useEffect(() => {
    stockTransferAddedLoading
      ? setStockTranferBtnDisabled(true)
      : setStockTranferBtnDisabled(false)
  }, [stockTransferAddedLoading])

  useEffect(() => {
    stockTransferAdded !== "" && toggleConfirmModel()
  }, [stockTransferAdded])

  // Check row material is available in selected store before adding to list
  useEffect(() => {
    if (checkStoreQuantity === 1 && itemRowSubmit) {
      const {qtyAvailableValidation: qtyAvailableValidation, rowOfSameValue: rowOfSameValue} = checkIfItemAddedBefore()

      if (!qtyAvailableValidation) {
        validation.setFieldValue("storeQtyAvailable", false, false)
        return
      }

      if(rowOfSameValue.length === 0) {

        const newRow = {
          id: Math.random().toString(36).substring(2, 10),
          qty: +validation.values.qty,
          unit: validation.values.unit,
          unitId: validation.values.unitId,
          storeFrom: stockBalanceFilteredByMaterial.find(
            one => one.PK_Store_ID === +validation.values.storeFromPkStoreId
          ),
          storeTo:
            tblStore.length &&
            tblStore
              .map(item => ({
                ...item,
                PK_Store_ID: item.pkStoreId,
                StoreName: item.storeName,
              }))
              .find(
                one => one.PK_Store_ID === +validation.values.storeToPkStoreId
              ),
        }

        
              // Attach Material || Component depend on "transferCheck"
              if (validation.values.transferCheck) {
                newRow.material = selectedMaterial
              } else {
                newRow.component = selectedComponent
              }
              newRow.itemId = validation.values.transferCheck
                ? selectedMaterial.pkMaterialId
                : selectedComponent.pkMaterialId
        
              // Add New Row
              setFormRows(prev => [...prev, newRow])

      } else {
        const formRowsCopy = [...formRows]
        formRowsCopy.map(one => one.id === rowOfSameValue[0].id && (one.qty = one.qty + validation.values.qty))
        setFormRows(formRowsCopy)
      }
      // Reset Form without transferCheck
      validation.resetForm({
        values: validation.initialValues,
      })
      setSelectedMaterial(null)
      setSelectedComponent(null)
      validation.setFieldValue(
        "transferCheck",
        validation.values.transferCheck,
        false
      )
      setItemRowSubmit(false)
    } else {
      // If Quantity not enough
      checkStoreQuantity === 0 &&
        validation.setFieldValue("storeQtyAvailable", false, false)
    }
  }, [checkStoreQuantity, itemRowSubmit])

  // Prevent add same item from same if store qty isn't available for the total of rowsTable qty
  // #1195 Stock Transfer: fix the bug of adding same item from same store multiple times and check the quantity
  const checkIfItemAddedBefore = () => {
    const selectedItem = validation.values.transferCheck
      ? selectedMaterial.pkMaterialId
      : selectedComponent.pkMaterialId

    // To check if Item added before from same store from
    const itemAlreadyAdded = formRows.length
      ? formRows.filter(
          one =>
            selectedItem === one.itemId &&
            one.storeFrom.PK_Store_ID === +validation.values.storeFromPkStoreId
        )
      : ""

    // to get store qty
    const store = stockBalanceFilteredByMaterial.find(
      one => one.PK_Store_ID === +validation.values.storeFromPkStoreId
    )
    // Calculate the total qty of same item and same store
    const totalSameItemQty = itemAlreadyAdded
      ? itemAlreadyAdded.reduce((total, qty) => total + qty.qty, 0)
      : 0

    return {
      // return true or false depend on check the total qty of same item and same store
      qtyAvailableValidation: store.Qty >= +validation.values.qty + totalSameItemQty,
      // return row of same item and store from and store to, to increase its qty instead of duplicate
      rowOfSameValue: itemAlreadyAdded.length
        ? itemAlreadyAdded.filter(
            one =>
                one.storeTo.PK_Store_ID ===
                +validation.values.storeToPkStoreId 
          )
        : "",
    }
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      transferCheck: false, // false = Component, true = Material
      selectedComponent: "",
      selectedMaterial: "",
      unit: "",
      qty: 1,
      storeFromPkStoreId: "",
      storeToPkStoreId: "",
      unitId: "",
      storeQtyAvailable: true,
    },
    validationSchema: Yup.object({
      selectedComponent: Yup.string().when("transferCheck", {
        is: false,
        then: Yup.string().required(props.t("selectComponent")),
      }),
      selectedMaterial: Yup.string().when("transferCheck", {
        is: true,
        then: Yup.string().required(props.t("Please Select Material")),
      }),
      qty: Yup.string().required(props.t("Please Enter Component Qty")),
      storeFromPkStoreId: Yup.string().required(props.t("selectStoreFrom")),
      storeToPkStoreId: Yup.string().required(props.t("selectStoreTo")),
    }),

    onSubmit: values => {
      const params = {
        storeFrom: values.storeFromPkStoreId,
        pkMaterialId: values.transferCheck
          ? selectedMaterial.pkMaterialId
          : selectedComponent.pkMaterialId,
        qty: values.qty,
      }
      setItemRowSubmit(true)
      // Checking From backEnd to check store qty is enough or not
      dispatch(getStockTransferCheckStoreQty(params))
    },
  })

  // customize material list and component list
  const { materialList, componentList } = useMemo(() => {
    if (materials) {
      return {
        materialList: materials.filter(
          item => item.materialName && !item.material
        ),
        componentList: materials.filter(
          item => item.materialName && item.material
        ),
      }
    } else {
      return {
        materialList: [],
        componentList: [],
      }
    }
  }, [materials])

  // customize store balance from and to
  const { stockBalanceFrom, stockBalanceTo } = useMemo(() => {
    if (stockBalanceFilteredByMaterial) {
      const { qty, storeFromPkStoreId } = validation.values
      return {
        stockBalanceFrom: stockBalanceFilteredByMaterial.filter(
          item => item.PK_Store_ID && item.Qty >= +qty && item.Qty !== 0
        ),
        stockBalanceTo: tblStore
          .map(item => ({
            ...item,
            PK_Store_ID: item.pkStoreId,
            StoreName: item.storeName,
          }))
          .filter(item => {
            if (storeFromPkStoreId && storeFromPkStoreId !== "") {
              return (
                item.PK_Store_ID && item.PK_Store_ID !== +storeFromPkStoreId
              )
            } else {
              return item.PK_Store_ID
            }
          }),
      }
    } else {
      return {
        stockBalanceTo: [],
        stockBalanceFrom: [],
      }
    }
  }, [
    stockBalanceFilteredByMaterial,
    tblStore,
    validation.values["qty"],
    validation.values["storeFromPkStoreId"],
  ])

  // Get All Store From Api
  useEffect(() => {
    dispatch(getTblStore())
  }, [])

  // Get All Material From Api
  useEffect(() => {
    if (materials && !materials.length) dispatch(getMaterials())
  }, [materials, dispatch])

  // set Unite
  useEffect(() => {
    // console.log(materialDetails)
    materialDetails.length &&
      validation.setFieldValue("unit", materialDetails[0].Unit, false)
    materialDetails.length &&
      validation.setFieldValue("unitId", materialDetails[0].fk_unit_ID, false)
  }, [materialDetails])

  // set Material & Component
  useEffect(() => {
    const material = selectedMaterial ? selectedMaterial.materialName : ""
    const component = selectedComponent ? selectedComponent.materialName : ""
    if (selectedMaterial)
      validation.setFieldValue("selectedMaterial", material, false)

    if (selectedComponent)
      validation.setFieldValue("selectedComponent", component, false)
  }, [selectedMaterial, selectedComponent])

  const onChangeTransferCheck = e => {
    validation.resetForm({
      values: validation.initialValues,
    })
    validation.setFieldValue("transferCheck", e.target.checked, false)
  }

  const onChangeStoreFrom = e =>
    validation.setFieldValue("storeFromPkStoreId", e.target.value, false)

  const onChangeStoreTo = e =>
    validation.setFieldValue("storeToPkStoreId", e.target.value)

  const onDeleteFormRow = id =>
    setFormRows(formRows.filter(row => row.id !== id))

  const toggleConfirmModel = () => setOpenConfirmModel(prevState => !prevState)

  // On Confirm (Submit)
  const onConfirmHandler = note => {
    const newRows = formRows.map(row => ({
      transferedQty: row.qty,
      fkUnitID: row.unitId,
      fkFromStoreID: row.storeFrom.PK_Store_ID,
      fkToStoreID: row.storeTo.PK_Store_ID,
      fkMaterialID: row.material
        ? row.material.pkMaterialId
        : row.component.pkMaterialId,
    }))
    const data = {
      stockTransfereDetails: newRows,
      stockTransfere: {
        notes: note,
        fkEmpID: JSON.parse(localStorage.getItem("authUser")).pkEmpId,
      },
    }
    dispatch(postStockTransfer(data))
    // Reset Rows
    setFormRows([])
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={props.t("transfer")}
          breadcrumbItem={props.t("stockTransfer")}
        />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <div style={{ minHeight: "350px" }}>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row className="mb-3 align-items-center">
                      <Col xs={12}>
                        <div className="form-check form-switch form-switch-md mb-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value={validation.values.transferCheck}
                            id="customSwitchsizemd"
                            onChange={onChangeTransferCheck}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customSwitchsizemd"
                          >
                            {validation.values["transferCheck"]
                              ? props.t("transferMaterial")
                              : props.t("transferComponent")}
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-end flex-wrap">
                      <Col sm={3} xs={6} className="mb-2">
                        {validation.values["transferCheck"] ? (
                          <MutualSelectInput
                            labelValue={props.t("Material")}
                            name="selectedMaterial"
                            list={materialList}
                            setSelected={setSelectedMaterial}
                            selectedValue={selectedMaterial}
                          />
                        ) : (
                          <MutualSelectInput
                            labelValue={props.t("Component")}
                            name="selectedComponent"
                            list={componentList}
                            setSelected={setSelectedComponent}
                            selectedValue={selectedComponent}
                          />
                        )}
                      </Col>
                      <Col sm={1} xs={6} className="mb-3">
                        <Label className="mb-0">{props.t("Unit")}</Label>
                        <Input
                          type="text"
                          id="unit"
                          className="form-control"
                          placeholder={props.t("Unit")}
                          value={validation.values.unit}
                          disabled
                          readOnly
                        />
                      </Col>
                      <Col sm={1} xs={6} className="mb-3">
                        <Label className="mb-0">
                          {props.t("Quantity")}{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="number"
                          id="quantity"
                          min="1"
                          name="qty"
                          className="form-control"
                          placeholder={props.t("Enter Quantity")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.qty || ""}
                          invalid={
                            validation.touched.qty && validation.errors.qty
                              ? true
                              : false
                          }
                        />
                      </Col>
                      <StoreInput
                        labelValue={props.t("storeFrom")}
                        name="storeFromPkStoreId"
                        touched={validation.touched.storeFromPkStoreId}
                        errors={validation.errors.storeFromPkStoreId}
                        selectStore={validation.values.storeFromPkStoreId}
                        validation={validation}
                        storeList={stockBalanceFrom}
                        check={!!selectedMaterial || !!selectedComponent}
                        onChange={onChangeStoreFrom}
                      />
                      <StoreInput
                        labelValue={props.t("storeTo")}
                        name="storeToPkStoreId"
                        touched={validation.touched.storeToPkStoreId}
                        errors={validation.errors.storeToPkStoreId}
                        selectStore={validation.values.storeToPkStoreId}
                        validation={validation}
                        storeList={stockBalanceTo}
                        check={!!selectedMaterial || !!selectedComponent}
                        onChange={onChangeStoreTo}
                      />
                      <Col sm={1} xs={6}>
                        <div className="text-end pt-3">
                          <Button
                            type="submit"
                            className="btn btn-success mb-3 mt-lg-0 px-1"
                            disabled={addRowTransferBtnDisable}
                          >
                            {addRowTransferBtnDisable ? (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            ) : (
                              <i className="mdi mdi-check-circle-outline me-1" />
                            )}
                            {props.t("Add")}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        {!validation.values.storeQtyAvailable && (
                          <span className="text-danger">
                            {props.t("materialQtyNotAvailable")}
                          </span>
                        )}
                        <span style={{ color: "#cd0000" }}>
                          {Object.keys(validation.errors).length > 0
                            ? validation.errors[
                                Object.keys(validation.errors)[0]
                              ]
                            : ""}
                        </span>
                      </Col>
                    </Row>
                    <hr
                      style={{
                        borderTop: "1px solid #d5d5d5",
                        margin: "3px 0",
                      }}
                    />
                  </Form>
                  <div style={{ maxHeight: "350px", overflowY: "auto" }}>
                    {formRows.length ? (
                      <RowsTabel
                        rows={formRows}
                        onDeleteFormRow={onDeleteFormRow}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <hr style={{ borderTop: "1px solid #d5d5d5" }} />
                <div className="d-flex flex-1 justify-content-end">
                  <Button
                    onClick={toggleConfirmModel}
                    disabled={formRows.length === 0}
                    style={
                      formRows.length !== 0
                        ? {
                            backgroundColor: "#FB940E",
                            border: "none",
                          }
                        : { backgroundColor: "#fcb961" }
                    }
                  >
                    {props.t("Submit")}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* confirm Model */}
        <ConfirmModel
          toggle={toggleConfirmModel}
          isOpen={openConfirmModel}
          onConfirm={onConfirmHandler}
          stockTranferBtnDisabled={stockTranferBtnDisabled}
        />
      </Container>
    </div>
  )
}

StockTransfer.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(StockTransfer))
