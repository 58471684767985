import axiosInstance from "../axios/axiosInstance"
import axiosInstance2 from "../axios/axiosInstance2"

export async function get(url, config = {}) {
  if (localStorage.getItem("branchName") === "moq") {
    return await axiosInstance
      .get(url, { ...config })
      .then(response => response.data)
  } else if (localStorage.getItem("branchName") === "ezdan") {
    return await axiosInstance2
      .get(url, { ...config })
      .then(response => response.data)
  } else {
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    localStorage.removeItem("shiftID");
    localStorage.removeItem("branchName");
  }
}

export async function post(url, data, config = {}) {
  if (localStorage.getItem("branchName") === "moq") {
    return await axiosInstance.post(url, data).then(response => response)
  } else if (localStorage.getItem("branchName") === "ezdan") {
    return await axiosInstance2.post(url, data).then(response => response)
  } else {
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    localStorage.removeItem("shiftID");
    localStorage.removeItem("branchName");
  }
}
