import React, { useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

const ConfirmModel = ({
  isOpen,
  toggle,
  t,
  onConfirm,
  stockTranferBtnDisabled,
}) => {
  const [note, setNote] = useState("")

  const onConfirmHandler = () => {
    onConfirm(note)
    setNote("")
  }

  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {t("confirmStockTransferHeader")}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={12}>
            <p className="fw-bold font-size-14">{t("confirmParagraph")} </p>
          </Col>
          <Col xs={12}>
            <Label htmlFor="note" className="col-form-label">
              {t("optionalNote")}
            </Label>
            <Input
              id="note"
              name="note"
              type="textarea"
              placeholder={t("Enter Notes")}
              onChange={e => setNote(e.target.value)}
              value={note}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="success" onClick={onConfirmHandler}>
          {stockTranferBtnDisabled ? (
            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          ) : (
            <i className="mdi mdi-check-circle-outline me-1" />
          )}{" "}
          {t("confirm")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

ConfirmModel.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ConfirmModel))
