import React from "react"
import i18n from "i18next"
import { Col, Row } from "reactstrap"

const FilterInput = props => {
  const { allListSize, setNumberToShowHandler } = props
  const [numberToShow, setNumberToShow] = React.useState("all")

  const onChangeInSelect = event => {
    let value
    const targetValue = event.target.value
    if (targetValue === "all") {
      value = +allListSize
      setNumberToShow(`all`)
    } else {
      value = +targetValue
      setNumberToShow(value)
    }
    setNumberToShowHandler(value)
  }

  return (
    <Row>
      <Col xs={6} sm={3} md={2}>
        <select
          className="form-select w-5"
          value={numberToShow}
          onChange={onChangeInSelect}
        >
          {[
            { value: 5, label: "5" },
            { value: 10, label: "10" },
            { value: 20, label: "20" },
            { value: 30, label: "30" },
            { value: "all", label: i18n.t("All") },
          ].map(pageSize => (
            <option key={pageSize.value} value={pageSize.value}>
              {pageSize.label === i18n.t("All")
                ? i18n.t("Show")
                : i18n.t("Filter Top")}{" "}
              {pageSize.label}
            </option>
          ))}
        </select>
      </Col>
    </Row>
  )
}

export default FilterInput
