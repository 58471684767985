import React, { useState, useEffect } from "react"

import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import Select from "react-select"
import { useFormik } from "formik"
import * as Yup from "yup"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import useSelectInputErrMsg from "pages/Products/hooks/use-selectInputErrMsg"
import SelectInputErrorMessage from "pages/Products/SelectInputErrorMessage"
import { selectInputBgColors, layoutModeTypes } from "constants/layout"
import classes from "../Products/index.module.scss"
import { getMaterials, getOneComponent, getTblStore, postCompManufacture } from "store/actions"
import ComponentModal from "pages/Products/modals/ComponentModal"
import ComponentMaterials from "./ComponentMaterials"

const Component = props => {
  const dispatch = useDispatch()
  const { errorMsgs, sets, states } = useSelectInputErrMsg({ t: props.t })

  const [componentModal, setComponentModal] = useState(false)
  const [componentsList, setComponentsList] = useState([])
  const [compAdded, setCompAdded] = useState(false)
  const [selectedComponent, setSelectedComponent] = useState("")
  const [selectedComponentDetails, setSelectedComponentDetails] = useState({})
  const [selectedCompInitDetails, setSelectedCompinitDetails] = useState({})
  const [selectedCompItsDetails, setSelectedCompItsDetails] = useState({
    compUnit: "",
    compQuantity: "",
  })
  const [selectStore, setSelectStore] = useState("")
  const [storeList, setStoreList] = useState([])
  const [matQtyForAComp, setMatQtyForAComp] =useState([])
  const [materialToSend, setMaterialToSend] = useState([])

  const { layoutModeType } = useSelector(state => state.Layout)
  const materials = useSelector(state => state.materials.allMaterials)
  const newComponentAdded = useSelector(
    state => state.components.newComponentAdded
  )

  const oneComponentDetails = useSelector(state => state.materials.oneComponent)
  const reports = useSelector(state => state.Reports)

  const { getTblStore: getTblStoreData } = reports

  useEffect(() => {
    setComponentModal(false)
  }, [])

  // Get All Material From Api
  useEffect(() => {
    if (materials && !materials.length) {
      dispatch(getMaterials())
    }
  }, [materials, dispatch])

  useEffect(() => {
    dispatch(getTblStore())
  }, [dispatch])

  useEffect(() => {
    setStoreList(getTblStoreData)
  }, [getTblStoreData])

  useEffect(() => {
    handleComponenetList()
  }, [materials])

  useEffect(() => {
    setSelectedComponentDetails({})
    setSelectedComponentDetails(oneComponentDetails)
    setSelectedCompinitDetails(oneComponentDetails)
    oneComponentDetails.length &&
      setSelectedCompItsDetails({
        compUnit: oneComponentDetails[0].CompUnit,
        compQuantity: oneComponentDetails[0].MokQty,
      })
  }, [oneComponentDetails])

  // Calculate each material Quantity of selected component for Qty 1 component
  useEffect(() => {
    if (selectedCompInitDetails.length > 0) {
      const initCopy = [...selectedCompInitDetails]
      const matsQtysForOneComp = []
      initCopy.map(item => matsQtysForOneComp.push(item.Qty / item.MokQty))
      setMatQtyForAComp(matsQtysForOneComp)
    }
  }, [selectedCompInitDetails])

  const handleComponenetList = () => {
    let componentListCopy = []
    materials &&
      materials.map(item => {
        item.materialName && item.material && componentListCopy.push(item)
      })
    setComponentsList(componentListCopy)
  }

  // Handle Add Component change state newComponentAdded to hit useEffect to select comp to added one
  useEffect(() => {
    if (newComponentAdded) {
      setCompAdded(true)
    }
  }, [newComponentAdded, componentsList])

  // useEffect to auto select comp the new added one
  useEffect(() => {
    if (compAdded) {
      componentsList.map(
        item =>
          item.pkMaterialId === newComponentAdded && setSelectedComponent(item)
      )
      setCompAdded(false)
    }
  }, [compAdded])

  const handleComponentModal = () => {
    setComponentModal(true)
  }
  const closeComponentModal = () => {
    setComponentModal(false)
  }
  const handleComponentToggle = () => {
    setComponentModal(!componentModal)
  }

  const handleSelectedComponent = e => {
    setSelectedComponentDetails({})
    setSelectedCompItsDetails({ compUnit: "", compQuantity: "" })
    setSelectedComponent(e)
  }

  const handleCompQtyChanged = e => {
    if(Object.keys(selectedComponent).length > 0){
      
      setSelectedCompItsDetails({
        ...selectedCompItsDetails,
        compQuantity: e,
      })
      handleCompMaterialListQty(e)
    } else {
      sets.setIngrediantModalShowComponentErrMsg(true)
    }
  }

  const handleCompMaterialListQty = e => {
    let initCopy = [...selectedComponentDetails]
    // To set new value of Qty According to main component Qty
    initCopy.map((item, i) => (item.Qty = matQtyForAComp[i] * e))
    setSelectedComponentDetails(initCopy)
  }

  useEffect(() => {
    setSelectedCompItsDetails({ compUnit: "", compQuantity: "" })
    setSelectedComponentDetails({})
    selectedComponent.pkMaterialId &&
      dispatch(getOneComponent(selectedComponent.pkMaterialId))
  }, [selectedComponent])

  const componentValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectedComponent: selectedComponent || "",
      componentId: (selectedComponent && selectedComponent.pkMaterialId) || "",
      componentQuantity: selectedCompItsDetails.compQuantity || "",
      selectStore: selectStore || "",
    },
    validationSchema: Yup.object({
      selectedComponent: Yup.object().required(
        props.t("Please Select Material")
      ),
      componentQuantity: Yup.number().required(
        props.t("Please Enter Quantity")
      ),
      selectStore: Yup.number().required(props.t("Please Select Store")),
    }),
    onSubmit: values => {
      resetForm();
      const data = {
        "componentId": values.componentId,
        "componentQty": values.componentQuantity,
        "toStoreId": Number(values.selectStore),
        "unitId": values.selectedComponent.fkUnitId ? values.selectedComponent.fkUnitId : null,
        "materialComponent": materialToSend,
        "fK_Emp_ID": JSON.parse(localStorage.getItem("authUser")).pkEmpId
      }
  
      dispatch(postCompManufacture(data))
    },
  })

  function resetForm() {
    setSelectedComponent("")
    setSelectedCompItsDetails({ compUnit: "", compQuantity: "" })
    sets.setIngrediantModalShowComponentErrMsg(false)
    setSelectStore("")
  }

  const handleMaterialsToSend =(e) => {
    setMaterialToSend(e)
    componentValidation.handleSubmit()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Component")}
            breadcrumbItem={props.t("Component Manufacturing")}
          />
          <Form
            onSubmit={e => {
              e.preventDefault()
              componentValidation.handleSubmit()
              // if select field are empty make sure to show error msg
              sets.setIngrediantModalShowComponentErrMsg(true)

              return false
            }}
          >
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody style={{ minHeight: "45vh" }}>
                    <Row>
                      <Col sm={4}>
                        <div className="mb-3">
                          <Label>{props.t("Component")}<span className="text-danger">*</span></Label>
                          <div className="input-group">
                            <Select
                              value={
                                selectedComponent !== ""
                                  ? componentsList.find(function (option) {
                                      return selectedComponent
                                        ? option.pkMaterialId ===
                                            Number(
                                              selectedComponent.pkMaterialId
                                            )
                                        : ""
                                    })
                                  : ""
                              }
                              onChange={e => {
                                handleSelectedComponent(e)
                              }}
                              options={componentsList}
                              className={`select2-selection form-control 
                              ${
                                states.ingrediantModalShowComponentErrMsg &&
                                !selectedComponent &&
                                classes.red_border
                              } 
                              ${classes.input_with_btn}`}
                              getOptionLabel={componentsList =>
                                componentsList["materialName"]
                              }
                              getOptionValue={componentsList =>
                                componentsList["pkMaterialId"]
                              }
                              onFocus={() =>
                                sets.setIngrediantModalShowComponentErrMsg(
                                  false
                                )
                              }
                              onBlur={() =>
                                sets.setIngrediantModalShowComponentErrMsg(true)
                              }
                              styles={{
                                menu: base => ({
                                  ...base,
                                  backgroundColor:
                                    layoutModeType === layoutModeTypes.LIGHT
                                      ? selectInputBgColors.light
                                      : selectInputBgColors.dark,
                                }),
                              }}
                            />
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={handleComponentModal}
                            >
                              {props.t("Add")}
                            </button>
                          </div>
                          {states.ingrediantModalShowComponentErrMsg &&
                            !selectedComponent && (
                              <SelectInputErrorMessage>
                                {errorMsgs.component}
                              </SelectInputErrorMessage>
                            )}
                        </div>
                      </Col>
                      <Col sm={2}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Unit")}
                          </Label>
                          <Input
                            name="componentUnit"
                            type="text"
                            placeholder=""
                            onChange={e => e.preventDefault()}
                            value={selectedCompItsDetails.compUnit}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col sm={2}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Quantity")}<span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="componentQuantity"
                            type="number"
                            placeholder={props.t("Enter Quantity")}
                            onChange={e => handleCompQtyChanged(e.target.value)}
                            onBlur={componentValidation.handleBlur}
                            value={
                              componentValidation.values.componentQuantity || ""
                            }
                            invalid={
                              componentValidation.touched.componentQuantity &&
                              componentValidation.errors.componentQuantity
                                ? true
                                : false
                            }
                            min={1}
                          />
                          {componentValidation.touched.componentQuantity &&
                          componentValidation.errors.componentQuantity ? (
                            <FormFeedback type="invalid">
                              {componentValidation.errors.componentQuantity}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("To Store")}<span className="text-danger">*</span>
                          </Label>
                            <Input
                              name="selectStore"
                              type="select"
                              onChange={e => {
                                setSelectStore(e.target.value)
                              }}
                              onBlur={componentValidation.handleBlur}
                              value={
                                componentValidation.values.selectStore || ""
                              }
                              invalid={
                                componentValidation.touched.selectStore &&
                                componentValidation.errors.selectStore
                                  ? true
                                  : false
                              }
                            >
                              <option value="">
                                {props.t("Select Store Name")}
                              </option>
                              {storeList.map((store, index) => (
                                <option key={index} value={store.pkStoreId}>
                                  {store.storeName}
                                </option>
                              ))}
                            </Input>

                            
                            {componentValidation.touched.selectStore &&
                            componentValidation.errors.selectStore ? (
                              <FormFeedback type="invalid">
                                {componentValidation.errors.selectStore}
                              </FormFeedback>
                            ) : null}
                        </div>
                      </Col>
                    </Row>
                    <hr
                      style={{
                        borderTop: "1px solid #d5d5d5",
                        margin: "3px",
                      }}
                    />
                    <ComponentMaterials
                      storeList={storeList}
                      selectedComponentDetails={selectedComponentDetails}
                      handleMaterialsToSend={handleMaterialsToSend}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
      <ComponentModal
        componentModal={componentModal}
        handleComponentToggle={handleComponentToggle}
        componentList= {componentsList}
        closeComponentModal={closeComponentModal}
      />
    </React.Fragment>
  )
}

Component.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Component))
