import PropTypes from "prop-types"
import React, { useState, useEffect, useCallback } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect, useDispatch, useSelector } from "react-redux"
import classes from "./index.module.scss"
import { toggleLeftmenu, getMainPrivillage } from "../../store/actions"
import { privilegePageId } from "common/privilege"

const Navbar = props => {
  const [mainPrivillage, setMainPrivillage] = useState([])
  const [dashboard, setdashboard] = useState(false)

  const [component, setcomponent] = useState(false)
  const [componentEndShift, setcomponentEndShift] = useState(false)
  const [transfer, setTransfer] = useState(false)

  const mainPrivillageData = useSelector(state => state.privillage.mainPrivillageData)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getMainPrivillage(JSON.parse(localStorage.getItem("authUser")).pkEmpId))
  }, [])

  useEffect(() => {
    setMainPrivillage(mainPrivillageData)
  }, [mainPrivillageData])

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    removeActivation(items)
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement
      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active")
        }
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  const closeLeftmenu = useCallback(() => {
    props.toggleLeftmenu(false)
  }, [])

  const closeModel = useCallback(() => {
    closeLeftmenu()
    setcomponentEndShift(false)
    setcomponent(false)
    setTransfer(false)
  }, [])



  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.dashboard) && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={closeLeftmenu}
                      to="/dashboard"
                    >
                      <i className="bx bxs-dashboard mx-2"></i>
                      Dashboard {props.menuOpen}
                    </Link>
                  </li>
                )}
                {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.products) && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={closeLeftmenu}
                      to="/products"
                    >
                      <i className="bx bxs-cart mx-2"></i>
                      {props.t("Products")} {props.menuOpen}
                      {/* <div className="arrow-down"></div> */}
                    </Link>
                  </li>
                )}
                {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.purchase) && <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={closeLeftmenu}
                    to="/purchase"
                  >
                    <i className="bx bxs-purchase-tag-alt mx-2"></i>
                    {props.t("Purchase")} {props.menuOpen}
                    {/* <div className="arrow-down"></div> */}
                  </Link>
                </li>}
                {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.expenses) && <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={closeLeftmenu}
                    to="/expenses"
                  >
                    <i className="bx bx-dollar mx-2"></i>
                    {props.t("Expenses")} {props.menuOpen}
                    {/* <div className="arrow-down"></div> */}
                  </Link>
                </li>}

                {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.mainTransfer) && <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setTransfer(!transfer)
                    }}
                  >
                    <i className="bx bx-transfer-alt mx-2"></i>
                    {props.t("transfer")} <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", {
                      show: transfer,
                    })}
                  >
                    {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.safeTransfer) && <div>
                      <Link
                        onClick={closeModel}
                        to="/safe-transfer"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Safe Transfer")}
                      </Link>
                    </div>}
                    {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.stockTransfer) && <div>
                      <Link
                        onClick={closeModel}
                        to="/stock-transfer"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("stockTransfer")}
                      </Link>
                    </div>}
                  </div>
                </li>}

                {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.component) && <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={closeLeftmenu}
                    to="/component"
                  >
                    <i className="mdi mdi-apple-keyboard-shift mx-2"></i>
                    {props.t("Component")}
                    {/* <div className="arrow-down"></div> */}
                  </Link>
                </li>}

                {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.mainShift) && 
                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setcomponentEndShift(!componentEndShift)
                    }}
                  >
                    <i className="bx bx-collection mx-2"></i>
                    {props.t("End Shift")} <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", {
                      show: componentEndShift,
                    })}
                  >
                    {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.endShift) && <div>
                      <Link
                        onClick={closeModel}
                        to="/end-shift"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("End Shift")}
                      </Link>
                    </div>}
                    {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.endShiftReport) && <div>
                      <Link
                        onClick={closeModel}
                        to="/end-shift-report"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("End Shift Report")}
                      </Link>
                    </div>}
                  </div>
                </li>}

                {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.mainReport) && <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setcomponent(!component)
                    }}
                  >
                    <i className="bx bx-collection mx-2"></i>
                    {props.t("Reports")} <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: component })}
                  >
                    {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.stockBalance) && <div>
                      <Link
                        onClick={closeModel}
                        to="/reports/stock-balance"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Stock Balance")}
                      </Link>
                    </div>}
                    {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.safeBalance) && <div>
                      <Link
                        onClick={closeModel}
                        to="/reports/safe-balance"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Safe Balance")}
                      </Link>
                    </div>}
                    {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.supplierPayment) && <div>
                      <Link
                        onClick={closeModel}
                        to="/reports/supplier-payment"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Supplier Payment")}
                      </Link>
                    </div>}
                    {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.netProfit) && <div>
                      <Link
                        onClick={closeModel}
                        to="/reports/net-profit"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Net Profit")}
                      </Link>
                    </div>}
                    {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.monthlyReport) && <div>
                      <Link
                        onClick={closeModel}
                        to="/reports/monthly-report"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Monthly Report")}
                      </Link>
                    </div>}
                    {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.dailyReport) && <div>
                      <Link
                        onClick={closeModel}
                        to="/reports/daily-report"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Daily Report")}
                      </Link>
                    </div>}
                    {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.supplierAccount) && <div>
                      <Link
                        onClick={closeModel}
                        to="/reports/supplier-account"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Supplier Account")}
                      </Link>
                    </div>}
                    {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.expenseReport) && <div>
                      <Link
                        onClick={closeModel}
                        to="/reports/expense-report"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Expense Report")}
                      </Link>
                    </div>}
                    {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.itemsSalesReport) && <div>
                      <Link
                        onClick={closeModel}
                        to="/reports/items-sales-report"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Items Sales Reports")}
                      </Link>
                    </div>}
                    {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.shiftReport) && <div>
                      <Link
                        onClick={closeModel}
                        to="/reports/shift-report"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Shift Reports")}
                      </Link>
                    </div>}
                    {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.safeTransferReport) && <div>
                      <Link
                        onClick={closeModel}
                        to="/reports/safe-transfer-report"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Safe Transfer Report")}
                      </Link>
                    </div>}
                    {mainPrivillage.some((privilege) => privilege.PK_Page_ID === privilegePageId.stockTransferReport) && <div>
                      <Link
                        onClick={closeModel}
                        to="/reports/stock-transfer-report"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Stock Transfer Report")}
                      </Link>
                    </div>}
                  </div>
                </li>}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
      {/* overlay  */}
      {props.leftMenu && (
        <div
          className={`rightbar-overlay z-1 ${classes.leftMenu_overlay}`}
          onClick={closeLeftmenu}
        />
      )}
      {component && (
        <div className="rightbar-overlay z-1" onClick={closeModel} />
      )}
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {
    toggleLeftmenu,
  })(withTranslation()(Navbar))
)
