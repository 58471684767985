import React, { useState } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import {
  Container,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";

import { useNavigate } from "react-router-dom";

import classnames from "classnames";

import FilterByShiftId from "./FilterByShiftId";
import FilterByDate from "./FilterByDate";

const EndShiftReport = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const naviagte = useNavigate();

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Shift")}
            breadcrumbItem={props.t("End Shift Report")}
          />
          <Card>
            <CardBody>
              <Nav tabs className="nav-tabs-custom" role="tablist">
                {[{ activeTab: "1", content: props.t('Filter By Shift Id') },
                { activeTab: "2", content: props.t('Filter By Date') }].map((one, i) =>
                  <NavItem key={i}>
                    <NavLink
                      className={classnames({
                        active: activeTab === one.activeTab,
                      })}
                      onClick={() => {
                        naviagte('/end-shift-report');
                        toggleTab(one.activeTab);
                      }}
                    >
                      {one.content}
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
              <TabContent
                activeTab={activeTab}
                className="crypto-buy-sell-nav-content"
              >
                <FilterByShiftId />
                <FilterByDate />
              </TabContent>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

EndShiftReport.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(EndShiftReport));