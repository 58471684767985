import {
  POST_STOCKTRANSFER,
  POST_STOCKTRANSFER_SUCCESS,
  POST_STOCKTRANSFER_FAIL,
  GET_STOCKTRANSFER_CHECKSTOREQTY,
  GET_STOCKTRANSFER_CHECKSTOREQTY_FAIL,
  GET_STOCKTRANSFER_CHECKSTOREQTY_SUCCESS,
  GET_STOCKTRANSFER,
  GET_STOCKTRANSFER_SUCCESS,
  GET_STOCKTRANSFER_FAIL,
  GET_STOCKTRANSFER_ID,
  GET_STOCKTRANSFER_ID_SUCCESS,
  GET_STOCKTRANSFER_ID_FAIL
} from "./actionTypes"

const postStockTransfer = data => ({
  type: POST_STOCKTRANSFER,
  payload: data,
})

const postStockTransferSuccess = response => ({
  type: POST_STOCKTRANSFER_SUCCESS,
  payload: response,
})

const postStockTransferFail = error => ({
  type: POST_STOCKTRANSFER_FAIL,
  payload: error,
})

const getStockTransferCheckStoreQty = params => ({
  type: GET_STOCKTRANSFER_CHECKSTOREQTY,
  payload: params,
})

const getStockTransferCheckStoreQtySuccess = response => ({
  type: GET_STOCKTRANSFER_CHECKSTOREQTY_SUCCESS,
  payload: response,
})

const getStockTransferCheckStoreQtyFail = error => ({
  type: GET_STOCKTRANSFER_CHECKSTOREQTY_FAIL,
  payload: error,
})

const getStockTransfer = () => ({
  type: GET_STOCKTRANSFER
})

const getStockTransferSuccess = response => ({
  type: GET_STOCKTRANSFER_SUCCESS,
  payload: response,
})

const getStockTransferFail = error => ({
  type: GET_STOCKTRANSFER,
  payload: error,
})

const getStockTransferById = params => ({
  type: GET_STOCKTRANSFER_ID,
  payload: params
})

const getStockTransferByIdSuccess = response => ({
  type: GET_STOCKTRANSFER_ID_SUCCESS,
  payload: response,
})

const getStockTransferByIdFail = error => ({
  type: GET_STOCKTRANSFER_ID_FAIL,
  payload: error,
})

export {
  postStockTransfer,
  postStockTransferSuccess,
  postStockTransferFail,
  getStockTransferCheckStoreQty,
  getStockTransferCheckStoreQtySuccess,
  getStockTransferCheckStoreQtyFail,
  getStockTransfer,
  getStockTransferSuccess,
  getStockTransferFail,
  getStockTransferById,
  getStockTransferByIdFail,
  getStockTransferByIdSuccess
}
