/* Get Items */
export const GET_ITEMS = "GET_ITEMS"
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS"
export const GET_ITEMS_FAIL = "GET_ITEMS_FAIL"

/*Post Item*/
export const POST_ITEM = "POST_ITEM"
export const POST_ITEM_SUCCESS = "POST_ITEM_SUCCESS"
export const POST_ITEM_FAIL = "POST_ITEM_FAIL"

// Post Item Img
export const POST_ITEM_IMG = "POST_ITEM_IMG"
export const POST_ITEM_IMG_SUCCESS = "POST_ITEM_IMG_SUCCESS"
export const POST_ITEM_IMG_FAIL = "POST_ITEM_IMG_FAIL"

// Post Item Note
export const POST_ITEM_NOTE = "POST_ITEM_NOTE"
export const POST_ITEM_NOTE_SUCCESS = "POST_ITEM_NOTE_SUCCESS"
export const POST_ITEM_NOTE_FAIL = "POST_ITEM_NOTE_FAIL"