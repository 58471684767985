import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import {
  Card,
  CardBody,
  UncontrolledTooltip,
  Button,
  Row,
  Input,
  Label,
  Spinner,
  Col,
} from "reactstrap"
import TableContainer from "components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"
import { convertParametersToString } from "../helpers"

import { useSelector, useDispatch } from "react-redux"
import { getShiftReport } from "store/actions"

const FilterByShift = props => {

  const [shiftReportDataList, setShiftReportDataList] = useState([])
  const payTypeList = [
    { name: props.t("all"), value: 0 },
    { name: props.t("Cash"), value: 1 },
    { name: props.t("Visa"), value: 2 },
    { name: props.t("Mix"), value: 3 },
    { name: props.t("Company"), value: 4 },
  ]
  const dispatch = useDispatch()
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      shiftID: "",
      payType: 0,
    },
    validationSchema: Yup.object({
      shiftID: Yup.number(),
      payType: Yup.number(),
    }),
    onSubmit: values => {
      //
    },
  })

  const reports = useSelector(state => state.Reports)
  const { getShiftReport: shiftReportData, loadingShiftReport: loading } =
    reports

    useEffect(() => {
      
      validation.values.shiftID === "" ? setShiftReportDataList([]) : setShiftReportDataList(shiftReportData)
      handleFilterByShiftIdChange(validation.values.shiftID)
      resetDatePeriodDate()
    }, [shiftReportData, validation.values.shiftID])

    useEffect(() => {
      setShiftReportDataList([])
    }, [props.resetdata])
    
    const resetDatePeriodDate = () => {
      props.resetStartEndDate()
    }

    function handleFilterByShiftIdChange(e) {
      props.handleFilterByShiftId(e)
    }

  useEffect(() => {
    if (!validation.values.shiftID) return
    let params = {}
    if (validation.values.shiftID) {
      params.shiftID = validation.values.shiftID
      Number(validation.values.payType) !== 0 ? (params.PayType = validation.values.payType) : delete params["PayType"];
    }
    const parameters = convertParametersToString(params)
    const timer = setTimeout(() => {
      dispatch(getShiftReport(parameters))
    }, 1000)
    return () => clearTimeout(timer)
  }, [validation.values.shiftID, validation.values.payType])

  const columns = useMemo(
    () => [
      {
        Header: props.t("No"),
        accessor: "PK_OrderMaster_ID",
        filterable: true,
      },
      {
        Header: props.t("Order Type"),
        accessor: "OrderType",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("Total"),
        accessor: "TotalPrice",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
      {
        Header: props.t("After Discount"),
        accessor: "totalafterDiscount",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
      {
        Header: props.t("Waiter name"),
        accessor: "waiter_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {cellProps.row.original.OrderType === "Din in"
                ? cellProps.value
                : ""}
            </>
          )
        },
      },
      {
        Header: props.t("payType"),
        accessor: "PayType",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("View"),
        Cell: cellProps => {
          const { PK_OrderMaster_ID } = cellProps.row.original
          return (
            <div className="list-unstyled hstack gap-1 mb-0">
              <li>
                <Button
                  type="button"
                  className="btn btn-sm btn-soft-primary"
                  onClick={() =>
                    props.toggleViewModalHandler(PK_OrderMaster_ID)
                  }
                >
                  <i className="mdi mdi-eye-outline" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    {props.t("View")}
                  </UncontrolledTooltip>
                </Button>
              </li>
            </div>
          )
        },
      },
    ],
    [shiftReportDataList, props.t]
  )

  return (
    <>
      <Card>
        <CardBody className="border-bottom ml-2">
          <Row>
            <Col sm={6}>
              <div className="mb-3">
                <Label htmlFor="shiftID">{props.t("Shift Id")}</Label>
                <div className="col-auto">
                  <Input
                    id="shiftID"
                    name="shiftID"
                    type="number"
                    className="form-control"
                    placeholder={props.t("Enter Shift Id")}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.shiftID || ""}
                    invalid={
                      validation.touched.shiftID && validation.errors.shiftID
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
            </Col>
            <Col sm={5}>
              <div className="mb-3">
                <Label>{props.t("payType")}</Label>
                <Input
                  name="payType"
                  type="select"
                  value={validation.values.payType}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                >
                  {/* <option value="" disabled>
                    {props.t("Select Safe From")}
                  </option> */}
                  {payTypeList.map((payType, index) => (
                    <option key={index} value={payType.value}>
                      {payType.name}
                    </option>
                  ))}
                </Input>
              </div>
            </Col>
            <Col sm={1}>
              <Label>{props.t("emptyString")}</Label>
              <div className="py-2">
                <i className="bx bx-filter align-middle bx-sm"></i>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardBody>
          {loading ? (
            <div className="text-center py-5">
              <Spinner className="ms-2" color="primary" />
            </div>
          ) : shiftReportDataList && shiftReportDataList.Table1 ? (
            <TableContainer
              columns={columns}
              data={
                shiftReportDataList && shiftReportDataList.Table1
                  ? shiftReportDataList.Table1
                  : []
              }
              isGlobalFilter={false}
              customPageSize={5}
              allListSize={
                shiftReportData && shiftReportData.Table1
                  ? shiftReportData.Table1.length
                  : 0
              }
              customPageSizeOptions={2}
            />
          ) : (
            <></>
          )}
        </CardBody>
      </Card>
    </>
  )
}

FilterByShift.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(FilterByShift))
