import React from "react"

import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import { Col, Input, Label } from "reactstrap"

const StoreInput = ({
  labelValue,
  name,
  touched,
  errors,
  storeList,
  selectStore,
  t,
  check,
  onChange,
}) => {
  return (
    <Col sm={3}>
      <div className="mb-3">
        <Label className="form-label mb-0">
          {labelValue}
          <span className="text-danger">*</span>
        </Label>
        <Input
          name={name}
          type="select"
          onChange={onChange}
          value={selectStore || ""}
          invalid={touched && errors ? true : false}
        >
          <option value="">{t("Select Store Name")}</option>
          {check ? (
            storeList.length ? (
              storeList.map((store, index) => (
                <option key={index} value={store.PK_Store_ID}>
                  {store.StoreName}
                </option>
              ))
            ) : (
              <option disabled className="fst-italic">
                {t("Quantity not available")}...
              </option>
            )
          ) : null}
        </Input>
      </div>
    </Col>
  )
}

StoreInput.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(StoreInput))
