import {
  GET_TBL_STORE,
  GET_TBL_STORE_SUCCESS,
  GET_TBL_STORE_FAIL,
  GET_TBL_MATERIAL,
  GET_TBL_MATERIAL_SUCCESS,
  GET_TBL_MATERIAL_FAIL,
  GET_TBL_EMP,
  GET_TBL_EMP_SUCCESS,
  GET_TBL_EMP_FAIL,
  GET_STOCK_BALANCE_FILTERED_BY_STOCK,
  GET_STOCK_BALANCE_FILTERED_BY_STOCK_SUCCESS,
  GET_STOCK_BALANCE_FILTERED_BY_STOCK_FAIL,
  GET_STOCK_BALANCE_FILTERED_BY_MATERIAL,
  GET_STOCK_BALANCE_FILTERED_BY_MATERIAL_SUCCESS,
  GET_STOCK_BALANCE_FILTERED_BY_MATERIAL_FAIL,
  GET_SAFE_BALANCE,
  GET_SAFE_BALANCE_SUCCESS,
  GET_SAFE_BALANCE_FAIL,
  GET_NET_PROFIT,
  GET_NET_PROFIT_SUCCESS,
  GET_NET_PROFIT_FAIL,
  GET_DAY_TRANS,
  GET_DAY_TRANS_SUCCESS,
  GET_DAY_TRANS_FAIL,
  GET_MONTH_TRANS,
  GET_MONTH_TRANS_SUCCESS,
  GET_MONTH_TRANS_FAIL,
  GET_SUPPLIER_ACCOUNT,
  GET_SUPPLIER_ACCOUNT_SUCCESS,
  GET_SUPPLIER_ACCOUNT_FAIL,
  GET_ITEMS_SALES,
  GET_ITEMS_SALES_SUCCESS,
  GET_ITEMS_SALES_FAIL,
  GET_EXPENSE_REPORT,
  GET_EXPENSE_REPORT_SUCCESS,
  GET_EXPENSE_REPORT_FAIL,
  GET_SUPPLIER_PAYMENTS,
  GET_SUPPLIER_PAYMENTS_SUCCESS,
  GET_SUPPLIER_PAYMENTS_FAIL,
  GET_SHIFT_REPORT,
  GET_SHIFT_REPORT_SUCCESS,
  GET_SHIFT_REPORT_FAIL,
  GET_ONE_SAFE_DATA,
  GET_ONE_SAFE_DATA_SUCCESS,
  GET_ONE_SAFE_DATA_FAIL,
  GET_SAFE_TRANSFER_SUCCESS,
  GET_SAFE_TRANSFER_FAIL,
  GET_SAFE_TRANSFER,
  POST_SUPPLIER_PAYMENT,
  POST_SUPPLIER_PAYMENT_SUCCESS,
  POST_SUPPLIER_PAYMENT_FAIL
} from "./actionTypes";

const INIT_STATE = {
  loadingTblStore: false,
  loadingTblMaterial: false,
  loadingTblEmp: false,
  loadingStockBalanceFilteredByStock: false,
  loadingStockBalanceFilteredByMaterial: false,
  loadingSafeBalance: false,
  loadingNetProfit: false,
  loadingDayTrans: false,
  loadingMonthTrans: false,
  loadingSupplierAccount: false,
  loadingItemsSales: false,
  loadingExpenseReport: false,
  loadingShiftReport: false,
  loadingSupplierPayments: false,
  loadingOneSafeData: false,
  loadingSafeTransferData: false,
  loadingSupplierPay: false,
  getTblStore: [],
  getTblMaterial: [],
  getTblEmp: [],
  getStockBalanceFilteredByStock: [],
  getStockBalanceFilteredByMaterial: [],
  getSafeBalance: [],
  getNetProfit: [],
  getDayTrans: [],
  getMonthTrans: [],
  getMonthTransNetCash: 0,
  getSupplierAccount: [],
  getItemsSales: [],
  getExpenseReport: [],
  getShiftReport: [],
  getSupplierPayments: [],
  oneSafeData: [],
  getSafeTransferData: [],
  supplierPay: 0,
  error: {},
};

const Reports = (state = INIT_STATE, action) => {
  switch (action.type) {
    // -- loading
    // Utils reducers
    case GET_TBL_STORE:
      return {
        ...state,
        loadingTblStore: true,
        getTblStore: [],
      };

    case GET_TBL_MATERIAL:
      return {
        ...state,
        loadingTblMaterial: true,
        getTblMaterial: [],
      };

    case GET_TBL_EMP:
      return {
        ...state,
        loadingTblEmp: true,
        getTblEmp: [],
      };

    case GET_SAFE_TRANSFER:
      return {
        ...state,
        loadingSafeTransferData: true,
        getSafeTransferData: [],
      };

    // Main reducers
    case GET_STOCK_BALANCE_FILTERED_BY_STOCK:
      return {
        ...state,
        loadingStockBalanceFilteredByStock: true,
        getStockBalanceFilteredByStock: [],
      };

    case GET_STOCK_BALANCE_FILTERED_BY_MATERIAL:
      return {
        ...state,
        loadingStockBalanceFilteredByMaterial: true,
        getStockBalanceFilteredByMaterial: [],
      };

    case GET_SAFE_BALANCE:
      return {
        ...state,
        loadingSafeBalance: true,
        getSafeBalance: [],
      };

    case GET_NET_PROFIT:
      return {
        ...state,
        loadingNetProfit: true,
        getNetProfit: [],
      };

    case GET_DAY_TRANS:
      return {
        ...state,
        loadingDayTrans: true,
        getDayTrans: [],
      };

    case GET_MONTH_TRANS:
      return {
        ...state,
        loadingMonthTrans: true,
        getMonthTrans: [],
        getMonthTransNetCash: 0
      };

    case GET_SUPPLIER_ACCOUNT:
      return {
        ...state,
        loadingSupplierAccount: true,
        getSupplierAccount: [],
      };

    case GET_ITEMS_SALES:
      return {
        ...state,
        loadingItemsSales: true,
        getItemsSales: [],
      };

    case GET_EXPENSE_REPORT:
      return {
        ...state,
        loadingExpenseReport: true,
        getExpenseReport: [],
      };

    case GET_SUPPLIER_PAYMENTS:
      return {
        ...state,
        loadingSupplierPayments: true,
        getSupplierPayments: []
      };

    case GET_SHIFT_REPORT:
      return {
        ...state,
        loadingShiftReport: true,
        getShiftReport: []
      };

    // -- success
    // Utils reducers
    case GET_TBL_STORE_SUCCESS:
      return {
        ...state,
        getTblStore: action.payload,
        loadingTblStore: false,
      };

    case GET_TBL_MATERIAL_SUCCESS:
      return {
        ...state,
        getTblMaterial: action.payload,
        loadingTblMaterial: false,
      };

    case GET_TBL_EMP_SUCCESS:
      return {
        ...state,
        getTblEmp: action.payload,
        loadingTblEmp: false,
      };

    // Main reducers
    case GET_STOCK_BALANCE_FILTERED_BY_STOCK_SUCCESS:
      return {
        ...state,
        getStockBalanceFilteredByStock: action.payload,
        loadingStockBalanceFilteredByStock: false,
      };

    case GET_STOCK_BALANCE_FILTERED_BY_MATERIAL_SUCCESS:
      return {
        ...state,
        getStockBalanceFilteredByMaterial: action.payload,
        loadingStockBalanceFilteredByMaterial: false,
      };

    case GET_SAFE_BALANCE_SUCCESS:
      return {
        ...state,
        getSafeBalance: action.payload,
        loadingSafeBalance: false,
      };

    case GET_NET_PROFIT_SUCCESS:
      return {
        ...state,
        getNetProfit: action.payload,
        loadingNetProfit: false,
      };

    case GET_DAY_TRANS_SUCCESS:
      return {
        ...state,
        getDayTrans: action.payload,
        loadingDayTrans: false,
      };

    case GET_MONTH_TRANS_SUCCESS:
      return {
        ...state,
        getMonthTrans: action.payload.Table,
        getMonthTransNetCash: action.payload.Table1[0].NetCash ? action.payload.Table1[0].NetCash : 0,
        loadingMonthTrans: false,
      };

    case GET_SUPPLIER_ACCOUNT_SUCCESS:
      return {
        ...state,
        getSupplierAccount: action.payload,
        loadingSupplierAccount: false,
      };

    case GET_ITEMS_SALES_SUCCESS:
      return {
        ...state,
        getItemsSales: action.payload,
        loadingItemsSales: false,
      };

    case GET_EXPENSE_REPORT_SUCCESS:
      return {
        ...state,
        getExpenseReport: action.payload,
        loadingExpenseReport: false,
      };

    case GET_SUPPLIER_PAYMENTS_SUCCESS:
      return {
        ...state,
        getSupplierPayments: action.payload,
        loadingSupplierPayments: false
      };

    case GET_SHIFT_REPORT_SUCCESS:
      return {
        ...state,
        getShiftReport: action.payload,
        loadingShiftReport: false
      };
      case GET_ONE_SAFE_DATA:
        return {
          ...state,
          loadingOneSafeData: true,
          getOneSafeData: []
        }

      case GET_ONE_SAFE_DATA_SUCCESS:
          return {
          ...state,
          oneSafeData: action.payload,
          loadingOneSafeData: false
        }

      case GET_SAFE_TRANSFER_SUCCESS:
        return {
          ...state,
          loadingSafeTransferData: false,
          getSafeTransferData: action.payload
        }

      case POST_SUPPLIER_PAYMENT:
        return {
          ...state,
          loadingSupplierPay: true
        }

      case POST_SUPPLIER_PAYMENT_SUCCESS:
        return {
          ...state,
          loadingSupplierPay: false,
          supplierPay: action.payload
        }

    // -- error
    case GET_TBL_STORE_FAIL:
    case GET_TBL_MATERIAL_FAIL:
    case GET_TBL_EMP_FAIL:
    case GET_STOCK_BALANCE_FILTERED_BY_STOCK_FAIL:
    case GET_STOCK_BALANCE_FILTERED_BY_MATERIAL_FAIL:
    case GET_SAFE_BALANCE_FAIL:
    case GET_NET_PROFIT_FAIL:
    case GET_DAY_TRANS_FAIL:
    case GET_MONTH_TRANS_FAIL:
    case GET_SUPPLIER_ACCOUNT_FAIL:
    case GET_ITEMS_SALES_FAIL:
    case GET_EXPENSE_REPORT_FAIL:
    case GET_SUPPLIER_PAYMENTS_FAIL:
    case GET_SHIFT_REPORT_FAIL:
    case GET_ONE_SAFE_DATA_FAIL:
    case POST_SUPPLIER_PAYMENT_FAIL:
      return {
        ...state,
        loadingTblStore: false,
        loadingTblMaterial: false,
        loadingTblEmp: false,
        loadingStockBalanceFilteredByStock: false,
        loadingStockBalanceFilteredByMaterial: false,
        loadingSafeBalance: false,
        loadingNetProfit: false,
        loadingDayTrans: false,
        loadingMonthTrans: false,
        loadingSupplierAccount: false,
        loadingItemsSales: false,
        loadingExpenseReport: false,
        loadingShiftReport: false,
        loadingSupplierPayments: false,
        loadingOneSafeData: false,
        loadingSafeTransferData: false,
        loadingSupplierPay: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Reports;
