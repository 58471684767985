import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import { Card, CardBody, Spinner } from "reactstrap"
import StackedColumnChart from "pages/Dashboard/components/customerMap/StackedColumnChart"
import { todayDummyData, weekDummyData, monthDummyData } from "../../data"
import classes from "../../index.module.scss"
import PeriodType from "pages/Dashboard/shared/PeriodType"
import { useDispatch, useSelector } from "react-redux"
import { getTimeline } from "store/actions"

const CustomerMap = props => {
  const dispatch = useDispatch()
  const [periodData, setPeriodData] = useState(todayDummyData)
  const [timelineData, setTimelineData] = useState([])
  const [timeLineTime, setTimeLineTime] = useState([])

  const { timeLineList: timeLineList, timeLineLoading: isLoading } =
    useSelector(state => state.dashboard)

  const currentPeriodTypeHandler = periodType => {
    if (periodType === "today") {
      dispatch(
        getTimeline({
          timeLine: "DailyTimeLine",
          timeLineValue: true,
        })
      )
    } else if (periodType === "monthly") {
      dispatch(
        getTimeline({
          timeLine: "MonthlyTimeLine",
          timeLineValue: true,
        })
      )
    } else if (periodType === "weekly") {
      dispatch(
        getTimeline({
          timeLine: "WeeklyTimeLine",
          timeLineValue: true,
        })
      )
    }
  }

  useEffect(() => {
    dispatch(
      getTimeline({
        timeLine: "DailyTimeLine",
        timeLineValue: true,
      })
    )
  }, [])

  useEffect(() => {
    if (timeLineList.length) {
      const periodDataList = timeLineList.map(one => one.OrderCount)
      const periodTime = timeLineList.map(one => one.OrderHour)

      setTimelineData(periodDataList)
      setTimeLineTime(periodTime)
    }
  }, [timeLineList])

  return (
    <Card style={{ borderRadius: "16px" }}>
      <CardBody>
        <div className="d-sm-flex flex-wrap">
          <div className="d-flex flex-column mb-4">
            <h4 className={"card-title" + classes.customerMapTitle}>
              {props.t("Orders Timeline")}
            </h4>
            <p className={classes.customerMapDescription}>
              {props.t("visualizeCustomer")}
            </p>
          </div>
          <PeriodType currentPeriodTypeHandler={currentPeriodTypeHandler} />
        </div>
        {isLoading ? (
          <div className="text-center">
            <Spinner className="ms-2" color="primary" />
          </div>
        ) : (
          <StackedColumnChart
            periodData={timelineData}
            periodTime={timeLineTime}
            dataColors='["--bs-primary"]'
          />
        )}
      </CardBody>
    </Card>
  )
}

CustomerMap.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(CustomerMap))
