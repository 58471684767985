import React, { useState } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  FormGroup,
  Button,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosAuthInstance from "../../../axios/axiosAuthInstance";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { postUnit } from "store/actions";


const UnitModal = (props) => {
  const dispatch = useDispatch();

  const { unitModal, handleUnitToggle, handleUnitUpdated } = props;
  const [unitName, setUnitName] = useState("");

  const toggle = () => {
    handleUnitToggle();
  };

  const postNewUnit = async data => {
    useDispatch(postUnit(data));
    // const response = await axiosAuthInstance.post("TblUnit/save", data);
    // if (response) {
    //   return response;
    // }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      unitName: unitName || "",
    },
    validationSchema: Yup.object({
      unitName: Yup.string().required(props.t("Please Enter Unit Name")),
    }),
    onSubmit: async values => {
      const newUnit = {
        unit: values["unitName"],
      };
      // save new user
      //dispatch(onAddNewUser(newArea))

      dispatch(postUnit(newUnit));

      // try {
      //   const response = await postNewUnit(newUnit);
      //   if (response.status === 200) {
      //     handleUnitUpdated();
      //     toast.dismiss();
      //     toast.success(props.t("Unit added successfully"));
      //     validation.resetForm();
      //   }
      // } catch (err) {
      //   toast.dismiss();
      //   toast.error(props.t("Problem occured. Please, try again"));
      //   console.log(err);
      // }


      toggle();
    },
  });

  const resetUnitForm = () => {
    setUnitName("");
  };
  const handleCancel = () => {
    resetUnitForm();
    toggle();
  };

  return (
    <Modal isOpen={unitModal} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} tag="h4">
        {props.t("Add Unit")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">{props.t("Unit")}</Label>
                <Input
                  name="unitName"
                  type="text"
                  placeholder={props.t("Enter unit")}
                  onChange={(e) => setUnitName(e.target.value)}
                  onBlur={validation.handleBlur}
                  value={validation.values.unitName || ""}
                  invalid={
                    validation.touched.unitName && validation.errors.unitName
                      ? true
                      : false
                  }
                />
                {validation.touched.unitName && validation.errors.unitName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.unitName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  <i className="mdi mdi-check-circle-outline me-1" />
                  {props.t("Save")}
                </button>
                <Button
                  type="button"
                  color="secondary"
                  className="mx-1"
                  onClick={handleCancel}
                >
                  <i className="mdi mdi-cancel me-1" />
                  {props.t("Cancel")}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

UnitModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(UnitModal));
