import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_PREPARE_AREAS, POST_PREPARE_AREA } from "./actionType"

import {
  getPrepareAreaSuccess,
  getPrepareAreaFail,
  postPrepareAreaSuccess,
  postPrepareAreaFail,
} from "./actions"

import { getPrepareAreas, postPrepareArea } from "../../../helpers/backend_helper"
import i18n from "i18n";
import { toast } from "react-toastify";


function* fetchPrepareArea() {
  try {
    const response = yield call(getPrepareAreas)
    yield put(getPrepareAreaSuccess(response.rs.data))
  } catch (err) {
    yield put(getPrepareAreaFail(err))
  }
}

function* postNewPrepareArea(item) {
  try {
    const response = yield call(postPrepareArea, item.payload)
    if (response.sts.msg === "OK") {
      toast.dismiss();
      toast.success(i18n.t("Area added successfully"));
      yield put(postPrepareAreaSuccess(response.rs))
    }
  } catch (err) {
    yield put(postPrepareAreaFail(err))
  }
}

function* prepareAreaSaga() {
  yield takeEvery(GET_PREPARE_AREAS, fetchPrepareArea)
  yield takeEvery(POST_PREPARE_AREA, postNewPrepareArea)
}

export default prepareAreaSaga
