const ADD_EXPENSE = "ADD_EXPENSE";
const ADD_EXPENSE_SUCCESS = "ADD_EXPENSE_SUCCESS";
const ADD_EXPENSE_FAIL = "ADD_EXPENSE_FAIL";

const UPLOAD_EXPENSE_IMAGES = "UPLOAD_EXPENSE_IMAGES";
const UPLOAD_EXPENSE_IMAGES_SUCCESS = "UPLOAD_EXPENSE_IMAGES_SUCCESS";
const UPLOAD_EXPENSE_IMAGES_FAIL = "UPLOAD_EXPENSE_IMAGES_FAIL";

const ADD_EXPENSE_CAT = "ADD_EXPENSE_CAT";
const ADD_EXPENSE_CAT_SUCCESS = "ADD_EXPENSE_CAT_SUCCESS";
const ADD_EXPENSE_CAT_FAIL = "ADD_EXPENSE_CAT_FAIL";
const GET_EXPENSE_CAT = "GET_EXPENSE_CAT";
const GET_EXPENSE_CAT_SUCCESS = "GET_EXPENSE_CAT_SUCCESS";
const GET_EXPENSE_CAT_FAIL = "Get_EXPENSE_CAT_FAIL";

export {
    ADD_EXPENSE,
    ADD_EXPENSE_SUCCESS,
    ADD_EXPENSE_FAIL,
    UPLOAD_EXPENSE_IMAGES,
    UPLOAD_EXPENSE_IMAGES_SUCCESS,
    UPLOAD_EXPENSE_IMAGES_FAIL,
    ADD_EXPENSE_CAT,
    ADD_EXPENSE_CAT_SUCCESS,
    ADD_EXPENSE_CAT_FAIL,
    GET_EXPENSE_CAT,
    GET_EXPENSE_CAT_SUCCESS,
    GET_EXPENSE_CAT_FAIL
};
